import React, { Component } from "react";
import PageTitle from "../../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../../components/Content-Section";
import Datatable from "./datatable";
import FilterSection from "./filter-section";
import ToggleDisplay from "react-toggle-display";

class VoucherPurchases extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { onLoad } = this.props;
    onLoad();
    this.props.clearDataTableSearchbox();
  }

  render() {
    const { isProcessing, isReset } = this.props.dataTable;
    return (
      <React.Fragment>
        <PageTitle title="Admin - Voucher Purchases | Train to Gain" />
        <ContentSection isFullWidth={true} padding={3} bgColor="grey-shadow">
          <h1>Voucher Purchases</h1>
        </ContentSection>
        <ContentSection isFullWidth={true} padding={3}>
          <ToggleDisplay if={!isReset} show={!isProcessing}>
            <FilterSection {...this.props} />
          </ToggleDisplay>
          <Datatable {...this.props} />
        </ContentSection>
      </React.Fragment>
    );
  }
}

export default VoucherPurchases;
