import React, { Component } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../../components/Content-Section";
import { Table } from "reactstrap";
import CourseService from "../../../services/course-service";
import "./new-enrolment.scss";

class NewEnrolment extends Component {
  render() {
    const courseService = CourseService();

    return (
      <React.Fragment>
        <PageTitle title="Enrol in a Course" />
        <ContentSection bgColor="grey-shadow" padding={4}>
          <h1>Enrol in a Course!</h1>
          <Link to="/your-courses" className="btn ttg-btn">
            Your Courses
          </Link>
        </ContentSection>
        <ContentSection padding={3}>
          <Table className="new-enrolment-table">
            <thead>
              <tr>
                <th>Course</th>
                <th>Price</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {courseService.getCourses().map((course, i) => (
                <tr key={i}>
                  <td className="course-val">
                    {course.courseId !== 6 ? (
                      course.courseTitle
                    ) : (
                      <React.Fragment>
                        Food Safety Supervisor Hospitality &amp; Retail{" "}
                        <span style={{ color: "#1e2b5f", fontWeight: "700" }}>
                          (The NSW Food Safety Supervisor Certificate)
                        </span>
                      </React.Fragment>
                    )}
                    <br />({course.certificateCodesString})
                  </td>
                  <td className="cost-val">${course.courseCost}.00</td>
                  <td className="action-btns">
                    <Link
                      to={`/course-enrolment/${course.courseId}/step-1`}
                      className="btn ttg-btn"
                    >
                      Enrol Now
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ContentSection>
      </React.Fragment>
    );
  }
}

export default NewEnrolment;
