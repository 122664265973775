import React, { Component } from "react";
import { Row, Col, Button, Form, FormGroup, Label } from "reactstrap";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import InputField from "../../components/Common/Fields/Input-Field";
import ContentSection from "../../components/Content-Section";
import * as validators from "../../components/Common/Fields/Validators/Field-Validators";
import ToggleDisplay from "react-toggle-display";
import BlockUiFx from "../../components/Common/Block-Ui-Fx/Block-Ui-Fx";

class ChnagePassword extends Component {
  passwordValidator = (fieldName, value) => {
    if (validators.required(fieldName, value))
      return validators.required(fieldName, value);
    if (value.length < 8) return validators.min(value, "Password", 8);
    if (value.length > 50) return validators.max(value, "Password", 50);
    if (!/^(?=.*[!@#$&*0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,50}$/.test(value)) {
      return validators.meessage(
        "Password must have at least 1 special character or a number."
      );
    }

    return false;
  };

  confirmPasswordValidator = (fieldName, value) => {
    if (validators.required(fieldName, value))
      return validators.required(fieldName, value);
    if (this.props.changePassword.fields.password != value)
      return validators.meessage(
        "Password and confirm confirm password must match"
      );
    return false;
  };

  render() {
    const {
      password,
      oldPassword,
      confirmPassword
    } = this.props.changePassword.fields;
    const { isSubmitting, isSubmitted } = this.props.changePassword;
    const { onSubmit, onInputChange, onFieldError, validate } = this.props;
    return (
      <React.Fragment>
        <PageTitle title="Change Password" />
        <ContentSection bgColor="grey-shadow mb-4" padding={4}>
          <h1>Change Password</h1>
          <main className="my-3">
            <ToggleDisplay if={isSubmitted}>
              <h3>Your password has been successfully changed.</h3>
            </ToggleDisplay>
            <ToggleDisplay if={!isSubmitted}>
              <BlockUiFx blocking={isSubmitting} lowerZIndex={true}>
                <Form className="ttg-form">
                  <FormGroup>
                    <Row>
                      <Col md="4" sm="6" xs="12">
                        <InputField
                          name="oldPassword"
                          placeholder="Old Password"
                          value={oldPassword}
                          type="password"
                          validate={val =>
                            validators.required("Old Password", val)
                          }
                          onFieldError={onFieldError}
                          onChange={onInputChange}
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Row>
                      <Col md="4" sm="6" xs="12">
                        <InputField
                          name="password"
                          placeholder="Password"
                          value={password}
                          type="password"
                          validate={val =>
                            this.passwordValidator("Password", val)
                          }
                          onFieldError={onFieldError}
                          onChange={onInputChange}
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Row>
                      <Col md="4" sm="6" xs="12">
                        <InputField
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          type="password"
                          validate={val =>
                            this.confirmPasswordValidator(
                              "Confirm Password",
                              val
                            )
                          }
                          onFieldError={onFieldError}
                          onChange={onInputChange}
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Button
                      className="ttg-btn"
                      onClick={onSubmit}
                      disabled={validate()}
                    >
                      Submit
                    </Button>
                  </FormGroup>
                </Form>
              </BlockUiFx>
            </ToggleDisplay>
          </main>
        </ContentSection>
      </React.Fragment>
    );
  }
}

export default ChnagePassword;
