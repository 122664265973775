import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import KeyboardEventHandler from "react-keyboard-event-handler";
import withSizes from "react-sizes";
import ToggleDisplay from "react-toggle-display";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import "../../../assets/styles/enrolment-forms.scss";
import BlockUiFx from "../../../components/Common/Block-Ui-Fx/Block-Ui-Fx";
import ButtonFx from "../../../components/Common/Button-Fx/Button-Fx";
import CheckboxField from "../../../components/Common/Fields/Checkbox-Field";
import DatePickerField from "../../../components/Common/Fields/Date-Picker-Field";
import InputField from "../../../components/Common/Fields/Input-Field";
import MaskedInputField from "../../../components/Common/Fields/Masked-Input-Field";
import RadioButtonList from "../../../components/Common/Fields/Radio-Button-List";
import SelectField from "../../../components/Common/Fields/Select-Field";
import * as validators from "../../../components/Common/Fields/Validators/Field-Validators";
import formHandler from "../../../components/Common/Fields/form-handler";
import PageTitle from "../../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../../components/Content-Section";
import LoqateAddressField from "./../../../components/Common/Loqate-Single-Address-Field/index";
import * as Step2Constants from "./step-2-constants";

const validateSelect = (fieldName, value) => {
  if (validators.required(fieldName, value) || value === "-1") {
    return `${fieldName} is required.`;
  }
  return false;
};

const validateUsiNumber = (fieldName, value) => {
  if (validators.required(fieldName, value) || value === "-1") {
    return `${fieldName} is required.`;
  }
  if (!/^[2-9A-HJ-NP-Za-hj-np-z]{10}$/.test(value) && value !== "INDIV") {
    return `${value} is not a valid USI number.`;
  }
  return false;
};

const phoneValidator = (fieldName, value) => {
  if (validators.required(fieldName, value))
    return validators.required(fieldName, value);

  if (validators.phone(value)) return validators.phone(value);

  return false;
};

const getPhoneMask = (value) => {
  if (
    value.startsWith("02") ||
    value.startsWith("03") ||
    value.startsWith("07") ||
    value.startsWith("08")
  )
    return "99 9999 9999";

  return "9999 999 999";
};

class CourseEnrolmentStep2 extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { step2OnInit, match } = this.props;
    step2OnInit(match.params.courseId);
  }

  onAutocompleteAddressSelect = (address) => {
    this.props.updateAutocompleteAddress(address);
  };

  render() {
    const form = formHandler();
    const {
      onInputChange,
      onDateOfBirthChange,
      toggleWhatIsUsi,
      onFieldError,
      isProcessing,
      isMobile,
      onSubmitEnrolment,
      clearAddress,
      addressEntry,
    } = this.props;

    const {
      showLevelOfEducation,
      showWhatIsUsi,
      showHasUsi,
      showDisability,
      showCountry,
      showOtherLanguage,
      showUnderstandingOfEnglish,
      showUnderstandFalseInfo,
      showUnderstandTimeToComplete,
      showGuardianAcknowledgment,
      showUsiLastName,
    } = this.props.fieldVisibility;

    const {
      dateOfBirth,
      gender,
      levelOfSchool,
      stillEnrolledInSchool,
      completedTertiary,
      levelOfEducation,
      reasonForUndertakingCourse,
      phone,
      usiFirstName,
      usiMiddleName,
      usiLastName,
      hasUsi,
      usiNumber,
      howDidYouHear,
      disability,
      disabilityType,
      bornInAustralia,
      country,
      aboriginalOrTorresStraitIslandOrigin,
      languageAtHome,
      languageAtHomeOther,
      goodUnderstandingOfEnglish,
      understandFalseInfo,
      understandTimeToComplete,
      studentAcknowledgment,
      guardianAcknowledgment,
    } = this.props.fields;
    const { courseTitle, courseCode } = this.props.courseDetails;

    return (
      <>
        <PageTitle title="Course Enrolment" />
        <Form className="ttg-form">
          <BlockUiFx blocking={isProcessing} lowerZIndex={true}>
            <ContentSection bgColor="grey-shadow" padding={4}>
              <h1>Course Enrolment</h1>
              <h5>
                {courseTitle} ({courseCode})
              </h5>
              <h6>
                As a Registered Training Organisation we are required to collect
                the information below for our training records. Thank you!
              </h6>
            </ContentSection>

            <ContentSection padding={3}>
              <h5>Your Details</h5>
              <FormGroup>
                <Label for="dateOfBirth">Date of Birth</Label>
                <div>
                  <ToggleDisplay if={!isMobile}>
                    <DatePickerField
                      name="dateOfBirth"
                      value={dateOfBirth}
                      minDate={new Date(1930, 1, 1)}
                      maxDate={new Date()}
                      onChange={onDateOfBirthChange}
                      validate={(val) =>
                        validators.required("Date of birth", val)
                      }
                      onFieldError={onFieldError}
                      ref={form.add}
                    ></DatePickerField>
                  </ToggleDisplay>
                  <ToggleDisplay if={isMobile}>
                    <InputField
                      name="dateOfBirth"
                      placeholder="Date of birth"
                      value={dateOfBirth}
                      ref={form.add}
                      type="date"
                      onChange={onDateOfBirthChange}
                      validate={(val) =>
                        validators.required("Date of birth", val)
                      }
                      onFieldError={onFieldError}
                      className="mxw-200"
                    />
                  </ToggleDisplay>
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="gender">Gender</Label>
                <div>
                  <RadioButtonList
                    name="gender"
                    value={gender}
                    showBorderError={false}
                    items={Step2Constants.genders}
                    onChange={onInputChange}
                    ref={form.add}
                    validate={(val) =>
                      val === "-1" ||
                      val === "" ||
                      val === undefined ||
                      val === null
                        ? "Gender is required"
                        : false
                    }
                    onFieldError={onFieldError}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="phone">Telephone Number</Label>
                <MaskedInputField
                  name="phone"
                  placeholder="Telephone Number"
                  mask={getPhoneMask(phone)}
                  value={phone}
                  ref={form.add}
                  onChange={onInputChange}
                  onFieldError={onFieldError}
                  validate={(val) => phoneValidator("Telephone Number", val)}
                  className="mxw-200"
                />
              </FormGroup>
              <FormGroup>
                <LoqateAddressField
                  name={"postalAddress"}
                  placeholder={"Postal Address"}
                  form={form}
                  initialValue={addressEntry.fullAddress}
                  onInputChange={onInputChange}
                  onAddressSelect={this.onAutocompleteAddressSelect}
                  fields={this.props.fields}
                  onFieldError={onFieldError}
                  validate={(val) => validators.required("Address", val)}
                  ref={form.add}
                  clearAddress={clearAddress}
                  label={"Postal"}
                />
              </FormGroup>
            </ContentSection>

            <ContentSection bgColor="grey" padding={3}>
              <h5>Your Education Details</h5>
              <FormGroup>
                <Label for="levelOfSchool">
                  What is your highest COMPLETED school level?
                </Label>
                <SelectField
                  name="levelOfSchool"
                  ref={form.add}
                  value={levelOfSchool}
                  onInputChange={onInputChange}
                  onFieldError={onFieldError}
                  validate={(val) => validateSelect("Level of school", val)}
                  className="mxw-400"
                >
                  <option value="-1">- Select -</option>
                  {Step2Constants.levelOfSchool.map((item, i) => (
                    <option key={i} value={item.value}>
                      {item.label ? item.label : item.value}
                    </option>
                  ))}
                </SelectField>
              </FormGroup>
              <FormGroup>
                <Label for="stillEnrolledInSchool">
                  Are you still enrolled in secondary or senior secondary
                  education?
                </Label>
                <div>
                  <RadioButtonList
                    name="stillEnrolledInSchool"
                    value={stillEnrolledInSchool}
                    ref={form.add}
                    validate={(val) =>
                      val === "-1" ||
                      val === "" ||
                      val === undefined ||
                      val === null
                        ? "Are you still enrolled in secondary or senior secondary education is required."
                        : false
                    }
                    onFieldError={onFieldError}
                    items={Step2Constants.yesNo}
                    showBorderError={false}
                    onChange={onInputChange}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="completedTertiary">
                  Have you successfully completed any tertiary qualifications?
                </Label>
                <div>
                  <RadioButtonList
                    name="completedTertiary"
                    value={completedTertiary}
                    showBorderError={false}
                    ref={form.add}
                    validate={(val) =>
                      val === "-1" ||
                      val === "" ||
                      val === undefined ||
                      val === null
                        ? "Has completed tertiary is required"
                        : false
                    }
                    onFieldError={onFieldError}
                    items={Step2Constants.yesNo}
                    onChange={onInputChange}
                  />
                </div>
              </FormGroup>
              {showLevelOfEducation && (
                <FormGroup>
                  <Label for="levelOfEducation">
                    What was the highest COMPLETED level of tertiary
                    qualification?
                  </Label>
                  <SelectField
                    name="levelOfEducation"
                    value={levelOfEducation}
                    onChange={onInputChange}
                    validate={(val) =>
                      validateSelect("Level of education", val)
                    }
                    onFieldError={onFieldError}
                    ref={form.add}
                    className="mxw-400"
                  >
                    <option value="-1">- Select -</option>
                    {Step2Constants.levelOfEducation.map((item, i) => (
                      <option key={i} value={item.value}>
                        {item.label ? item.label : item.value}
                      </option>
                    ))}
                  </SelectField>
                </FormGroup>
              )}
              <FormGroup>
                <Label for="reasonForUndertakingCourse">
                  Of the following categories, select the one which BEST
                  describes the main reason you are undertaking this
                  course/traineeship/apprenticeship
                </Label>
                <SelectField
                  name="reasonForUndertakingCourse"
                  value={reasonForUndertakingCourse}
                  onChange={onInputChange}
                  validate={(val) =>
                    validateSelect("Reason for undertaking course", val)
                  }
                  ref={form.add}
                  onFieldError={onFieldError}
                  className="mxw-400"
                >
                  <option value="-1">- Select -</option>
                  {Step2Constants.reasonForUndertakingCourse.map((item, i) => (
                    <option key={i} value={item.value}>
                      {item.label ? item.label : item.value}
                    </option>
                  ))}
                </SelectField>
              </FormGroup>
              <FormGroup className="mb-0">
                <Label>
                  Your Full Legal Name{" "}
                  <span className="font-weight-normal">
                    (Your legal name needs to match exactly how you entered it
                    in the USI system)
                  </span>
                  <br />
                  <span className="font-weight-normal" style={{ color: "red" }}>
                    Your certificate will be issued in this name.
                  </span>
                </Label>
                <Row form>
                  <Col md={3}>
                    <FormGroup>
                      <InputField
                        name="usiFirstName"
                        placeholder="First Name"
                        value={usiFirstName}
                        onChange={onInputChange}
                        ref={form.add}
                        validate={(val) =>
                          validators.required("USI first name", val)
                        }
                        onFieldError={onFieldError}
                      />
                    </FormGroup>
                  </Col>
                  {showUsiLastName && (
                    <>
                      <Col md={3}>
                        <FormGroup>
                          <InputField
                            name="usiMiddleName"
                            placeholder="Middle Name"
                            value={usiMiddleName}
                            onChange={onInputChange}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <FormGroup>
                          <InputField
                            name="usiLastName"
                            placeholder="Last Name"
                            value={usiLastName}
                            onChange={onInputChange}
                            ref={form.add}
                            validate={(val) =>
                              validators.required("USI last name", val)
                            }
                            onFieldError={onFieldError}
                          />
                        </FormGroup>
                      </Col>
                    </>
                  )}
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="hasUsi">
                  Do you have a Unique Student Identifier (USI)? &nbsp;
                  <a className="action-button" onClick={toggleWhatIsUsi}>
                    What is a USI?
                  </a>
                </Label>
                <div>
                  <RadioButtonList
                    name="hasUsi"
                    value={hasUsi}
                    showBorderError={false}
                    ref={form.add}
                    validate={(val) =>
                      val === "-1" ||
                      val === "" ||
                      val === undefined ||
                      val === null
                        ? "Please indicate whether or not you have a USI number"
                        : false
                    }
                    onFieldError={onFieldError}
                    items={Step2Constants.yesNo}
                    onChange={onInputChange}
                  />
                </div>
              </FormGroup>
              {showHasUsi && (
                <FormGroup>
                  <Label for="usiNumber">
                    Your Unique Student Identifier (USI) Number
                  </Label>
                  <InputField
                    name="usiNumber"
                    ref={form.add}
                    placeholder="Your Unique Student Identifier (USI) Number"
                    value={usiNumber}
                    onChange={onInputChange}
                    validate={(val) => validateUsiNumber("USI Number", val)}
                    onFieldError={onFieldError}
                    className="mxw-400"
                  />
                </FormGroup>
              )}
              {hasUsi === "FALSE" && (
                <div>
                  <p>
                    Please go to{" "}
                    <a
                      href="https://www.usi.gov.au/students/create-usi"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.usi.gov.au/students/create-usi
                    </a>{" "}
                    to create your USI number.
                  </p>
                  <p>
                    If you have forgotten your USI number please follow the link
                    below:
                    <br />
                    <a
                      href="https://www.usi.gov.au/documents/forgotten-usi-fact-sheet"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.usi.gov.au/documents/forgotten-usi-fact-sheet
                    </a>
                  </p>
                  <p>
                    When you have created the number you need to select{" "}
                    <strong>
                      <em>Yes</em>
                    </strong>{" "}
                    to the question{" "}
                    <em>Do you have a Unique Student Identifier (USI)?</em>{" "}
                    above, and enter the USI number into the box and continue
                    with your enrolment.
                  </p>
                  <p>
                    If you experience any issues please call the USI Department
                    on 1300 857 536
                  </p>
                </div>
              )}
              {showWhatIsUsi && (
                <div>
                  <iframe
                    src="https://www.youtube.com/embed/0HXEjWiZo5s"
                    allowFullScreen
                    frameBorder={0}
                    width="100%"
                    height="280px"
                    style={{ maxWidth: "500px" }}
                    title="What is a USI Video"
                  />
                </div>
              )}
            </ContentSection>
            <ContentSection padding={3}>
              <h5>Further Details</h5>
              <FormGroup>
                <Label for="howDidYouHear">
                  How did you hear about our company and courses?
                </Label>
                <SelectField
                  name="howDidYouHear"
                  value={howDidYouHear}
                  ref={form.add}
                  onInputChange={onInputChange}
                  onFieldError={onFieldError}
                  validate={(val) =>
                    validateSelect(
                      "How did you hear about our company and courses",
                      val
                    )
                  }
                  className="mxw-300"
                >
                  <option value="-1">- Select -</option>
                  {Step2Constants.howDidYouHear.map((item, i) => (
                    <option key={i} value={item.value}>
                      {item.label ? item.label : item.value}
                    </option>
                  ))}
                </SelectField>
              </FormGroup>
              <FormGroup>
                <Label for="disability">
                  Do you consider yourself to have a disability, impairment or
                  long-term condition?
                </Label>
                <div>
                  <RadioButtonList
                    showBorderError={false}
                    name="disability"
                    ref={form.add}
                    validate={(val) =>
                      val === "-1" ||
                      val === "" ||
                      val === undefined ||
                      val === null
                        ? "Please indicate whether or not you consider yourself to have a disability."
                        : false
                    }
                    onFieldError={onFieldError}
                    value={disability}
                    items={Step2Constants.yesNo}
                    onChange={onInputChange}
                  />
                </div>
              </FormGroup>
              {showDisability && (
                <React.Fragment>
                  <FormGroup>
                    <Label for="disabilityType">
                      Please select from the below options
                    </Label>
                    <SelectField
                      ref={form.add}
                      name="disabilityType"
                      value={disabilityType}
                      onInputChange={onInputChange}
                      onFieldError={onFieldError}
                      validate={(val) => validateSelect("Disability type", val)}
                      className="mxw-300"
                    >
                      <option value="-1">- Select -</option>
                      {Step2Constants.disabilityType.map((item, i) => (
                        <option key={i} value={item.value}>
                          {item.label ? item.label : item.value}
                        </option>
                      ))}
                    </SelectField>
                  </FormGroup>
                  <div className="more-info-box">
                    <p>
                      For more information, please refer to the student
                      handbook.{" "}
                      <a
                        href="/UserFiles/files/Student-Handbook-2024.07.v2.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ttg-btn"
                      >
                        View the Student Handbook
                      </a>
                    </p>
                  </div>
                </React.Fragment>
              )}
              <FormGroup>
                <Label for="bornInAustralia">Were you born in Australia?</Label>
                <div>
                  <RadioButtonList
                    name="bornInAustralia"
                    ref={form.add}
                    validate={(val) =>
                      val === "-1" ||
                      val === "" ||
                      val === undefined ||
                      val === null
                        ? "Were you born in Australia is required"
                        : false
                    }
                    onFieldError={onFieldError}
                    showBorderError={false}
                    value={bornInAustralia}
                    items={Step2Constants.yesNo}
                    onChange={onInputChange}
                  />
                </div>
              </FormGroup>
              {showCountry && (
                <FormGroup>
                  <Label for="country">Country of Birth</Label>
                  <SelectField
                    name="country"
                    value={country}
                    onInputChange={onInputChange}
                    onFieldError={onFieldError}
                    ref={form.add}
                    validate={(val) => validateSelect("Country of birth", val)}
                    className="mxw-400"
                  >
                    <option value="-1">- Select -</option>
                    {Step2Constants.countries.map((item, i) => (
                      <option key={i} value={item.value}>
                        {item.label ? item.label : item.value}
                      </option>
                    ))}
                  </SelectField>
                </FormGroup>
              )}
              <FormGroup>
                <Label for="aboriginalOrTorresStraitIslandOrigin">
                  Are you Aboriginal or Torres Strait Island origin?
                </Label>
                <div>
                  <RadioButtonList
                    name="aboriginalOrTorresStraitIslandOrigin"
                    value={aboriginalOrTorresStraitIslandOrigin}
                    ref={form.add}
                    validate={(val) =>
                      val === "-1" ||
                      val === "" ||
                      val === undefined ||
                      val === null
                        ? "Aboriginal or Torres Strait Island Origin is required."
                        : false
                    }
                    onFieldError={onFieldError}
                    items={Step2Constants.yesNo}
                    showBorderError={false}
                    onChange={onInputChange}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="languageAtHome">
                  What language do you speak mainly at home?
                </Label>
                <div>
                  <RadioButtonList
                    name="languageAtHome"
                    ref={form.add}
                    validate={(val) =>
                      val === "-1" ||
                      val === "" ||
                      val === undefined ||
                      val === null
                        ? "Language at home is required."
                        : false
                    }
                    onFieldError={onFieldError}
                    value={languageAtHome}
                    showBorderError={false}
                    items={Step2Constants.languageAtHome}
                    onChange={onInputChange}
                  />
                </div>
              </FormGroup>
              {showOtherLanguage && (
                <FormGroup>
                  <Label for="languageAtHomeOther">
                    Please select the language you speak mainly at home
                  </Label>
                  <SelectField
                    name="languageAtHomeOther"
                    value={languageAtHomeOther}
                    ref={form.add}
                    onInputChange={onInputChange}
                    onFieldError={onFieldError}
                    validate={(val) =>
                      validateSelect("Other language at home", val)
                    }
                    showBorderError={false}
                    className="mxw-400"
                  >
                    <option value="-1">- Select -</option>
                    {Step2Constants.languages.map((item, i) => (
                      <option key={i} value={item.value}>
                        {item.label ? item.label : item.value}
                      </option>
                    ))}
                  </SelectField>
                </FormGroup>
              )}
              <FormGroup>
                <Label for="goodUnderstandingOfEnglish">
                  Do you have a good understanding of reading and writing
                  English?
                </Label>
                <div>
                  <RadioButtonList
                    name="goodUnderstandingOfEnglish"
                    value={goodUnderstandingOfEnglish}
                    ref={form.add}
                    validate={(val) =>
                      val === "-1" ||
                      val === "" ||
                      val === undefined ||
                      val === null
                        ? "Please indicate whether or not you have a good understanding of English"
                        : false
                    }
                    onFieldError={onFieldError}
                    showBorderError={false}
                    items={Step2Constants.yesNo}
                    onChange={onInputChange}
                  />
                </div>
              </FormGroup>
              {showUnderstandingOfEnglish && (
                <div className="more-info-box">
                  <p>
                    If you have any Language, Literacy or Numeracy issues that
                    may impact upon your studies and you feel you need any
                    assistance please contact{" "}
                    <a href="mailto:contact@traintogain.com.au">
                      contact@traintogain.com.au
                    </a>{" "}
                    for further information.{" "}
                    <a
                      href="/UserFiles/files/Student-Handbook-2024.07.v2.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ttg-btn"
                    >
                      View the Student Handbook
                    </a>
                  </p>
                </div>
              )}
            </ContentSection>
            <ContentSection bgColor="grey" padding={3}>
              <h5>Acknowledgment</h5>
              <FormGroup>
                <Label for="understandFalseInfo">
                  Do you understand that if you provide false information in
                  your enrolment process – you will be liable to discipline as
                  per our Student Handbook Fraudulent Activity Policy? &nbsp;
                  <a
                    href="/UserFiles/files/Student-Handbook-2024.07.v2.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ttg-btn"
                  >
                    View the Student Handbook
                  </a>
                </Label>
                <br />
                <RadioButtonList
                  name="understandFalseInfo"
                  value={understandFalseInfo}
                  ref={form.add}
                  validate={(val) =>
                    val === "-1" ||
                    val === "" ||
                    val === undefined ||
                    val === null
                      ? "Please tick that you understand about providing false information."
                      : false
                  }
                  onFieldError={onFieldError}
                  showBorderError={false}
                  items={Step2Constants.yesNo}
                  onChange={onInputChange}
                />
              </FormGroup>
              {showUnderstandFalseInfo && (
                <div className="more-info-box">
                  <p>
                    Please contact the office at{" "}
                    <a href="mailto:contact@traintogain.com.au">
                      contact@traintogain.com.au
                    </a>{" "}
                    to assist you in progressing further.
                  </p>
                </div>
              )}
              <FormGroup>
                <Label for="understandTimeToComplete">
                  Do you understand that you have 12 months from your enrolment
                  date to complete your course and that it is non refundable?
                  &nbsp;
                  <a
                    href="/UserFiles/files/Student-Handbook-2024.07.v2.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ttg-btn"
                  >
                    View the Policy
                  </a>
                </Label>
                <br />
                <RadioButtonList
                  name="understandTimeToComplete"
                  value={understandTimeToComplete}
                  showBorderError={false}
                  ref={form.add}
                  validate={(val) =>
                    val === "-1" ||
                    val === "" ||
                    val === undefined ||
                    val === null
                      ? "Please confirm you understand that you have 12 months to complete this course"
                      : false
                  }
                  onFieldError={onFieldError}
                  items={Step2Constants.yesNo}
                  onChange={onInputChange}
                />
              </FormGroup>
              {showUnderstandTimeToComplete && (
                <div className="more-info-box">
                  <p>
                    Please contact the office at{" "}
                    <a href="mailto:contact@traintogain.com.au">
                      contact@traintogain.com.au
                    </a>{" "}
                    to assist you in progressing further.
                  </p>
                </div>
              )}
              <FormGroup>
                <Label>Student Declaration and Consent</Label>
                <p>
                  I declare that the information I have provided to the best of
                  my knowledge is true and correct. I consent to the collection,
                  use and disclosure of my personal information in accordance
                  with the Privacy Notice attached.{" "}
                  <a
                    href="/Privacy-Policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ttg-btn"
                  >
                    View the Policy
                  </a>
                </p>
                <CheckboxField
                  ref={form.add}
                  validate={(val) =>
                    !val ? "Please tick the STUDENT acknowledgement." : false
                  }
                  name="studentAcknowledgment"
                  label="STUDENT acknowledgement (please tick this to continue)"
                  value={studentAcknowledgment}
                  onFieldError={onFieldError}
                  onChange={onInputChange}
                />
                {showGuardianAcknowledgment && (
                  <React.Fragment>
                    <div className="my-2">
                      <CheckboxField
                        ref={form.add}
                        validate={(val) =>
                          !val
                            ? "Please tick the PARENT/GUARDIAN acknowledgement."
                            : false
                        }
                        name="guardianAcknowledgment"
                        label="PARENT/GUARDIAN acknowledgement (please tick this to continue)"
                        value={guardianAcknowledgment}
                        onFieldError={onFieldError}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="my-2">
                      Parental/guardian consent is required for all students
                      under the age of 18.
                    </div>
                  </React.Fragment>
                )}
              </FormGroup>

              <FormGroup>
                <h4 className="my-4">
                  GREAT! Thanks for your patience{" "}
                  <i className="far fa-grin-alt" />
                </h4>

                <ButtonFx
                  type="submit"
                  className="ttg-btn"
                  isLoading={isProcessing}
                  onClick={() =>
                    form.validate({
                      validFn: () => {
                        onSubmitEnrolment();
                      },
                      invalidFn: () => console.log(form.getFieldErrors()),
                    })
                  }
                >
                  Continue
                </ButtonFx>
              </FormGroup>
            </ContentSection>
          </BlockUiFx>
        </Form>
        <KeyboardEventHandler
          handleKeys={["enter"]}
          onKeyEvent={(key, e) =>
            form.validate({
              validFn: () => onSubmitEnrolment(),
            })
          }
        />
      </>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 800,
});

export default withSizes(mapSizesToProps)(CourseEnrolmentStep2);
