import HttpClient from "coreLib/http/httpClient";

const ApiService = () => {
  const syncApiRootUrl = "v1/sync";
  const courseApiRootUrl = "v1/courses";

  const syncCoursesFromStaffd = () => {
    return HttpClient().post(`${syncApiRootUrl}/staffd-courses`);
  };

  const publishCourse = (courseId) => {
    return HttpClient().post(`${courseApiRootUrl}/${courseId}/publish`);
  };

  return {
    syncCoursesFromStaffd,
    publishCourse,
  };
};

export default ApiService;
