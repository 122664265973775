import { connect } from "react-redux";
import CorporateVouchersRegister from "./Corporate-Vouchers-Register";
import { selectCorporateVouchersRegister } from "./redux/reducer";
import * as actionCreators from "./redux/action-creators";

function mapStateToProps(state) {
  const corporateVouchersRegister = selectCorporateVouchersRegister(state);

  return {
    corporateVouchersRegister: corporateVouchersRegister
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.formInputChange(name, value, error));
  },
  dispatch: dispatch,
  history: ownProps.history
});

const CorporateVouchersRegisterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CorporateVouchersRegister);

export default CorporateVouchersRegisterContainer;
