import React, { Component } from "react";
import PropTypes from "prop-types";
import View from "./View";

class Carousel extends Component {
  render() {
    return <View items={this.props.items} />;
  }
}

Carousel.propTypes = {
  items: PropTypes.array.isRequired
};

export default Carousel;
