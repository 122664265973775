import { combineReducers } from "redux";
import SystemReducer from "./system/system-reducer";
import TemplateComponentReducer from "../components/Template-Component/redux/reducer";
import CourseEnrolmentReducer from "../views/Course-Enrolment/redux/reducers";
import RegisterBoxReducer from "../components/Register-Box/redux/reducer";
import ContactBoxReducer from "../components/Contact-Box/redux/reducer";
import YourCoursesReducer from "../views/Start/redux/reducer";
import CourseContentReducer from "../views/Course-Content/redux/reducer";
import CourseQuizReducer from "../views/Course-Quiz/redux/reducer";
import CertificateRegisterReducer from "../views/Certificates/redux/reducer";
import CorporateVouchersRegisterReducer from "../views/Corporate-Vouchers/redux/reducer";
import ThirdPartyFormReducer from "../views/Third-Party-Form/redux/reducer";
import UpdateProfileReducer from "../views/Update-Profile/redux/reducer";
import ChangePasswordReducer from "../views/Change-Password/redux/reducer";
import ForgotPasswordReducer from "../components/Forgot-Password/redux/reducer";
import ResetPasswordReducer from "../components/Reset-Password/redux/reducer";
import NSWReprintReducer from "../views/NSW-Reprint-Page/redux/reducers";
import CorporateTaxInvoiceReducer from "../views/Corporate-Tax-Invoice/redux/reducers";
import CorporateVoucherReducer from "../views/Coporate-Vouchers/redux/reducer";
import PurchaseCorporateVoucherReducer from "../views/Purchase-Corporate-Vouchers/redux/reducer";
import CourseSurveyReducer from "./../views/Course-Survey/redux/reducer";
import CourseCompletedReducer from "./../views/Course-Completed/redux/reducer";
import UsersReducer from "./../views/Admin/Users/redux/reducers";
import UserCoursesReducer from "./../views/Admin/UserCourses/redux/reducers";
import NswCertificateReducer from "./../views/Admin/Nsw-Certificates/redux/reducers";
import NswReprintsViewReducer from "./../views/Admin/Nsw-Reprints-View/redux/reducers";
import PromoCodeReducer from "./../views/Admin/Promo-Codes/redux/reducers";
import AdminVouchersReducer from "./../views/Admin/View-Voucher-Purchases/redux/reducers";
import AdminDownloadReport from "./../views/Admin/Download-Reports/redux/reducers";
import AdminEditCourse from "./../views/Admin/Edit-User-Course/redux/reducers";
import AuthoriseAvsReducer from "../views/Avetmiss/Authorise-Avs/redux/reducer";
import AvetmissReportingReducer from "../views/Avetmiss/Avetmiss-Reporting/redux/reducer";
import AvetmissReportingSubmissionReducer from "../views/Avetmiss/Avetmiss-Reporting-Submission/redux/reducer";
import AvetmissReportingValidationReducer from "../views/Avetmiss/Avetmiss-Reporting-Validation/redux/reducer";
import AvetmissAddressValidationReducer from "../views/Avetmiss/Avetmiss-Address-Validation/redux/reducer";
import SyncCoursesReducer from "views/Admin/Sync/redux/reducer";

/**
 * Module reducer registration is per module
 */
const RootReducer = combineReducers({
  system: SystemReducer,
  templateComponent: TemplateComponentReducer,
  courseEnrolment: CourseEnrolmentReducer,
  register: RegisterBoxReducer,
  contact: ContactBoxReducer,
  yourCourses: YourCoursesReducer,
  courseContent: CourseContentReducer,
  courseQuiz: CourseQuizReducer,
  certificateRegister: CertificateRegisterReducer,
  corporateVouchersRegister: CorporateVouchersRegisterReducer,
  thirdPartyForm: ThirdPartyFormReducer,
  updateProfile: UpdateProfileReducer,
  changePassword: ChangePasswordReducer,
  forgotPassword: ForgotPasswordReducer,
  resetPassword: ResetPasswordReducer,
  nswReprint: NSWReprintReducer,
  corporateTaxInvoice: CorporateTaxInvoiceReducer,
  purchaseCorporateVoucher: PurchaseCorporateVoucherReducer,
  corporateVouchers: CorporateVoucherReducer,
  survey: CourseSurveyReducer,
  courseCompleted: CourseCompletedReducer,
  users: UsersReducer,
  userCourses: UserCoursesReducer,
  nswCertificates: NswCertificateReducer,
  nswReprintsView: NswReprintsViewReducer,
  promoCode: PromoCodeReducer,
  adminVouchers: AdminVouchersReducer,
  adminDownloadReport: AdminDownloadReport,
  adminEditCourse: AdminEditCourse,
  authoriseAvs: AuthoriseAvsReducer,
  avetmissReporting: AvetmissReportingReducer,
  avetmissReportingSubmission: AvetmissReportingSubmissionReducer,
  avetmissReportingValidation: AvetmissReportingValidationReducer,
  avetmissAddressValidation: AvetmissAddressValidationReducer,
  syncCourses: SyncCoursesReducer,
});

export default RootReducer;
