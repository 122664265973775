import React, { Component } from "react";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import ContentSection from "../../components/Content-Section";
import Accordion from "../../components/Common/Accordion/Accordion";
import * as Constants from "../../constants/faq-content";
import "./faqs.scss";

class FAQs extends Component {
  constructor(props) {
    super(props);

    this.toggleTab = this.toggleTab.bind(this);
    this.state = {
      activeTab: "1"
    };
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <PageTitle title="Frequently Asked Questions" />
        <ContentSection bgColor="grey-shadow" padding={4}>
          <h1 className="my-0">Frequently Asked Questions</h1>
        </ContentSection>
        <ContentSection padding={3}>
          <Nav tabs className="faq-tabs">
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                style={{
                  cursor: "pointer"
                }}
                onClick={() => {
                  this.toggleTab("1");
                }}
              >
                General
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "2" })}
                style={{
                  cursor: "pointer"
                }}
                onClick={() => {
                  this.toggleTab("2");
                }}
              >
                USI - Unique Student Identifier
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "3" })}
                style={{
                  cursor: "pointer"
                }}
                onClick={() => {
                  this.toggleTab("3");
                }}
              >
                Third Party Observer/Assessment
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "4" })}
                style={{
                  cursor: "pointer"
                }}
                onClick={() => {
                  this.toggleTab("4");
                }}
              >
                NSW Students
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <Accordion content={Constants.general} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <Accordion content={Constants.usi} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col sm="12">
                  <Accordion content={Constants.thirdParty} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="4">
              <Row>
                <Col sm="12">
                  <Accordion content={Constants.nsw} />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </ContentSection>
      </React.Fragment>
    );
  }
}

export default FAQs;
