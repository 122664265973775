import { connect } from "react-redux";
import CertificateRegister from "./Certificate-Register";
import { selectCertificateRegister } from "./redux/reducer";
import * as actionCreators from "./redux/action-creators";

function mapStateToProps(state) {
  const certificateRegister = selectCertificateRegister(state);

  return {
    certificateRegister: certificateRegister
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.formInputChange(name, value, error));
  },
  dispatch: dispatch,
  history: ownProps.history
});

const CertificateRegisterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificateRegister);

export default CertificateRegisterContainer;
