import React from "react";

export const courses = [
  {
    courseId: 6, // NB: this uses the same quiz questions as courseId: 4
    courseTitle: "NSW Food Safety Supervisor Certificate",
    courseCost: 179,
    courseContentPrefix: "FSSHR",
    certificateCodes: ["SITXFSA005", "SITXFSA006"],
    certificateCodesString: "SITXFSA005, SITXFSA006",
    unitOfCompetencies: [
      "Use Hygienic Practices for Food Safety",
      "Participate in Safe Food Handling Practices",
    ],
    courseImage:
      "https://www.foodsafetyeducation.com.au/userfiles/images/courses/food-safety-supervisor-hospitality-and-retail-nsw.jpg",
    validStates: (
      <>
        {/* For Hospitality Workers{" "}
        <strong>
          <u>in&nbsp;NSW</u>
        </strong> */}
      </>
    ),
    courseReadMorePage: "/map/nsw/",
    courseGuideUrl: "/Print-Course-Content-Guide/6/",
  },
  {
    courseId: 8, // NB: this uses the same quiz questions as courseId: 4
    courseTitle:
      "NSW Refresher Course - Includes your NSW Food Safety Supervisor Certificate",
    courseCost: 149,
    courseContentPrefix: "FSSHR",
    certificateCodes: ["SITXFSA005", "SITXFSA006"],
    certificateCodesString: "SITXFSA005, SITXFSA006",
    unitOfCompetencies: [
      "Use Hygienic Practices for Food Safety",
      "Participate in Safe Food Handling Practices",
    ],
    courseImage:
      "https://www.foodsafetyeducation.com.au/userfiles/images/courses/nsw-refresher-course.jpg",
    validStates: (
      <>
        {/* For Hospitality Workers{" "}
        <strong>
          <u>in&nbsp;NSW</u>
        </strong> */}
      </>
    ),
    courseReadMorePage: "/nsw-food-safety-supervisor-refresher-course/",
    courseGuideUrl: "/Print-Course-Content-Guide/8/",
  },
  {
    courseId: 4,
    courseTitle: "Food Safety Supervisor Hospitality & Retail",
    courseCost: 139,
    courseContentPrefix: "FSSHR",
    certificateCodes: ["SITXFSA005", "SITXFSA006"],
    certificateCodesString: "SITXFSA005, SITXFSA006",
    unitOfCompetencies: [
      "Use Hygienic Practices for Food Safety",
      "Participate in Safe Food Handling Practices",
    ],
    courseImage:
      "https://www.foodsafetyeducation.com.au/userfiles/images/courses/food-safety-supervisor-hospitality-and-retail.jpg",
    validStates: (
      <>
        Nationwide{" "}
        <strong>
          <u>Except&nbsp;NSW</u>
        </strong>
      </>
    ),
    courseReadMorePage: "/food-safety-supervisor-hospitality-and-retail/",
    courseGuideUrl: "/Print-Course-Content-Guide/4/",
  },
  {
    courseId: 2,
    courseTitle: "Food Handling Level 1 - Hospitality & Retail",
    courseCost: 55,
    courseContentPrefix: "FH1",
    certificateCodes: ["SITXFSA005"],
    certificateCodesString: "SITXFSA005",
    unitOfCompetencies: ["Use Hygienic Practices for Food Safety"],
    courseImage:
      "https://www.foodsafetyeducation.com.au/userfiles/images/courses/food-handling-level-1.jpg",
    courseReadMorePage: "/food-handling-level-1/",
    courseGuideUrl: "/Print-Course-Content-Guide/2/",
  },
  {
    courseId: 3,
    courseTitle: "Food Handling Level 2 - Hospitality & Retail",
    courseCost: 89,
    courseContentPrefix: "FH2",
    certificateCodes: ["SITXFSA006"],
    certificateCodesString: "SITXFSA006",
    unitOfCompetencies: ["Participate in Safe Food Handling Practices"],
    courseImage:
      "https://www.foodsafetyeducation.com.au/userfiles/images/courses/food-handling-level-2.jpg",
    courseReadMorePage: "/food-handling-level-2/",
    courseGuideUrl: "/Print-Course-Content-Guide/3/",
  },
  {
    courseId: 1009,
    courseTitle: "Food Handling Level 1 - Health & Community",
    courseCost: 50,
    courseContentPrefix: "FSSACL1",
    certificateCodes: ["HLTFSE001"],
    certificateCodesString: "HLTFSE001",
    unitOfCompetencies: ["Follow basic food safety practices"],
    courseImage:
      "https://www.foodsafetyeducation.com.au/userfiles/images/courses/food-handling-level-1-aged-care-and-child-care.jpg",
    courseReadMorePage: "/food-handling-level-1-aged-care-and-child-care/",
    courseGuideUrl: "/Print-Course-Content-Guide/1009/",
  },
  {
    courseId: 1008,
    courseTitle: "Food Safety Supervisor Health & Community",
    courseCost: 198,
    courseContentPrefix: "FSSAC",
    certificateCodes: ["HLTFSE001", "HLTFSE005", "HLTFSE007"],
    certificateCodesString: "HLTFSE001, HLTFSE005, HLTFSE007",
    unitOfCompetencies: [
      "Follow basic food safety practices",
      "Apply and monitor food safety requirements",
      "Oversee the day-to-day implementation of food safety in the workplace",
    ],
    courseImage:
      "https://www.foodsafetyeducation.com.au/userfiles/images/courses/food-safety-supervisor-aged-care-and-child-care.jpg",
    courseReadMorePage: "/food-safety-supervisor-aged-care-and-child-care/",
    courseGuideUrl: "/Print-Course-Content-Guide/1008/",
  },
];

export const nswCertificateReprint = {
  courseId: 7,
  courseTitle: "The NSW Food Safety Supervisor Certificate Reprint",
  courseCost: 45,
  courseContentPrefix: "FSSHR",
  certificateCodes: ["SITXFSA005", "SITXFSA006"],
  certificateCodesString: "SITXFSA005, SITXFSA006",
  unitOfCompetencies: [
    "Use Hygienic Practices for Food Safety",
    "Participate in Safe Food Handling Practices",
  ],
  courseImage:
    "https://www.foodsafetyeducation.com.au/userfiles/images/courses/food-safety-supervisor-hospitality-and-retail-nsw.jpg",
  validStates: (
    <>
      {/* For Hospitality Workers{" "}
      <strong>
        <u>in&nbsp;NSW</u>
      </strong> */}
    </>
  ),
  courseReadMorePage: "/map/nsw/",
  courseGuideUrl: "/Print-Course-Content-Guide/6/",
};

export const thirdPartyFormTasks = [
  {
    taskId: 28,
    courseId: 2,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task: "Follow organisational hygiene procedures.",
    displayOrder: 1,
  },
  {
    taskId: 29,
    courseId: 2,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task: "Promptly report unsafe practices that breach hygiene procedures.",
    displayOrder: 2,
  },
  {
    taskId: 30,
    courseId: 2,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task:
      "Identify food hazards that may affect the health and safety of customers, colleagues and self",
    displayOrder: 3,
  },
  {
    taskId: 31,
    courseId: 2,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task:
      "Remove or minimise the hygiene hazard and report to appropriate person for follow up.",
    displayOrder: 4,
  },
  {
    taskId: 32,
    courseId: 2,
    taskGroup: "Task 2: Report any personal health issues.",
    task: "Report any personal health issues likely to cause a hygiene risk.",
    displayOrder: 5,
  },
  {
    taskId: 33,
    courseId: 2,
    taskGroup: "Task 2: Report any personal health issues.",
    task:
      "Report incidents of food contamination resulting from personal health issues.",
    displayOrder: 6,
  },
  {
    taskId: 34,
    courseId: 2,
    taskGroup: "Task 2: Report any personal health issues.",
    task:
      "Cease participation in food handling activities where a health issue may cause food contamination.",
    displayOrder: 7,
  },
  {
    taskId: 35,
    courseId: 2,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Maintain clean clothes, wear required personal protective clothing and only use organisation-approved bandages and dressings.",
    displayOrder: 8,
  },
  {
    taskId: 36,
    courseId: 2,
    taskGroup: "Task 3: Prevent food contamination",
    task: "Prevent food contamination from clothing and other items worn.",
    displayOrder: 9,
  },
  {
    taskId: 37,
    courseId: 2,
    taskGroup: "Task 3: Prevent food contamination",
    task: "Prevent unnecessary direct contact with ready to eat food.",
    displayOrder: 10,
  },
  {
    taskId: 38,
    courseId: 2,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Avoid unhygienic personal contact with food or food contact surfaces.",
    displayOrder: 11,
  },
  {
    taskId: 39,
    courseId: 2,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Avoid unhygienic cleaning practices that may cause food-borne illnesses.",
    displayOrder: 12,
  },
  {
    taskId: 40,
    courseId: 2,
    taskGroup: "Task 4: Prevent cross contamination by washing hands",
    task:
      "Wash hands at appropriate times and follow hand washing procedures consistently.",
    displayOrder: 13,
  },
  {
    taskId: 41,
    courseId: 2,
    taskGroup: "Task 4: Prevent cross contamination by washing hands",
    task: "Wash hands using appropriate facilities.",
    displayOrder: 14,
  },
  {
    taskId: 42,
    courseId: 2,
    taskGroup: "General 1: Communication skills",
    task: "Verbal communication of hazards",
    displayOrder: 15,
  },
  {
    taskId: 43,
    courseId: 2,
    taskGroup: "General 1: Communication skills",
    task:
      "Communication skills to verbally report hygiene hazards and poor organisational practice",
    displayOrder: 16,
  },
  {
    taskId: 44,
    courseId: 2,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the organisational food safety program",
    displayOrder: 17,
  },
  {
    taskId: 45,
    courseId: 2,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the hygiene and food safety procedures",
    displayOrder: 18,
  },
  {
    taskId: 46,
    courseId: 2,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the Hazard Analysis and Critical Control Points (HACCP) practices",
    displayOrder: 19,
  },
  {
    taskId: 47,
    courseId: 2,
    taskGroup:
      "General 3: Problem solving skills – Identifying and reporting hazards",
    task: "Problem-solving skills to identify and report hygiene hazards",
    displayOrder: 20,
  },
  {
    taskId: 1,
    courseId: 3,
    taskGroup: "Task 1: Follow food safety program",
    task:
      "Access and use relevant information from organisational food safety program.",
    displayOrder: 1,
  },
  {
    taskId: 2,
    courseId: 3,
    taskGroup: "Task 1: Follow food safety program",
    task: "Follow policies and procedures in food safety program.",
    displayOrder: 2,
  },
  {
    taskId: 3,
    courseId: 3,
    taskGroup: "Task 1: Follow food safety program",
    task: "Control food hazards at critical control points.",
    displayOrder: 3,
  },
  {
    taskId: 4,
    courseId: 3,
    taskGroup: "Task 1: Follow food safety program",
    task:
      "Complete food safety monitoring processes and complete documents as required.",
    displayOrder: 4,
  },
  {
    taskId: 5,
    courseId: 3,
    taskGroup: "Task 1: Follow food safety program",
    task: "Identify and report non-conforming practices.",
    displayOrder: 5,
  },
  {
    taskId: 6,
    courseId: 3,
    taskGroup: "Task 1: Follow food safety program",
    task:
      "Take corrective actions within scope of job responsibility for incidents where food hazards are not controlled.",
    displayOrder: 6,
  },
  {
    taskId: 7,
    courseId: 3,
    taskGroup: "Task 2: Store food safely",
    task: "Select food storage conditions for specific food type.",
    displayOrder: 7,
  },
  {
    taskId: 8,
    courseId: 3,
    taskGroup: "Task 2: Store food safely",
    task:
      "Store food in environmental conditions that protect against contamination and maximise freshness, quality and appearance.",
    displayOrder: 8,
  },
  {
    taskId: 9,
    courseId: 3,
    taskGroup: "Task 2: Store food safely",
    task:
      "Store food at controlled temperatures and ensure that frozen items remain frozen during storage.",
    displayOrder: 9,
  },
  {
    taskId: 10,
    courseId: 3,
    taskGroup: "Task 3: Prepare food safely",
    task:
      "Use cooling and heating processes that support microbiological safety of the food.",
    displayOrder: 10,
  },
  {
    taskId: 11,
    courseId: 3,
    taskGroup: "Task 3: Prepare food safely",
    task:
      "Monitor food temperature during preparation using required temperature measuring device to achieve microbiological safety.",
    displayOrder: 11,
  },
  {
    taskId: 12,
    courseId: 3,
    taskGroup: "Task 3: Prepare food safely",
    task:
      "Ensure safety of food prepared, served and sold to customers under other conditions.",
    displayOrder: 12,
  },
  {
    taskId: 13,
    courseId: 3,
    taskGroup: "Task 4: Provide safe single use items",
    task:
      "Store, display and provide single use items so they are protected from damage and contamination.",
    displayOrder: 13,
  },
  {
    taskId: 14,
    courseId: 3,
    taskGroup: "Task 4: Provide safe single use items",
    task: "Follow instructions for items intended for single use.",
    displayOrder: 14,
  },
  {
    taskId: 15,
    courseId: 3,
    taskGroup: "Task 5: Maintain a clean environment",
    task: "Clean and sanitise equipment, surfaces and utensils.",
    displayOrder: 15,
  },
  {
    taskId: 16,
    courseId: 3,
    taskGroup: "Task 5: Maintain a clean environment",
    task:
      "Use appropriate containers and prevent accumulation of garbage and recycled matter.",
    displayOrder: 16,
  },
  {
    taskId: 17,
    courseId: 3,
    taskGroup: "Task 5: Maintain a clean environment",
    task:
      "Identify and report cleaning, sanitising and maintenance requirements.",
    displayOrder: 17,
  },
  {
    taskId: 18,
    courseId: 3,
    taskGroup: "Task 5: Maintain a clean environment",
    task:
      "Dispose of or report chipped, broken or cracked eating, drinking or food handling utensils.",
    displayOrder: 18,
  },
  {
    taskId: 19,
    courseId: 3,
    taskGroup: "Task 5: Maintain a clean environment",
    task:
      "Take measures within scope of responsibility to ensure food handling areas are free from animals and pests and report incidents of animal or pest infestation.",
    displayOrder: 19,
  },
  {
    taskId: 20,
    courseId: 3,
    taskGroup: "Task 6: Dispose of food safely",
    task:
      "Mark and keep separate from other foodstuffs any food identified for disposal until disposal is complete.",
    displayOrder: 20,
  },
  {
    taskId: 21,
    courseId: 3,
    taskGroup: "Task 6: Dispose of food safely",
    task: "Dispose of food promptly to avoid cross contamination.",
    displayOrder: 21,
  },
  {
    taskId: 22,
    courseId: 3,
    taskGroup:
      "General 1: Communication skills – Verbal communication of hazards",
    task:
      "Communication skills to verbally report hygiene hazards and poor organisational practice",
    displayOrder: 22,
  },
  {
    taskId: 23,
    courseId: 3,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to read and interpret food safety program, including policies, procedures and flow charts that identify critical control points",
    displayOrder: 23,
  },
  {
    taskId: 24,
    courseId: 3,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend and complete documentation for monitoring food safety",
    displayOrder: 24,
  },
  {
    taskId: 25,
    courseId: 3,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the Hazard Analysis and Critical Control Points (HACCP) practices",
    displayOrder: 25,
  },
  {
    taskId: 26,
    courseId: 3,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Numeracy skills to calibrate and use a temperature probe and calculate timings",
    displayOrder: 26,
  },
  {
    taskId: 27,
    courseId: 3,
    taskGroup:
      "General 3: Problem solving skills – Identifying and reporting hazards",
    task:
      "Problem-solving skills to identify and respond to routine food safety issues",
    displayOrder: 27,
  },
  {
    taskId: 113,
    courseId: 4,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task: "Follow organisational hygiene procedures.",
    displayOrder: 1,
  },
  {
    taskId: 114,
    courseId: 4,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task: "Promptly report unsafe practices that breach hygiene procedures.",
    displayOrder: 2,
  },
  {
    taskId: 115,
    courseId: 4,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task:
      "Identify food hazards that may affect the health and safety of customers, colleagues and self",
    displayOrder: 3,
  },
  {
    taskId: 116,
    courseId: 4,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task:
      "Remove or minimise the hygiene hazard and report to appropriate person for follow up.",
    displayOrder: 4,
  },
  {
    taskId: 117,
    courseId: 4,
    taskGroup: "Task 2: Report any personal health issues.",
    task: "Report any personal health issues likely to cause a hygiene risk.",
    displayOrder: 5,
  },
  {
    taskId: 118,
    courseId: 4,
    taskGroup: "Task 2: Report any personal health issues.",
    task:
      "Report incidents of food contamination resulting from personal health issues.",
    displayOrder: 6,
  },
  {
    taskId: 119,
    courseId: 4,
    taskGroup: "Task 2: Report any personal health issues.",
    task:
      "Cease participation in food handling activities where a health issue may cause food contamination.",
    displayOrder: 7,
  },
  {
    taskId: 120,
    courseId: 4,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Maintain clean clothes, wear required personal protective clothing and only use organisation-approved bandages and dressings.",
    displayOrder: 8,
  },
  {
    taskId: 121,
    courseId: 4,
    taskGroup: "Task 3: Prevent food contamination",
    task: "Prevent food contamination from clothing and other items worn.",
    displayOrder: 9,
  },
  {
    taskId: 122,
    courseId: 4,
    taskGroup: "Task 3: Prevent food contamination",
    task: "Prevent unnecessary direct contact with ready to eat food.",
    displayOrder: 10,
  },
  {
    taskId: 123,
    courseId: 4,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Avoid unhygienic personal contact with food or food contact surfaces.",
    displayOrder: 11,
  },
  {
    taskId: 124,
    courseId: 4,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Avoid unhygienic cleaning practices that may cause food-borne illnesses.",
    displayOrder: 12,
  },
  {
    taskId: 125,
    courseId: 4,
    taskGroup: "Task 4: Prevent cross contamination by washing hands",
    task:
      "Wash hands at appropriate times and follow hand washing procedures consistently.",
    displayOrder: 13,
  },
  {
    taskId: 126,
    courseId: 4,
    taskGroup: "Task 4: Prevent cross contamination by washing hands",
    task: "Wash hands using appropriate facilities.",
    displayOrder: 14,
  },
  {
    taskId: 127,
    courseId: 4,
    taskGroup: "Task 5: Follow food safety program",
    task:
      "Access and use relevant information from organisational food safety program.",
    displayOrder: 15,
  },
  {
    taskId: 128,
    courseId: 4,
    taskGroup: "Task 5: Follow food safety program",
    task: "Follow policies and procedures in food safety program.",
    displayOrder: 16,
  },
  {
    taskId: 129,
    courseId: 4,
    taskGroup: "Task 5: Follow food safety program",
    task: "Control food hazards at critical control points.",
    displayOrder: 17,
  },
  {
    taskId: 130,
    courseId: 4,
    taskGroup: "Task 5: Follow food safety program",
    task:
      "Complete food safety monitoring processes and complete documents as required.",
    displayOrder: 18,
  },
  {
    taskId: 131,
    courseId: 4,
    taskGroup: "Task 5: Follow food safety program",
    task: "Identify and report non-conforming practices.",
    displayOrder: 19,
  },
  {
    taskId: 132,
    courseId: 4,
    taskGroup: "Task 5: Follow food safety program",
    task:
      "Take corrective actions within scope of job responsibility for incidents where food hazards are not controlled.",
    displayOrder: 20,
  },
  {
    taskId: 133,
    courseId: 4,
    taskGroup: "Task 6: Store food safely",
    task: "Select food storage conditions for specific food type.",
    displayOrder: 21,
  },
  {
    taskId: 134,
    courseId: 4,
    taskGroup: "Task 6: Store food safely",
    task:
      "Store food in environmental conditions that protect against contamination and maximise freshness, quality and appearance.",
    displayOrder: 22,
  },
  {
    taskId: 135,
    courseId: 4,
    taskGroup: "Task 6: Store food safely",
    task:
      "Store food at controlled temperatures and ensure that frozen items remain frozen during storage.",
    displayOrder: 23,
  },
  {
    taskId: 136,
    courseId: 4,
    taskGroup: "Task 7: Prepare food safely",
    task:
      "Use cooling and heating processes that support microbiological safety of the food.",
    displayOrder: 24,
  },
  {
    taskId: 137,
    courseId: 4,
    taskGroup: "Task 7: Prepare food safely",
    task:
      "Monitor food temperature during preparation using required temperature measuring device to achieve microbiological safety.",
    displayOrder: 25,
  },
  {
    taskId: 138,
    courseId: 4,
    taskGroup: "Task 7: Prepare food safely",
    task:
      "Ensure safety of food prepared, served and sold to customers under other conditions.",
    displayOrder: 26,
  },
  {
    taskId: 139,
    courseId: 4,
    taskGroup: "Task 8: Provide safe single use items",
    task:
      "Store, display and provide single use items so they are protected from damage and contamination.",
    displayOrder: 27,
  },
  {
    taskId: 140,
    courseId: 4,
    taskGroup: "Task 8: Provide safe single use items",
    task: "Follow instructions for items intended for single use.",
    displayOrder: 28,
  },
  {
    taskId: 141,
    courseId: 4,
    taskGroup: "Task 9: Maintain a clean environment",
    task: "Clean and sanitise equipment, surfaces and utensils.",
    displayOrder: 29,
  },
  {
    taskId: 142,
    courseId: 4,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Use appropriate containers and prevent accumulation of garbage and recycled matter.",
    displayOrder: 30,
  },
  {
    taskId: 143,
    courseId: 4,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Identify and report cleaning, sanitising and maintenance requirements.",
    displayOrder: 31,
  },
  {
    taskId: 144,
    courseId: 4,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Dispose of or report chipped, broken or cracked eating, drinking or food handling utensils.",
    displayOrder: 32,
  },
  {
    taskId: 145,
    courseId: 4,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Take measures within scope of responsibility to ensure food handling areas are free from animals and pests and report incidents of animal or pest infestation.",
    displayOrder: 33,
  },
  {
    taskId: 146,
    courseId: 4,
    taskGroup: "Task 10: Dispose of food safely",
    task:
      "Mark and keep separate from other foodstuffs any food identified for disposal until disposal is complete.",
    displayOrder: 34,
  },
  {
    taskId: 147,
    courseId: 4,
    taskGroup: "Task 10: Dispose of food safely",
    task: "Dispose of food promptly to avoid cross contamination.",
    displayOrder: 35,
  },
  {
    taskId: 148,
    courseId: 4,
    taskGroup: "General 1: Communication skills",
    task: "Verbal communication of hazards",
    displayOrder: 36,
  },
  {
    taskId: 149,
    courseId: 4,
    taskGroup: "General 1: Communication skills",
    task:
      "Communication skills to verbally report hygiene hazards and poor organisational practice",
    displayOrder: 37,
  },
  {
    taskId: 150,
    courseId: 4,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the organisational food safety program",
    displayOrder: 38,
  },
  {
    taskId: 151,
    courseId: 4,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the hygiene and food safety procedures",
    displayOrder: 39,
  },
  {
    taskId: 152,
    courseId: 4,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the Hazard Analysis and Critical Control Points (HACCP) practices",
    displayOrder: 40,
  },
  {
    taskId: 153,
    courseId: 4,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to read and interpret food safety program, including policies, procedures and flow charts that identify critical control points",
    displayOrder: 41,
  },
  {
    taskId: 154,
    courseId: 4,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend and complete documentation for monitoring food safety",
    displayOrder: 42,
  },
  {
    taskId: 155,
    courseId: 4,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Numeracy skills to calibrate and use a temperature probe and calculate timings",
    displayOrder: 43,
  },
  {
    taskId: 156,
    courseId: 4,
    taskGroup:
      "General 3: Problem solving skills – Identifying and reporting hazards",
    task: "Problem-solving skills to identify and report hygiene hazards",
    displayOrder: 44,
  },
  {
    taskId: 157,
    courseId: 4,
    taskGroup:
      "General 3: Problem solving skills – Identifying and reporting hazards",
    task:
      "Problem-solving skills to identify and respond to routine food safety issues",
    displayOrder: 45,
  },
  {
    taskId: 158,
    courseId: 6,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task: "Follow organisational hygiene procedures.",
    displayOrder: 1,
  },
  {
    taskId: 159,
    courseId: 6,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task: "Promptly report unsafe practices that breach hygiene procedures.",
    displayOrder: 2,
  },
  {
    taskId: 160,
    courseId: 6,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task:
      "Identify food hazards that may affect the health and safety of customers, colleagues and self",
    displayOrder: 3,
  },
  {
    taskId: 161,
    courseId: 6,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task:
      "Remove or minimise the hygiene hazard and report to appropriate person for follow up.",
    displayOrder: 4,
  },
  {
    taskId: 162,
    courseId: 6,
    taskGroup: "Task 2: Report any personal health issues.",
    task: "Report any personal health issues likely to cause a hygiene risk.",
    displayOrder: 5,
  },
  {
    taskId: 163,
    courseId: 6,
    taskGroup: "Task 2: Report any personal health issues.",
    task:
      "Report incidents of food contamination resulting from personal health issues.",
    displayOrder: 6,
  },
  {
    taskId: 164,
    courseId: 6,
    taskGroup: "Task 2: Report any personal health issues.",
    task:
      "Cease participation in food handling activities where a health issue may cause food contamination.",
    displayOrder: 7,
  },
  {
    taskId: 165,
    courseId: 6,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Maintain clean clothes, wear required personal protective clothing and only use organisation-approved bandages and dressings.",
    displayOrder: 8,
  },
  {
    taskId: 166,
    courseId: 6,
    taskGroup: "Task 3: Prevent food contamination",
    task: "Prevent food contamination from clothing and other items worn.",
    displayOrder: 9,
  },
  {
    taskId: 167,
    courseId: 6,
    taskGroup: "Task 3: Prevent food contamination",
    task: "Prevent unnecessary direct contact with ready to eat food.",
    displayOrder: 10,
  },
  {
    taskId: 168,
    courseId: 6,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Avoid unhygienic personal contact with food or food contact surfaces.",
    displayOrder: 11,
  },
  {
    taskId: 169,
    courseId: 6,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Avoid unhygienic cleaning practices that may cause food-borne illnesses.",
    displayOrder: 12,
  },
  {
    taskId: 170,
    courseId: 6,
    taskGroup: "Task 4: Prevent cross contamination by washing hands",
    task:
      "Wash hands at appropriate times and follow hand washing procedures consistently.",
    displayOrder: 13,
  },
  {
    taskId: 171,
    courseId: 6,
    taskGroup: "Task 4: Prevent cross contamination by washing hands",
    task: "Wash hands using appropriate facilities.",
    displayOrder: 14,
  },
  {
    taskId: 172,
    courseId: 6,
    taskGroup: "Task 5: Follow food safety program",
    task:
      "Access and use relevant information from organisational food safety program.",
    displayOrder: 15,
  },
  {
    taskId: 173,
    courseId: 6,
    taskGroup: "Task 5: Follow food safety program",
    task: "Follow policies and procedures in food safety program.",
    displayOrder: 16,
  },
  {
    taskId: 174,
    courseId: 6,
    taskGroup: "Task 5: Follow food safety program",
    task: "Control food hazards at critical control points.",
    displayOrder: 17,
  },
  {
    taskId: 175,
    courseId: 6,
    taskGroup: "Task 5: Follow food safety program",
    task:
      "Complete food safety monitoring processes and complete documents as required.",
    displayOrder: 18,
  },
  {
    taskId: 176,
    courseId: 6,
    taskGroup: "Task 5: Follow food safety program",
    task: "Identify and report non-conforming practices.",
    displayOrder: 19,
  },
  {
    taskId: 177,
    courseId: 6,
    taskGroup: "Task 5: Follow food safety program",
    task:
      "Take corrective actions within scope of job responsibility for incidents where food hazards are not controlled.",
    displayOrder: 20,
  },
  {
    taskId: 178,
    courseId: 6,
    taskGroup: "Task 6: Store food safely",
    task: "Select food storage conditions for specific food type.",
    displayOrder: 21,
  },
  {
    taskId: 179,
    courseId: 6,
    taskGroup: "Task 6: Store food safely",
    task:
      "Store food in environmental conditions that protect against contamination and maximise freshness, quality and appearance.",
    displayOrder: 22,
  },
  {
    taskId: 180,
    courseId: 6,
    taskGroup: "Task 6: Store food safely",
    task:
      "Store food at controlled temperatures and ensure that frozen items remain frozen during storage.",
    displayOrder: 23,
  },
  {
    taskId: 181,
    courseId: 6,
    taskGroup: "Task 7: Prepare food safely",
    task:
      "Use cooling and heating processes that support microbiological safety of the food.",
    displayOrder: 24,
  },
  {
    taskId: 182,
    courseId: 6,
    taskGroup: "Task 7: Prepare food safely",
    task:
      "Monitor food temperature during preparation using required temperature measuring device to achieve microbiological safety.",
    displayOrder: 25,
  },
  {
    taskId: 183,
    courseId: 6,
    taskGroup: "Task 7: Prepare food safely",
    task:
      "Ensure safety of food prepared, served and sold to customers under other conditions.",
    displayOrder: 26,
  },
  {
    taskId: 184,
    courseId: 6,
    taskGroup: "Task 8: Provide safe single use items",
    task:
      "Store, display and provide single use items so they are protected from damage and contamination.",
    displayOrder: 27,
  },
  {
    taskId: 185,
    courseId: 6,
    taskGroup: "Task 8: Provide safe single use items",
    task: "Follow instructions for items intended for single use.",
    displayOrder: 28,
  },
  {
    taskId: 186,
    courseId: 6,
    taskGroup: "Task 9: Maintain a clean environment",
    task: "Clean and sanitise equipment, surfaces and utensils.",
    displayOrder: 29,
  },
  {
    taskId: 187,
    courseId: 6,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Use appropriate containers and prevent accumulation of garbage and recycled matter.",
    displayOrder: 30,
  },
  {
    taskId: 188,
    courseId: 6,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Identify and report cleaning, sanitising and maintenance requirements.",
    displayOrder: 31,
  },
  {
    taskId: 189,
    courseId: 6,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Dispose of or report chipped, broken or cracked eating, drinking or food handling utensils.",
    displayOrder: 32,
  },
  {
    taskId: 190,
    courseId: 6,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Take measures within scope of responsibility to ensure food handling areas are free from animals and pests and report incidents of animal or pest infestation.",
    displayOrder: 33,
  },
  {
    taskId: 191,
    courseId: 6,
    taskGroup: "Task 10: Dispose of food safely",
    task:
      "Mark and keep separate from other foodstuffs any food identified for disposal until disposal is complete.",
    displayOrder: 34,
  },
  {
    taskId: 192,
    courseId: 6,
    taskGroup: "Task 10: Dispose of food safely",
    task: "Dispose of food promptly to avoid cross contamination.",
    displayOrder: 35,
  },
  {
    taskId: 193,
    courseId: 6,
    taskGroup: "General 1: Communication skills",
    task: "Verbal communication of hazards",
    displayOrder: 36,
  },
  {
    taskId: 194,
    courseId: 6,
    taskGroup: "General 1: Communication skills",
    task:
      "Communication skills to verbally report hygiene hazards and poor organisational practice",
    displayOrder: 37,
  },
  {
    taskId: 195,
    courseId: 6,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the organisational food safety program",
    displayOrder: 38,
  },
  {
    taskId: 196,
    courseId: 6,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the hygiene and food safety procedures",
    displayOrder: 39,
  },
  {
    taskId: 197,
    courseId: 6,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the Hazard Analysis and Critical Control Points (HACCP) practices",
    displayOrder: 40,
  },
  {
    taskId: 198,
    courseId: 6,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to read and interpret food safety program, including policies, procedures and flow charts that identify critical control points",
    displayOrder: 41,
  },
  {
    taskId: 199,
    courseId: 6,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend and complete documentation for monitoring food safety",
    displayOrder: 42,
  },
  {
    taskId: 200,
    courseId: 6,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Numeracy skills to calibrate and use a temperature probe and calculate timings",
    displayOrder: 43,
  },
  {
    taskId: 201,
    courseId: 6,
    taskGroup:
      "General 3: Problem solving skills – Identifying and reporting hazards",
    task: "Problem-solving skills to identify and report hygiene hazards",
    displayOrder: 44,
  },
  {
    taskId: 202,
    courseId: 6,
    taskGroup:
      "General 3: Problem solving skills – Identifying and reporting hazards",
    task:
      "Problem-solving skills to identify and respond to routine food safety issues",
    displayOrder: 45,
  },
  {
    taskId: 238,
    courseId: 8,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task: "Follow organisational hygiene procedures.",
    displayOrder: 1,
  },
  {
    taskId: 239,
    courseId: 8,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task: "Promptly report unsafe practices that breach hygiene procedures.",
    displayOrder: 2,
  },
  {
    taskId: 240,
    courseId: 8,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task:
      "Identify food hazards that may affect the health and safety of customers, colleagues and self",
    displayOrder: 3,
  },
  {
    taskId: 241,
    courseId: 8,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task:
      "Remove or minimise the hygiene hazard and report to appropriate person for follow up.",
    displayOrder: 4,
  },
  {
    taskId: 242,
    courseId: 8,
    taskGroup: "Task 2: Report any personal health issues.",
    task: "Report any personal health issues likely to cause a hygiene risk.",
    displayOrder: 5,
  },
  {
    taskId: 243,
    courseId: 8,
    taskGroup: "Task 2: Report any personal health issues.",
    task:
      "Report incidents of food contamination resulting from personal health issues.",
    displayOrder: 6,
  },
  {
    taskId: 244,
    courseId: 8,
    taskGroup: "Task 2: Report any personal health issues.",
    task:
      "Cease participation in food handling activities where a health issue may cause food contamination.",
    displayOrder: 7,
  },
  {
    taskId: 245,
    courseId: 8,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Maintain clean clothes, wear required personal protective clothing and only use organisation-approved bandages and dressings.",
    displayOrder: 8,
  },
  {
    taskId: 246,
    courseId: 8,
    taskGroup: "Task 3: Prevent food contamination",
    task: "Prevent food contamination from clothing and other items worn.",
    displayOrder: 9,
  },
  {
    taskId: 247,
    courseId: 8,
    taskGroup: "Task 3: Prevent food contamination",
    task: "Prevent unnecessary direct contact with ready to eat food.",
    displayOrder: 10,
  },
  {
    taskId: 248,
    courseId: 8,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Avoid unhygienic personal contact with food or food contact surfaces.",
    displayOrder: 11,
  },
  {
    taskId: 249,
    courseId: 8,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Avoid unhygienic cleaning practices that may cause food-borne illnesses.",
    displayOrder: 12,
  },
  {
    taskId: 250,
    courseId: 8,
    taskGroup: "Task 4: Prevent cross contamination by washing hands",
    task:
      "Wash hands at appropriate times and follow hand washing procedures consistently.",
    displayOrder: 13,
  },
  {
    taskId: 251,
    courseId: 8,
    taskGroup: "Task 4: Prevent cross contamination by washing hands",
    task: "Wash hands using appropriate facilities.",
    displayOrder: 14,
  },
  {
    taskId: 252,
    courseId: 8,
    taskGroup: "Task 5: Follow food safety program",
    task:
      "Access and use relevant information from organisational food safety program.",
    displayOrder: 15,
  },
  {
    taskId: 253,
    courseId: 8,
    taskGroup: "Task 5: Follow food safety program",
    task: "Follow policies and procedures in food safety program.",
    displayOrder: 16,
  },
  {
    taskId: 254,
    courseId: 8,
    taskGroup: "Task 5: Follow food safety program",
    task: "Control food hazards at critical control points.",
    displayOrder: 17,
  },
  {
    taskId: 255,
    courseId: 8,
    taskGroup: "Task 5: Follow food safety program",
    task:
      "Complete food safety monitoring processes and complete documents as required.",
    displayOrder: 18,
  },
  {
    taskId: 256,
    courseId: 8,
    taskGroup: "Task 5: Follow food safety program",
    task: "Identify and report non-conforming practices.",
    displayOrder: 19,
  },
  {
    taskId: 257,
    courseId: 8,
    taskGroup: "Task 5: Follow food safety program",
    task:
      "Take corrective actions within scope of job responsibility for incidents where food hazards are not controlled.",
    displayOrder: 20,
  },
  {
    taskId: 258,
    courseId: 8,
    taskGroup: "Task 6: Store food safely",
    task: "Select food storage conditions for specific food type.",
    displayOrder: 21,
  },
  {
    taskId: 259,
    courseId: 8,
    taskGroup: "Task 6: Store food safely",
    task:
      "Store food in environmental conditions that protect against contamination and maximise freshness, quality and appearance.",
    displayOrder: 22,
  },
  {
    taskId: 260,
    courseId: 8,
    taskGroup: "Task 6: Store food safely",
    task:
      "Store food at controlled temperatures and ensure that frozen items remain frozen during storage.",
    displayOrder: 23,
  },
  {
    taskId: 261,
    courseId: 8,
    taskGroup: "Task 7: Prepare food safely",
    task:
      "Use cooling and heating processes that support microbiological safety of the food.",
    displayOrder: 24,
  },
  {
    taskId: 262,
    courseId: 8,
    taskGroup: "Task 7: Prepare food safely",
    task:
      "Monitor food temperature during preparation using required temperature measuring device to achieve microbiological safety.",
    displayOrder: 25,
  },
  {
    taskId: 263,
    courseId: 8,
    taskGroup: "Task 7: Prepare food safely",
    task:
      "Ensure safety of food prepared, served and sold to customers under other conditions.",
    displayOrder: 26,
  },
  {
    taskId: 264,
    courseId: 8,
    taskGroup: "Task 8: Provide safe single use items",
    task:
      "Store, display and provide single use items so they are protected from damage and contamination.",
    displayOrder: 27,
  },
  {
    taskId: 265,
    courseId: 8,
    taskGroup: "Task 8: Provide safe single use items",
    task: "Follow instructions for items intended for single use.",
    displayOrder: 28,
  },
  {
    taskId: 266,
    courseId: 8,
    taskGroup: "Task 9: Maintain a clean environment",
    task: "Clean and sanitise equipment, surfaces and utensils.",
    displayOrder: 29,
  },
  {
    taskId: 267,
    courseId: 8,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Use appropriate containers and prevent accumulation of garbage and recycled matter.",
    displayOrder: 30,
  },
  {
    taskId: 268,
    courseId: 8,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Identify and report cleaning, sanitising and maintenance requirements.",
    displayOrder: 31,
  },
  {
    taskId: 269,
    courseId: 8,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Dispose of or report chipped, broken or cracked eating, drinking or food handling utensils.",
    displayOrder: 32,
  },
  {
    taskId: 270,
    courseId: 8,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Take measures within scope of responsibility to ensure food handling areas are free from animals and pests and report incidents of animal or pest infestation.",
    displayOrder: 33,
  },
  {
    taskId: 271,
    courseId: 8,
    taskGroup: "Task 10: Dispose of food safely",
    task:
      "Mark and keep separate from other foodstuffs any food identified for disposal until disposal is complete.",
    displayOrder: 34,
  },
  {
    taskId: 272,
    courseId: 8,
    taskGroup: "Task 10: Dispose of food safely",
    task: "Dispose of food promptly to avoid cross contamination.",
    displayOrder: 35,
  },
  {
    taskId: 273,
    courseId: 8,
    taskGroup: "General 1: Communication skills",
    task: "Verbal communication of hazards",
    displayOrder: 36,
  },
  {
    taskId: 274,
    courseId: 8,
    taskGroup: "General 1: Communication skills",
    task:
      "Communication skills to verbally report hygiene hazards and poor organisational practice",
    displayOrder: 37,
  },
  {
    taskId: 275,
    courseId: 8,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the organisational food safety program",
    displayOrder: 38,
  },
  {
    taskId: 276,
    courseId: 8,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the hygiene and food safety procedures",
    displayOrder: 39,
  },
  {
    taskId: 277,
    courseId: 8,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the Hazard Analysis and Critical Control Points (HACCP) practices",
    displayOrder: 40,
  },
  {
    taskId: 278,
    courseId: 8,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to read and interpret food safety program, including policies, procedures and flow charts that identify critical control points",
    displayOrder: 41,
  },
  {
    taskId: 279,
    courseId: 8,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend and complete documentation for monitoring food safety",
    displayOrder: 42,
  },
  {
    taskId: 280,
    courseId: 8,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Numeracy skills to calibrate and use a temperature probe and calculate timings",
    displayOrder: 43,
  },
  {
    taskId: 281,
    courseId: 8,
    taskGroup:
      "General 3: Problem solving skills – Identifying and reporting hazards",
    task: "Problem-solving skills to identify and report hygiene hazards",
    displayOrder: 44,
  },
  {
    taskId: 282,
    courseId: 8,
    taskGroup:
      "General 3: Problem solving skills – Identifying and reporting hazards",
    task:
      "Problem-solving skills to identify and respond to routine food safety issues",
    displayOrder: 45,
  },
  {
    taskId: 203,
    courseId: 1008,
    taskGroup:
      "Task 1: Maintain food safety while carrying out food handling activities",
    task: "Handle food according to the food safety program",
    displayOrder: 1,
  },
  {
    taskId: 204,
    courseId: 1008,
    taskGroup:
      "Task 1: Maintain food safety while carrying out food handling activities",
    task:
      "Identify and report processes and practices which are not consistent with the food safety program",
    displayOrder: 2,
  },
  {
    taskId: 205,
    courseId: 1008,
    taskGroup:
      "Task 1: Maintain food safety while carrying out food handling activities",
    task:
      "Take corrective action, within level of responsibility, and according to the food safety program",
    displayOrder: 3,
  },
  {
    taskId: 206,
    courseId: 1008,
    taskGroup:
      "Task 1: Maintain food safety while carrying out food handling activities",
    task:
      "Report any corrective action to supervisor, and according to legislative and organisation protocols",
    displayOrder: 4,
  },
  {
    taskId: 207,
    courseId: 1008,
    taskGroup: "Task 2: Comply with personal hygiene standards",
    task:
      "Ensure personal hygiene meets the requirements of the food safety program",
    displayOrder: 5,
  },
  {
    taskId: 208,
    courseId: 1008,
    taskGroup: "Task 2: Comply with personal hygiene standards",
    task:
      "Report health conditions and/or illness, as required by the food safety program",
    displayOrder: 6,
  },
  {
    taskId: 209,
    courseId: 1008,
    taskGroup: "Task 2: Comply with personal hygiene standards",
    task:
      "Wear appropriate clothing and footwear as required by the food safety program",
    displayOrder: 7,
  },
  {
    taskId: 210,
    courseId: 1008,
    taskGroup:
      "Task 3: Contribute to the cleanliness of food handling areas, according to the food safety program",
    task:
      "Maintain the workplace in a clean and tidy order to meet workplace requirements",
    displayOrder: 8,
  },
  {
    taskId: 211,
    courseId: 1008,
    taskGroup:
      "Task 3: Contribute to the cleanliness of food handling areas, according to the food safety program",
    task:
      "Comply with workplace measures to prevent pests entering food premises",
    displayOrder: 9,
  },
  {
    taskId: 212,
    courseId: 1008,
    taskGroup:
      "Task 3: Contribute to the cleanliness of food handling areas, according to the food safety program",
    task: "Identify and report indicators of pest presence",
    displayOrder: 10,
  },
  {
    taskId: 213,
    courseId: 1008,
    taskGroup: "Task 4: Dispose of food according to food safety program",
    task:
      "Keep food for disposal separate, according to food safety program requirements",
    displayOrder: 11,
  },
  {
    taskId: 214,
    courseId: 1008,
    taskGroup: "Task 4: Dispose of food according to food safety program",
    task: "Dispose of food according to the food safety program requirements",
    displayOrder: 12,
  },
  {
    taskId: 215,
    courseId: 1008,
    taskGroup:
      "Task 5: Monitor the implementation of the food safety program in the workplace",
    task:
      "Implement food safety handling processes and procedures that meet the requirements of the food safety program",
    displayOrder: 13,
  },
  {
    taskId: 216,
    courseId: 1008,
    taskGroup:
      "Task 5: Monitor the implementation of the food safety program in the workplace",
    task:
      "Implement processes and procedures to maintain the food handling area to meet the requirements of the food safety program",
    displayOrder: 14,
  },
  {
    taskId: 217,
    courseId: 1008,
    taskGroup:
      "Task 5: Monitor the implementation of the food safety program in the workplace",
    task:
      "Communicate food safety requirements and procedures to others in the workplace",
    displayOrder: 15,
  },
  {
    taskId: 218,
    courseId: 1008,
    taskGroup:
      "Task 5: Monitor the implementation of the food safety program in the workplace",
    task: "Support people in the food area to meet food safety requirements",
    displayOrder: 16,
  },
  {
    taskId: 219,
    courseId: 1008,
    taskGroup:
      "Task 6: Take corrective action when a food safety hazard is found not to be in control",
    task: "Implement procedures for responding to non-conformance",
    displayOrder: 17,
  },
  {
    taskId: 220,
    courseId: 1008,
    taskGroup:
      "Task 6: Take corrective action when a food safety hazard is found not to be in control",
    task: "Identify causes of non -compliance",
    displayOrder: 18,
  },
  {
    taskId: 221,
    courseId: 1008,
    taskGroup:
      "Task 6: Take corrective action when a food safety hazard is found not to be in control",
    task:
      "Implement measures to prevent the recurrence of non-conformance issues",
    displayOrder: 19,
  },
  {
    taskId: 222,
    courseId: 1008,
    taskGroup:
      "Task 6: Take corrective action when a food safety hazard is found not to be in control",
    task:
      "Develop or revise procedures to support the effective control of food safety hazards",
    displayOrder: 20,
  },
  {
    taskId: 223,
    courseId: 1008,
    taskGroup:
      "Task 6: Take corrective action when a food safety hazard is found not to be in control",
    task:
      "Communicate all additional and/or revised processes and procedures to food handlers",
    displayOrder: 21,
  },
  {
    taskId: 224,
    courseId: 1008,
    taskGroup: "Task 7: Maintain food safety in the workplace",
    task:
      "Identify processes and procedures that could result in a breach of food safety procedures and take preventive or corrective action",
    displayOrder: 22,
  },
  {
    taskId: 225,
    courseId: 1008,
    taskGroup: "Task 7: Maintain food safety in the workplace",
    task:
      "Support food handlers to complete food safety records to meet the food safety program and legal requirements",
    displayOrder: 23,
  },
  {
    taskId: 226,
    courseId: 1008,
    taskGroup: "Task 8: Implement the food safety program",
    task:
      "Monitor and control food safety hazards as required by the food safety program",
    displayOrder: 24,
  },
  {
    taskId: 227,
    courseId: 1008,
    taskGroup: "Task 8: Implement the food safety program",
    task:
      "Promptly report non-conformance where food safety control requirements are not met and take corrective action",
    displayOrder: 25,
  },
  {
    taskId: 228,
    courseId: 1008,
    taskGroup: "Task 8: Implement the food safety program",
    task:
      "Record food safety information to meet the requirements of the food safety program",
    displayOrder: 26,
  },
  {
    taskId: 229,
    courseId: 1008,
    taskGroup:
      "Task 9: Assist in the identification of breaches of food safety procedures",
    task:
      "Identify and report processes and conditions which could result in a breach of food safety procedures",
    displayOrder: 27,
  },
  {
    taskId: 230,
    courseId: 1008,
    taskGroup:
      "Task 9: Assist in the identification of breaches of food safety procedures",
    task:
      "Take corrective action to meet the requirements of the food safety program",
    displayOrder: 28,
  },
  {
    taskId: 231,
    courseId: 1008,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task:
      "Apply knowledge of aspects of the food safety program relevant to the work role",
    displayOrder: 29,
  },
  {
    taskId: 232,
    courseId: 1008,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task: "Comply with personal hygiene requirements",
    displayOrder: 30,
  },
  {
    taskId: 233,
    courseId: 1008,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task: "Comply with workplace cleanliness requirements",
    displayOrder: 31,
  },
  {
    taskId: 234,
    courseId: 1008,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task: "Demonstrate appropriate disposal of food",
    displayOrder: 32,
  },
  {
    taskId: 235,
    courseId: 1008,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task: "Apply knowledge of all aspects of the food safety program",
    displayOrder: 33,
  },
  {
    taskId: 236,
    courseId: 1008,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task: "Meet reporting requirements",
    displayOrder: 34,
  },
  {
    taskId: 237,
    courseId: 1008,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task: "Undertake hazard identification and management",
    displayOrder: 35,
  },
  {
    taskId: 313,
    courseId: 1009,
    taskGroup:
      "Task 1: Maintain food safety while carrying out food handling activities",
    task: "Handle food according to the food safety program",
    displayOrder: 1,
  },
  {
    taskId: 314,
    courseId: 1009,
    taskGroup:
      "Task 1: Maintain food safety while carrying out food handling activities",
    task:
      "Identify and report processes and practices which are not consistent with the food safety program",
    displayOrder: 2,
  },
  {
    taskId: 315,
    courseId: 1009,
    taskGroup:
      "Task 1: Maintain food safety while carrying out food handling activities",
    task:
      "Take corrective action, within level of responsibility, and according to the food safety program",
    displayOrder: 3,
  },
  {
    taskId: 316,
    courseId: 1009,
    taskGroup:
      "Task 1: Maintain food safety while carrying out food handling activities",
    task:
      "Report any corrective action to supervisor, and according to legislative and organisation protocols",
    displayOrder: 4,
  },
  {
    taskId: 317,
    courseId: 1009,
    taskGroup: "Task 2: Comply with personal hygiene standards",
    task:
      "Ensure personal hygiene meets the requirements of the food safety program",
    displayOrder: 5,
  },
  {
    taskId: 318,
    courseId: 1009,
    taskGroup: "Task 2: Comply with personal hygiene standards",
    task:
      "Report health conditions and/or illness, as required by the food safety program",
    displayOrder: 6,
  },
  {
    taskId: 319,
    courseId: 1009,
    taskGroup: "Task 2: Comply with personal hygiene standards",
    task:
      "Wear appropriate clothing and footwear as required by the food safety program",
    displayOrder: 7,
  },
  {
    taskId: 320,
    courseId: 1009,
    taskGroup:
      "Task 3: Contribute to the cleanliness of food handling areas, according to the food safety program",
    task:
      "Maintain the workplace in a clean and tidy order to meet workplace requirements",
    displayOrder: 8,
  },
  {
    taskId: 321,
    courseId: 1009,
    taskGroup:
      "Task 3: Contribute to the cleanliness of food handling areas, according to the food safety program",
    task:
      "Comply with workplace measures to prevent pests entering food premises",
    displayOrder: 9,
  },
  {
    taskId: 322,
    courseId: 1009,
    taskGroup:
      "Task 3: Contribute to the cleanliness of food handling areas, according to the food safety program",
    task: "Identify and report indicators of pest presence",
    displayOrder: 10,
  },
  {
    taskId: 323,
    courseId: 1009,
    taskGroup: "Task 4: Dispose of food according to food safety program",
    task:
      "Keep food for disposal separate, according to food safety program requirements",
    displayOrder: 11,
  },
  {
    taskId: 324,
    courseId: 1009,
    taskGroup: "Task 4: Dispose of food according to food safety program",
    task: "Dispose of food according to the food safety program requirements",
    displayOrder: 12,
  },
  {
    taskId: 325,
    courseId: 1009,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task:
      "Apply knowledge of aspects of the food safety program relevant to the work role",
    displayOrder: 13,
  },
  {
    taskId: 326,
    courseId: 1009,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task: "Comply with personal hygiene requirements",
    displayOrder: 14,
  },
  {
    taskId: 327,
    courseId: 1009,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task: "Comply with workplace cleanliness requirements",
    displayOrder: 15,
  },
  {
    taskId: 328,
    courseId: 1009,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task: "Demonstrate appropriate disposal of food",
    displayOrder: 16,
  },
];
