/**
 * Course Service
 */

import CryptoJS from "crypto-js";
import Config from "../config";
import {
  courses,
  nswCertificateReprint,
  thirdPartyFormTasks,
} from "../constants/course-content";
import HttpClient from "../coreLib/http/httpClient";

const CourseService = () => {
  let httpClient = HttpClient();

  const getCourseContents = async (courseToken) => {
    return await httpClient
      .get(`/v1/usercourse/${courseToken}/contents`)
      .then((resp) => {
        var data = decryptPayload(
          resp.data,
          Config.crypto_key,
          Config.crypto_vector
        );
        return data;
      });
  };

  const getCourses = () => {
    return courses;
  };

  const getFlattenedCourses = () => {
    return courses.map((course) => {
      return {
        value: course.courseId,
        label: course.courseTitle,
      };
    });
  };

  const getCourse = (courseId) => {
    // == instead of === because route can pass as an int instead of a string
    // eslint-disable-next-line eqeqeq
    if (courseId === 7) return nswCertificateReprint;

    const course = courses.filter((course) => course.courseId == courseId);

    if (course.length > 0) return course[0];
    else return null;
  };

  const getCourseSections = async (sectionId, courseContentToken) => {
    const courseContentDetails = await getCourseContents(courseContentToken);

    const sections = courseContentDetails.sections;

    if (sections.length === 0) {
      return null;
    }

    const section = sections.find((x) => x.sectionId === sectionId);

    return section;
  };

  const getThirdPartyFormTasks = (courseId) => {
    let tasks = thirdPartyFormTasks.filter((x) => {
      return x.courseId === courseId;
    });

    tasks.sort(function(a, b) {
      return a - b;
    });

    return tasks;
  };

  const decryptPayload = (encryptedPayload, secretKey, vector) => {
    try {
      const ciphertext = CryptoJS.enc.Base64.parse(encryptedPayload);
      const key = CryptoJS.enc.Utf8.parse(secretKey);
      const initializationVector = CryptoJS.enc.Utf8.parse(vector);

      const decrypted = CryptoJS.AES.decrypt({ ciphertext }, key, {
        iv: initializationVector,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      const decryptedPayload = decrypted.toString(CryptoJS.enc.Utf8);
      const json = JSON.parse(decryptedPayload);

      return json;
    } catch (error) {
      console.error("Error decrypting payload:", error.message);
    }
  };

  return {
    getCourse,
    getCourses,
    getCourseSections,
    getThirdPartyFormTasks,
    getFlattenedCourses,
    getCourseContents,
  };
};

export default CourseService;
