import { connect } from "react-redux";
import Header from "./Header";
import { selectLoginData } from "../../redux/system/login-reducer";

function mapStateToProps(state) {
  const loginData = selectLoginData(state);

  return {
    loginData: loginData
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch: dispatch,
  history: ownProps.history
});

const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

export default HeaderContainer;
