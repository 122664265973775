import React, { Component } from "react";
import { Row, Col, Button, Form, FormGroup, Label } from "reactstrap";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import InputField from "../../components/Common/Fields/Input-Field";
import SelectField from "../../components/Common/Fields/Select-Field";
import ContentSection from "../../components/Content-Section";
import * as Constants from "../../constants";
import * as validators from "../../components/Common/Fields/Validators/Field-Validators";
import "./update-profile.scss";
import BockUiFx from "../../components/Common/Block-Ui-Fx/Block-Ui-Fx";

// Combination of validations
const emailValidator = (fieldName, value) => {
  if (validators.required(fieldName, value))
    return validators.required(fieldName, value);
  if (validators.email(value)) return validators.email(value);

  return false;
};

class UpdateProfile extends Component {
  componentDidMount() {
    const { getUserInfo } = this.props;
    getUserInfo();
  }

  render() {
    const {
      title,
      firstName,
      lastName,
      email,
      state
    } = this.props.updateProfile.fields;
    const { onSubmit, onInputChange, onFieldError, validate } = this.props;
    const { isSubmitting } = this.props.updateProfile;
    return (
      <React.Fragment>
        <PageTitle title="Update Profile" />
        <ContentSection bgColor="grey-shadow mb-4" padding={4}>
          <h1>Update Profile</h1>
          <article>
            Updating your details will not change the details on your existing
            certificates, or any courses that you have already enrolled in. If
            you wish to change your existing certificate or a course which you
            are enrolled in, please <a>contact us</a>.
          </article>
          <main className="my-3">
            <BockUiFx blocking={isSubmitting} lowerZIndex={true}>
              <Form className="ttg-form update-profile">
                <FormGroup>
                  <Row>
                    <Col lg="3" md="4" sm="6" xs="12">
                      <SelectField
                        name="title"
                        validate={val => validators.required("Title", val)}
                        value={title}
                        onFieldError={onFieldError}
                        onChange={onInputChange}
                      >
                        <option value="-1">- Select Your Title -</option>
                        {Constants.titles.map((item, i) => (
                          <option key={i} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </SelectField>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Col lg="3" md="4" sm="6" xs="12">
                      <InputField
                        name="firstName"
                        placeholder="First Name"
                        value={firstName}
                        validate={val => validators.required("First Name", val)}
                        onFieldError={onFieldError}
                        onChange={onInputChange}
                      />
                    </Col>
                    <Col className="last-name" lg="3" md="4" sm="6" xs="12">
                      <InputField
                        name="lastName"
                        placeholder="Last Name"
                        value={lastName}
                        validate={val => validators.required("Last Name", val)}
                        onFieldError={onFieldError}
                        onChange={onInputChange}
                      />
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Col lg="6" md="8" xs="12">
                      <InputField
                        name="email"
                        placeholder="Email Address"
                        value={email}
                        validate={val => emailValidator("Email Address", val)}
                        onFieldError={onFieldError}
                        onChange={onInputChange}
                      />
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Col lg="6" md="8" xs="12">
                      <SelectField
                        name="state"
                        value={state}
                        validate={val => validators.required("State", val)}
                        onFieldError={onFieldError}
                        onChange={onInputChange}
                      >
                        <option value="-1">- select state -</option>
                        {Constants.australianStates.map((item, i) => (
                          <option key={i} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </SelectField>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Button
                    className="ttg-btn"
                    onClick={onSubmit}
                    disabled={validate()}
                  >
                    Submit
                  </Button>
                </FormGroup>
              </Form>
            </BockUiFx>
          </main>
        </ContentSection>
      </React.Fragment>
    );
  }
}

export default UpdateProfile;
