import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Form, FormGroup } from "reactstrap";
import InputField from "../Common/Fields/Input-Field";
//import ButtonFx from "../Common/Button-Fx/Button-Fx";
import "./login-bar.scss";
import ButtonFx from "./../Common/Button-Fx/Button-Fx";
import withSizes from "react-sizes";

class LoginBar extends Component {
  componentDidMount() {
    this.props.loadData();
  }

  render() {
    const { loggedIn, isLoading } = this.props.loginData;
    const { pathname } = this.props.history.location;
    const { firstName } = this.props.loginData.loggedInUser;
    const { username, password } = this.props.loginData.fields;
    const { onLogin, onLogout, onInputChange, isMobile } = this.props;

    return (
      <div className="login-bar">
        <div className="container">
          <div className="row">
            <div className="col-3 d-none d-lg-block">
              <span className="login-contact">
                <i className="fas fa-phone" /> &nbsp;
                <a
                  href="tel:+61400757645"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  0400 757 645
                </a>
                &nbsp; &#124; &nbsp;
                <i className="fas fa-envelope" /> &nbsp;
                <a
                  href="mailto:contact@traintogain.com.au"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Email
                </a>
              </span>
            </div>
            {!loggedIn && !pathname.includes("Login") && (
              <React.Fragment>
                <div className="col-lg-9 col-12 d-none d-sm-block text-right">
                  <Form className="mt-2 header-login" onSubmit={onLogin}>
                    <FormGroup style={{ marginBottom: "6px" }}>
                      <InputField
                        name="username"
                        placeholder="Email"
                        type="email"
                        //onKeyPress={onLogin} // No need to add KeyPress for every field input (redundant and overkill)
                        value={username}
                        onChange={onInputChange}
                      />
                      <InputField
                        name="password"
                        placeholder="Password"
                        type="password"
                        //onKeyPress={onLogin}   // No need to add KeyPress for every field input (redundant and overkill)
                        value={password}
                        onChange={onInputChange}
                      />
                      <ButtonFx
                        type="submit"
                        className="ttg-btn"
                        onClick={onLogin}
                        isLoading={isLoading}
                      >
                        Log In
                      </ButtonFx>
                      <Link to="/Forgot-Password/" className="forgot-pass">
                        Forgot your Password?
                      </Link>
                    </FormGroup>
                  </Form>
                </div>
              </React.Fragment>
            )}
            {loggedIn && (
              <React.Fragment>
                <div
                  style={{ marginBottom: "6px" }}
                  className="col-lg-9 col-12 d-none d-sm-block text-right logged-in-actions"
                >
                  <div className="welcome-name">Welcome {firstName}</div>
                  {/* <Link
                    to="/Update-Profile"
                    className="ttg-btn btn btn-secondary"
                  >
                    Update Profile
                  </Link>
                  <Link
                    to="/Change-Password"
                    className="ttg-btn btn btn-secondary"
                  >
                    Change Password
                  </Link> */}
                  <Button className="ttg-btn" onClick={onLogout}>
                    Logout
                  </Button>
                </div>
                {/* <div className="col d-block d-sm-none text-center">
                  <Button className="ttg-btn" onClick={onLogout}>
                    Logout
                  </Button>
                </div> */}
              </React.Fragment>
            )}
            {isMobile && !loggedIn && (
              <React.Fragment>
                <div
                  style={{ marginBottom: "6px" }}
                  className="col text-center logged-in-actions"
                >
                  <Link to="/Login/" className="ttg-btn btn btn-secondary">
                    Login
                  </Link>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 576,
});

export default withSizes(mapSizesToProps)(LoginBar);
