import React, { Component } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../components/Common/Page-Title/Page-Title";
import { Button, Form, FormGroup, Label, Row, Col } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import InputField from "../../../components/Common/Fields/Input-Field";
import MaskedInputField from "../../../components/Common/Fields/Masked-Input-Field";
import SelectField from "../../../components/Common/Fields/Select-Field";
import RadioButtonList from "../../../components/Common/Fields/Radio-Button-List";
import ContentSection from "../../../components/Content-Section";
import * as Step3Constants from "./step-3-constants";
import "../../../assets/styles/enrolment-forms.scss";
import * as validators from "../../../components/Common/Fields/Validators/Field-Validators";
import BlockUiFx from "../../../components/Common/Block-Ui-Fx/Block-Ui-Fx";
import ButtonFx from "../../../components/Common/Button-Fx/Button-Fx";
import formHandler from "../../../components/Common/Fields/form-handler";
import KeyboardEventHandler from "react-keyboard-event-handler";

const validateSelect = (fieldName, value) => {
  if (validators.required(fieldName, value) || value === "-1") {
    return `${fieldName} is required.`;
  }
  return false;
};

const emailValidator = (fieldName, value) => {
  if (validators.required(fieldName, value))
    return validators.required(fieldName, value);
  if (validators.email(value)) return validators.email(value);

  return false;
};

const observerNameValidator = (
  fieldName,
  value,
  observerFirstName,
  observerLastName,
  studentFirstName,
  studentLastName
) => {
  if (validators.required(fieldName, value))
    return validators.required(fieldName, value);

  if (
    observerFirstName !== "" &&
    observerLastName !== "" &&
    studentFirstName !== "" &&
    studentLastName !== "" &&
    observerFirstName.toLowerCase() === studentFirstName.toLowerCase() &&
    observerLastName.toLowerCase() === studentLastName.toLowerCase()
  ) {
    return "Observer Name - this cannot be your own name - your observer may be contacted for verification";
  }

  return false;
};

const phoneValidator = (fieldName, value) => {
  if (validators.required(fieldName, value))
    return validators.required(fieldName, value);

  if (validators.phone(value)) return validators.phone(value);

  return false;
};

const getPhoneMask = (value) => {
  if (
    value.startsWith("02") ||
    value.startsWith("03") ||
    value.startsWith("07") ||
    value.startsWith("08")
  )
    return "99 9999 9999";

  return "9999 999 999";
};

class CourseEnrolmentStep3 extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { step3OnInit, match } = this.props;
    step3OnInit(match.params.courseId);
  }

  render() {
    const form = formHandler();
    const {
      onInputChange,
      onFieldError,
      formValid,
      isProcessing,
      onSubmitEnrolment,
    } = this.props;

    const { showExperience, showWorkPlaceName } = this.props.fieldVisibility;

    const {
      whereExperience,
      workplaceName,
      workplaceSuburb,
      workplacePhone,
      relationshipToObserver,
      observerFirstName,
      observerLastName,
      observerEmail,
      currentEmploymentStatus,
    } = this.props.fields;
    const { courseTitle, courseCode } = this.props.courseDetails;

    const { firstName, lastName } = this.props.loginData.loggedInUser;

    return (
      <React.Fragment>
        <PageTitle title="Course Enrolment" />
        <Form className="ttg-form">
          <BlockUiFx blocking={isProcessing} lowerZIndex={true}>
            <ContentSection bgColor="grey-shadow" padding={4}>
              <h1>Course Enrolment</h1>
              <h5>
                {courseTitle} ({courseCode})
              </h5>
              <h4 className="mt-4">Ok nearly there!</h4>
              <p>
                Because we offer online training, we need to prove your
                practical skills in Food Safety and we do this by an online
                third-party checklist form that is emailed to your nominated
                observer. There are some things we can&rsquo;t see you doing
                through your computer – such as washing your hands.
              </p>
              <p>
                We often have students who aren&rsquo;t working in the food
                industry at the moment - sometimes because they are starting a
                new food business or a new career in the industry, so this
                observation can also be done in the home kitchen (simulated
                environment). The person you choose to fill in the report does
                not need to be a Food Safety Supervisor themselves. As the
                observation does not have to be in work situation, an adult
                family member can be nominated as your observer as long as they
                can witness you perform the required tasks.
              </p>
              <p>
                The observer needs to have an email address and is willing to
                fill in and return the third-party form online. Don&rsquo;t
                forget though, both you and your observer are declaring that the
                information supplied is true and original.
              </p>
              <p>Your observer can be:</p>
              <ul>
                <li>Past or Present Supervisor/Employer/Manager/Co Worker</li>
                <li>Past or Present Volunteer Supervisor/Manager/Co Worker</li>
                <li>Adult Family Member or Friend</li>
              </ul>
              <h5>
                The person you choose to fill in the report does not need to
                have a Food Safety Certificate.
              </h5>
            </ContentSection>
            <ContentSection padding={3}>
              <h5>Your Experience</h5>
              <p>
                <strong>
                  We need to see that you have experience in dealing with food
                  safely. This can be now or sometime in the past.
                </strong>
              </p>

              <FormGroup>
                <Label for="whereExperience">Experience gained?</Label>
                <div>
                  <RadioButtonList
                    name="whereExperience"
                    showBorderError={false}
                    value={whereExperience}
                    ref={form.add}
                    validate={(val) =>
                      val === "-1" ||
                      val === "" ||
                      val === undefined ||
                      val === null
                        ? "Work experience is required."
                        : false
                    }
                    onFieldError={onFieldError}
                    items={Step3Constants.whereExperience}
                    onChange={onInputChange}
                  />
                </div>
              </FormGroup>
              {showExperience && (
                <React.Fragment>
                  {showWorkPlaceName && (
                    <FormGroup>
                      <Label for="workplaceName">Workplace Name</Label>
                      <InputField
                        name="workplaceName"
                        ref={form.add}
                        placeholder="Workplace Name"
                        value={workplaceName}
                        onChange={onInputChange}
                        validate={(val) =>
                          validators.required("Workplace name", val)
                        }
                        onFieldError={onFieldError}
                        className="mxw-400"
                      />
                    </FormGroup>
                  )}

                  <FormGroup>
                    <Label for="workplaceSuburb">
                      {showWorkPlaceName ? "Workplace Suburb" : "Suburb"}
                    </Label>
                    <InputField
                      name="workplaceSuburb"
                      ref={form.add}
                      placeholder={
                        showWorkPlaceName ? "Workplace Suburb" : "Suburb"
                      }
                      value={workplaceSuburb}
                      onChange={onInputChange}
                      validate={(val) =>
                        validators.required(
                          showWorkPlaceName ? "Workplace Suburb" : "Suburb",
                          val
                        )
                      }
                      onFieldError={onFieldError}
                      className="mxw-400"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="workplacePhone">
                      {showWorkPlaceName
                        ? "Workplace Phone Number"
                        : "Phone Number"}
                    </Label>
                    <MaskedInputField
                      name="workplacePhone"
                      placeholder={
                        showWorkPlaceName
                          ? "Workplace Phone Number"
                          : "Phone Number"
                      }
                      mask={getPhoneMask(workplacePhone)}
                      value={workplacePhone}
                      ref={form.add}
                      onChange={onInputChange}
                      onFieldError={onFieldError}
                      validate={(val) =>
                        phoneValidator(
                          showWorkPlaceName
                            ? "Workplace Phone Number"
                            : "Phone Number",
                          val
                        )
                      }
                      className="mxw-400"
                    />
                  </FormGroup>
                </React.Fragment>
              )}

              <FormGroup>
                <Label for="currentEmploymentStatus">
                  Of the following categories, which BEST describes your current
                  employment status?
                </Label>
                <SelectField
                  name="currentEmploymentStatus"
                  value={currentEmploymentStatus}
                  ref={form.add}
                  onChange={onInputChange}
                  validate={(val) =>
                    validateSelect("Current employment status", val)
                  }
                  onFieldError={onFieldError}
                  className="mxw-500"
                >
                  <option value="-1">- Select -</option>
                  {Step3Constants.currentEmploymentStatus.map((item, i) => (
                    <option key={i} value={item.value}>
                      {item.label ? item.label : item.value}
                    </option>
                  ))}
                </SelectField>
              </FormGroup>
            </ContentSection>
            <ContentSection bgColor="grey" padding={3}>
              <h5>Your Observer Details</h5>
              <p>
                <Link to="/FAQs/" className="ttg-btn btn">
                  Read our FAQs to know more about who can be your Observer
                </Link>
              </p>
              <FormGroup className="mb-0">
                <Label>
                  Observer Name (This cannot be your own name - your observer
                  may be contacted for verification)
                </Label>
                <Row form>
                  <Col md={3}>
                    <FormGroup>
                      <InputField
                        name="observerFirstName"
                        ref={form.add}
                        placeholder="First Name"
                        value={observerFirstName}
                        onChange={onInputChange}
                        validate={(val) =>
                          observerNameValidator(
                            "Observer first name",
                            val,
                            val,
                            observerLastName,
                            firstName,
                            lastName
                          )
                        }
                        onFieldError={onFieldError}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <InputField
                        name="observerLastName"
                        placeholder="Last Name"
                        ref={form.add}
                        value={observerLastName}
                        onChange={onInputChange}
                        validate={(val) =>
                          observerNameValidator(
                            "Observer last name",
                            val,
                            observerFirstName,
                            val,
                            firstName,
                            lastName
                          )
                        }
                        onFieldError={onFieldError}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="observerEmail">Observer Email</Label>
                <div className="mb-2">
                  <InputField
                    name="observerEmail"
                    placeholder="Email Address"
                    value={observerEmail}
                    ref={form.add}
                    type="email"
                    onChange={onInputChange}
                    validate={(val) => emailValidator("Email address", val)}
                    onFieldError={onFieldError}
                    className="mxw-400"
                  />
                </div>
                <p>
                  <strong>
                    Please enter your observers email address so your third
                    party form can be emailed to them directly. If you do not
                    have it now, enter your own email address so you can forward
                    it on to your observer at a later date.
                  </strong>
                </p>
              </FormGroup>
              <FormGroup>
                <Label for="relationshipToObserver">
                  Relationship to Observer
                </Label>
                <SelectField
                  name="relationshipToObserver"
                  value={relationshipToObserver}
                  ref={form.add}
                  onChange={onInputChange}
                  validate={(val) =>
                    validateSelect("Relationship to observer", val)
                  }
                  onFieldError={onFieldError}
                  className="mxw-400"
                >
                  <option value="-1">- Select -</option>
                  {Step3Constants.relationshipToObserver.map((item, i) => (
                    <option key={i} value={item.value}>
                      {item.label ? item.label : item.value}
                    </option>
                  ))}
                </SelectField>
              </FormGroup>
              <FormGroup>
                <h4 className="my-4">
                  WE&rsquo;RE DONE! Thanks for your patience{" "}
                  <i className="far fa-grin-alt" />
                </h4>

                <ButtonFx
                  type="submit"
                  className="ttg-btn"
                  isLoading={isProcessing}
                  onClick={() =>
                    form.validate({
                      validFn: () => {
                        onSubmitEnrolment();
                      },
                      invalidFn: () => console.log(form.getFieldErrors()),
                    })
                  }
                >
                  Continue
                </ButtonFx>
              </FormGroup>
            </ContentSection>
          </BlockUiFx>
        </Form>
        <KeyboardEventHandler
          handleKeys={["enter"]}
          onKeyEvent={(key, e) =>
            form.validate({
              validFn: () => onSubmitEnrolment(),
            })
          }
        />
      </React.Fragment>
    );
  }
}

export default CourseEnrolmentStep3;
