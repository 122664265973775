import React from "react";
import { Row, Col, Button, Form, FormGroup } from "reactstrap";
import InputField from "../Common/Fields/Input-Field";
import SelectField from "../Common/Fields/Select-Field";
import ButtonFx from "../Common/Button-Fx/Button-Fx";
import * as Constants from "../../constants";
import {
  required,
  email,
  strongPassword,
} from "../Common/Fields/Validators/Field-Validators";
import "./register-box.scss";
import KeyboardEventHandler from "react-keyboard-event-handler";
import formHandler from "./../Common/Fields/form-handler";

// Complex validators
const validateEmailField = (val) => {
  if (required("Email", val)) return required("Email", val);
  if (email(val)) return email(val);

  return false;
};

const validatePasswordField = (val) => {
  if (required("Password", val)) return required("Password", val);
  if (strongPassword(val)) return strongPassword(val);

  return false;
};

const validateConfirmPasswordField = (password, confirmPwd) => {
  if (required("Confirm Password", confirmPwd))
    return required("Confirm Password", confirmPwd);
  if (password !== confirmPwd) return "Your passwords do not match.";

  return false;
};

const validateConfirmEmailField = (email, confirmEmail) => {
  if (required("Confirm Email", confirmEmail))
    return required("Confirm Email", confirmEmail);
  if (email !== confirmEmail) return "Your email addresses do not match.";

  return false;
};

//
// Presentation Component
//
const RegisterBox = (props) => {
  const {
    title,
    firstName,
    lastName,
    email,
    confirmEmail,
    password,
    confirmPassword,
    state,
  } = props.registerBox.fields;
  const {
    courseId,
    registerType,
    onRegister,
    onInputChange,
    onFieldError,
    validate,
    isLoading,
    noLastName,
    toggleNoLastName,
  } = props;

  const form = formHandler();

  return (
    <Form className="ttg-form register-box">
      <Row form>
        <Col md={6}>
          <FormGroup>
            <SelectField
              name="title"
              value={title}
              onChange={onInputChange}
              validate={(val) =>
                val !== "-1" && val !== "" ? false : "Select a valid title"
              }
              onFieldError={onFieldError}
              ref={form.add}
            >
              <option value="-1">- Select Your Title -</option>
              {Constants.titles.map((item, i) => (
                <option key={i} value={item.value}>
                  {item.label}
                </option>
              ))}
            </SelectField>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <InputField
              name="firstName"
              placeholder="First Name"
              value={firstName}
              validate={(val) => required("First name", val)}
              onFieldError={onFieldError}
              onChange={onInputChange}
              ref={form.add}
            />
          </FormGroup>
        </Col>
        {!noLastName && (
          <Col md={6}>
            <FormGroup>
              <InputField
                name="lastName"
                placeholder="Last Name"
                value={lastName}
                validate={(val) => required("Last name", val)}
                onFieldError={onFieldError}
                onChange={onInputChange}
                ref={form.add}
              />
              <div
                className="mt-2 font-weight-bold text-center"
                style={{ fontSize: "1.1em" }}
              >
                <a className="no-last-name" onClick={toggleNoLastName}>
                  NO SURNAME, CLICK HERE
                </a>
              </div>
            </FormGroup>
          </Col>
        )}
      </Row>
      <FormGroup>
        <InputField
          name="email"
          placeholder="Email"
          type="email"
          value={email}
          validate={(val) => validateEmailField(val)}
          onFieldError={onFieldError}
          onChange={onInputChange}
          ref={form.add}
        />
      </FormGroup>
      <FormGroup>
        <InputField
          name="confirmEmail"
          placeholder="Confirm Email"
          type="email"
          value={confirmEmail}
          validate={(val) => validateConfirmEmailField(email, val)}
          onFieldError={onFieldError}
          onChange={onInputChange}
          ref={form.add}
        />
      </FormGroup>
      <FormGroup>
        <InputField
          name="password"
          placeholder="Password"
          type="password"
          value={password}
          validate={(val) => validatePasswordField(val)}
          onFieldError={onFieldError}
          onChange={onInputChange}
          ref={form.add}
        />
      </FormGroup>
      <FormGroup>
        <InputField
          name="confirmPassword"
          placeholder="Confirm Password"
          type="password"
          value={confirmPassword}
          validate={(val) => validateConfirmPasswordField(password, val)}
          onFieldError={onFieldError}
          onChange={onInputChange}
          ref={form.add}
        />
      </FormGroup>
      <FormGroup>
        <SelectField
          name="state"
          value={state}
          onChange={onInputChange}
          validate={(val) =>
            val !== "-1" && val !== "" ? false : "Select a valid state"
          }
          onFieldError={onFieldError}
          ref={form.add}
        >
          <option value="-1">- Select Your State -</option>
          {Constants.australianStates.map((item, i) => (
            <option key={i} value={item.value}>
              {item.label}
            </option>
          ))}
        </SelectField>
      </FormGroup>
      <FormGroup>
        <ButtonFx
          type="submit"
          className="ttg-btn"
          isLoading={isLoading}
          onClick={() =>
            form.validate({
              validFn: () => onRegister(courseId, registerType),
              invalidFn: () => console.log(form.getFieldErrors()),
            })
          }
        >
          Continue
        </ButtonFx>
      </FormGroup>
      <KeyboardEventHandler
        handleKeys={["enter"]}
        onKeyEvent={(key, e) =>
          form.validate({ validFn: () => onRegister(courseId, registerType) })
        }
      />
    </Form>
  );
};

export default RegisterBox;
