import React, { Component } from "react";
import "./nsw-reprint.scss";
import NswCertDataTable from "./Data-Table/Data-Table";
import PageTitle from "../../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../../components/Content-Section/index";

export default class NswReprintsViewContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { onLoadData } = this.props;
    onLoadData();
    this.props.clearDataTableSearchbox();
  }

  render() {
    const { data } = this.props.dataTable;
    const { actionButtonHandler } = this.props;
    return (
      <React.Fragment>
        <PageTitle title="Admin - View NSW Reprints Page | Train to Gain" />
        <ContentSection padding={3} isFullWidth={true} bgColor="grey-shadow">
          <h1>View NSW Reprints</h1>
        </ContentSection>
        <ContentSection isFullWidth={true} padding={3}>
          <NswCertDataTable
            data={data}
            onActionButtonHandler={actionButtonHandler}
            {...this.props}
          />
        </ContentSection>
      </React.Fragment>
    );
  }
}
