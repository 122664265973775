import React, { Component } from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import "react-table/react-table.css";
import "./searchable-datatable.css";
import SearchBox from "./search-box/search-box";
import SearchLogic from "./search-box/search-logic";
import ActionButtonsFactory from "./action-button/action-buttons-factory"; //Todo: This will be remove in the future sprint
import ActionButtonsFactory2 from "./action-button/action-buttons-factory2";
import withSizes from "react-sizes";
import isNil from "lodash/isNil";

class SolvableDataTable extends Component {
  state = {
    data: [],
    columns: [],
    searchText: ""
  };

  static propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    defaultPageSize: PropTypes.number,
    stateSettings: PropTypes.object,
    keepSettings: PropTypes.bool,
    onError: PropTypes.func,
    onSettingsChanged: PropTypes.func
  };

  static defaultProps = {
    data: [],
    predefinedButtons: null,
    defaultPageSize: 20,
    stateSettings: {},
    keepSettings: false,
    onError: () => {} // noop
  };

  constructor() {
    super();
    this.searchLogic = SearchLogic();
    this.actionButtonsFactory = ActionButtonsFactory(this.actionButtonHandler);
    this.actionButtonsFactory2 = ActionButtonsFactory2(
      this.actionButtonHandler
    );
  }

  componentDidMount() {
    this.load();
  }

  static getDerivedStateFromProps(props, state) {
    return {
      data: props.data
    };
  }

  load() {
    const {
      columns,
      data,
      predefinedButtons,
      actionButtons,
      isMobile
    } = this.props;
    let cols = columns.map(item => item);

    // Setup action buttons if provided
    // Todo: This will be remove in the future sprint
    if (this.props.predefinedButtons) {
      const buttonComponent = this.actionButtonsFactory.getPreDefinedActionButtons(
        predefinedButtons
      );
      cols = this.setupActionButtons(
        cols,
        buttonComponent,
        predefinedButtons.containerWidth
      );
    } else if (this.props.actionButtons) {
      // New version
      const buttonComponent = this.actionButtonsFactory2.getPreDefinedActionButtons(
        actionButtons
      );
      cols = this.setupActionButtons(
        cols,
        buttonComponent,
        actionButtons.containerWidth
      );
    }

    this.setState({ columns: cols, data });
  }

  setupActionButtons = (cols, component, containerWidth) => {
    return cols.concat({
      Header: "Actions",
      accessor: "actions",
      sortable: false,
      Cell: component,
      width: containerWidth ? containerWidth : 100
    });
  };

  actionButtonHandler = evt => {
    (this.props.onActionButtonClick || (() => {}))(evt);
  };

  searchInputChange = evt => {
    if (this.props.keepSettings) {
      const settings = this.getLatestSettings();
      this.props.onSettingsChanged({
        ...settings,
        searchText: evt.target.value
      });
    } else {
      this.setState({ searchText: evt.target.value });
    }
  };

  pageSizeChange = (pageSize, pageIndex) => {
    const settings = this.getLatestSettings();
    this.props.onSettingsChanged({
      ...settings,
      pageSize
    });
  };

  pageChange = pageIndex => {
    const settings = this.getLatestSettings();
    this.props.onSettingsChanged({
      ...settings,
      pageIndex
    });
  };

  getLatestSettings = () => {
    return Object.keys(this.props.stateSettings).length > 0
      ? { ...this.props.stateSettings }
      : this.getDefaultStateSettings();
  };

  getDefaultStateSettings = () => {
    return {
      searchText: "",
      pageIndex: 0,
      pageSize: ""
    };
  };

  setPageSize = length => {
    // Observing the keepSettings feature
    let defaultPageSize = this.props.keepSettings
      ? this.props.stateSettings.pageSize
        ? this.props.stateSettings.pageSize
        : this.props.defaultPageSize
      : this.props.defaultPageSize;

    // For Mobile Only
    // Adjusts Pagesize automatically based on data length
    // If data length < 10, pageSize = data length, else, pageSize = this.props.defaultPageSize
    let size = this.props.isMobile
      ? length >= 10
        ? defaultPageSize //this.props.defaultPageSize
        : length
      : defaultPageSize; //this.props.defaultPageSize;
    return size;
  };

  render() {
    let data = [...this.state.data];
    let searchText;
    let pageIndex;
    let pageSize; // required by keepSettings

    if (this.props.keepSettings) {
      // Search
      if (this.props.stateSettings.searchText)
        data = this.searchLogic.search(
          this.props.stateSettings.searchText,
          data,
          this.state.columns
        );

      // Load state settings
      // pageIndex = this.props.stateSettings.pageIndex
      //   ? this.props.stateSettings.pageIndex
      //   : 0;
      searchText = !isNil(this.props.stateSettings.searchText)
        ? this.props.stateSettings.searchText
        : "";
    } else if (this.state.searchText) {
      data = this.searchLogic.search(
        this.state.searchText,
        data,
        this.state.columns
      );
      searchText = this.state.searchText;
    }

    return (
      <React.Fragment>
        <SearchBox
          searchText={searchText}
          onSearchInputChange={this.searchInputChange}
        />
        {this.props.keepSettings ? (
          <ReactTable
            data={data}
            columns={this.state.columns}
            defaultPageSize={this.props.defaultPageSize}
            pageSize={this.setPageSize(data.length)}
            className="-striped -highlight"
            onPageSizeChange={this.pageSizeChange.bind(this)}
            onPageChange={this.pageChange}
          />
        ) : (
          <ReactTable
            data={data}
            columns={this.state.columns}
            defaultPageSize={this.props.defaultPageSize}
            pageSize={this.setPageSize(data.length)}
            className="-striped -highlight"
          />
        )}
      </React.Fragment>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 765
});

export default withSizes(mapSizesToProps)(SolvableDataTable);
