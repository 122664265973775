import React from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import "./navBar.scss";
import TtgLogo from "../../assets/images/Train-to-Gain-Logo.jpg";
import NtrLogo from "../../assets/images/ntr-logo.jpg";
import ToggleDisplay from "react-toggle-display";
import withSizes from "react-sizes";

class View extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      navLinks,
      toggle,
      isOpen,
      onLogout,
      isMobile,
      isLoggedIn,
      history,
    } = this.props;
    return (
      <div className="nav-bg">
        <div className="container">
          <Navbar className="navbar-header" expand="md">
            <NavbarBrand onClick={() => history.push("/")}>
              <img src={NtrLogo} alt="Nationally Recognised Training" />
              <img
                src={TtgLogo}
                alt="Train to Gain Pty Ltd t/a Food Safety Education"
              />
            </NavbarBrand>

            <NavbarToggler onClick={toggle}>
              <i className="fas fa-bars" />
            </NavbarToggler>

            <Collapse isOpen={isOpen} navbar>
              <Nav className="ml-auto" navbar>
                {navLinks.map((navLink, i) => (
                  <NavItem key={i}>
                    <ToggleDisplay if={isMobile}>
                      <NavLink
                        onClick={() => toggle()}
                        tag={Link}
                        to={navLink.to}
                      >
                        {navLink.title}
                      </NavLink>
                    </ToggleDisplay>

                    <ToggleDisplay if={!isMobile}>
                      <NavLink tag={Link} to={navLink.to}>
                        {navLink.title}
                      </NavLink>
                    </ToggleDisplay>
                  </NavItem>
                ))}
                <ToggleDisplay if={isLoggedIn}>
                  <section className="d-block d-md-none">
                    <NavItem>
                      <NavLink
                        onClick={() => {
                          toggle();
                          onLogout();
                        }}
                        tag={Link}
                        to={"#"}
                      >
                        Logout
                      </NavLink>
                    </NavItem>
                  </section>
                </ToggleDisplay>
                <ToggleDisplay if={!isLoggedIn}>
                  <section className="d-block d-md-none">
                    <NavItem>
                      <NavLink
                        onClick={() => {
                          toggle();
                        }}
                        tag={Link}
                        to={"/Login/"}
                      >
                        Log In
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        onClick={() => {
                          toggle();
                        }}
                        tag={Link}
                        to={"/Forgot-Password/"}
                      >
                        Forgot your Password?
                      </NavLink>
                    </NavItem>
                  </section>
                </ToggleDisplay>
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </div>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 800,
});

export default withRouter(withSizes(mapSizesToProps)(View));
