import React from "react";
import "./tick-list.scss";

const TickList = ({ items, tickColor, headingStyle, className }) => {
  return (
    <ul
      className={
        (className ? className + " " : "") +
        (tickColor ? "tick-list tick-list-" + tickColor : "tick-list") +
        (headingStyle ? " tick-list-heading" : "")
      }
    >
      {items.map((item, i) => (
        <li key={i}>
          <i className="fas fa-check mr-2 tick" />
          {item}
        </li>
      ))}
    </ul>
  );
};

export default TickList;
