/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { PropTypes } from "prop-types";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardFooter,
} from "reactstrap";
import { useDropzone } from "react-dropzone";
import "./file-uploader.scss";
import styled from "styled-components";
import IconFactory from "./service/icon-factory";
import { fileListToBase64, toKilobyte, getColor } from "./service/helper";
import ImagePreviewFx from "./../image-preview-fx/image-preview-fx";
import ToolTipFx from "./../tooltip/tool-tip-fx";
import BlockUiFx from "../Block-Ui-Fx/Block-Ui-Fx";
import { saveAs } from "file-saver";

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const FileUploader = (props) => {
  const [files, setFiles] = useState([]);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [isDropping, toggleDrop] = useState(false);
  const [initialised, initialise] = useState(false);
  const {
    text,
    dropText,
    minSize,
    maxFileSize,
    maxFiles,
    initialFiles,
    mode,
    fileTypes,
    isReadonly,
    cloudStoragePath,
    onChange,
  } = props;

  const onDrop = useCallback(async (acceptedFiles) => {
    // Reject files exceeding the maxFiles limit

    toggleDrop(true);
    let finalAcceptedFiles = [];
    let filesCount = files.length + acceptedFiles.length;
    if (filesCount > maxFiles) {
      let itemsUpperLimit = maxFiles - files.length;
      finalAcceptedFiles = acceptedFiles.slice(0, itemsUpperLimit);
    } else {
      finalAcceptedFiles = acceptedFiles;
    }

    let additionalFiles = finalAcceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    // Convert to base64 each file
    let arrayOfBase64Files = await fileListToBase64(additionalFiles);

    arrayOfBase64Files = arrayOfBase64Files.map((x) => ({
      ...x,
      size: x.size / 1024 < 0 ? x.size : x.size / 1024,
    }));

    files.push(...arrayOfBase64Files);
    setFiles(files);

    onChange([...files]);
    toggleDrop(false);
  });

  const getFileTypesAccepted = () => {
    if (mode === "Image" && fileTypes.length === 0) return "image/*";
    if (mode === "File" && fileTypes.length === 0) {
      return [
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
        ".pdf",
        ".jpg",
        ".jpeg",
        ".png",
      ].join(",");
    } else {
      return fileTypes.join(",");
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    rejectedFiles,
  } = useDropzone({
    accept: getFileTypesAccepted(),
    minSize: minSize,
    maxSize: maxFileSize,
    //multiple: true,
    onDrop,
    disabled: isReadonly,
  });

  const openPreview = (file) => {
    setPreviewUrl(file.preview);
    setPreviewOpen(true);
  };

  const removeFile = useCallback((file) => () => {
    if (isReadonly) return;
    URL.revokeObjectURL(file.preview);
    let filesUpdated = files.filter((o) => o !== file);
    setFiles(filesUpdated);

    onChange([...filesUpdated]);
  });

  const closePreview = (e) => {
    setPreviewOpen(false);
  };

  const downloadFile = (file) => {
    saveAs(file.preview, file.name);
  };

  // Refactor: Separate file
  const imageListing = files.map((file, index) => (
    <div key={index} className="fileDetails ml-3">
      <div className="thumb" key={file.name}>
        <div className="thumbInner">
          <img
            id={`remove${index}`}
            src={file.preview}
            className="img sol-clickable thumb-img"
            onClick={() => openPreview(file)}
            alt=""
          />
          <ToolTipFx target={`remove${index}`}>Open preview</ToolTipFx>
        </div>
      </div>
      <div className="ml-2">
        <div className="text-muted">
          <small className="font-bold">Filename</small>
        </div>
        <div className="text-muted sol-truncate-xs">
          <small>{file.name}</small>
        </div>
        <div className="text-muted">
          <small className="font-bold">Size</small>
        </div>
        <div className="text-muted">
          <small>
            {file.size > 1024 ? toKilobyte(file.size) : Math.round(file.size)}{" "}
            Kb
          </small>
        </div>
        <div className="mt-2">
          {!isReadonly && (
            <Button type="button" onClick={removeFile(file)}>
              Remove
            </Button>
          )}
        </div>
      </div>
    </div>
  ));

  const limitFileName = (fileName) => {
    if (fileName.length > 18) {
      let name = fileName.substring(0, fileName.lastIndexOf("."));
      let extension = fileName.substring(fileName.lastIndexOf("."));
      return `${name.substring(0, 16)}...${extension}`;
    }
    return fileName;
  };

  const isAnImage = (ext) => {
    switch (ext) {
      case "jpg":
      case "jpeg":
      case "png":
      case "JPG":
      case "JPEG":
      case "PNG":
        return true;
      default:
        return false;
    }
  };

  // Refactor: Separate file
  const fileListing = files.map((file, index) => {
    const ext = file.name.substring(file.name.lastIndexOf(".") + 1);
    const icon = IconFactory().getIcon(ext);
    let limitedName = limitFileName(file.name);
    const fileIsAnImage = isAnImage(ext);

    return (
      <Col
        key={index}
        className="my-2"
        xs={props.xs}
        sm={props.sm}
        md={props.md}
        lg={props.lg}
        xl={props.xl}
      >
        <Card>
          <CardBody>
            <div className="remove-button">
              <i
                id={`remove${index}`}
                className="fas fa-times sol-clickable text-muted"
                onClick={removeFile(file)}
              />
              <ToolTipFx target={`remove${index}`}>Remove</ToolTipFx>
            </div>
            <Row className="w-100 justify-content-center text-center">
              <Col className="p-2" xs="4">
                {fileIsAnImage && (
                  <>
                    <img
                      id={`preview${index}`}
                      src={file.preview}
                      className="img sol-clickable thumb-img"
                      onClick={() => openPreview(file)}
                      alt=""
                      style={{ cursor: "pointer" }}
                    />
                    <ToolTipFx target={`preview${index}`}>
                      Open preview
                    </ToolTipFx>
                  </>
                )}
                {!fileIsAnImage && (
                  <>
                    <i
                      id={`download${index}`}
                      className={`${icon} fa-7x sol-clickable`}
                      onClick={
                        file.type === "init"
                          ? () => downloadFile(file)
                          : () => {}
                      }
                      style={file.type === "init" ? { cursor: "pointer" } : {}}
                    />
                    {file.type === "init" && (
                      <ToolTipFx target={`download${index}`}>
                        Download
                      </ToolTipFx>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col>
                <span className="text-muted small">{limitedName}</span>
                <span className="font-weight-bold">
                  {" "}
                  ({Math.round(file.size)} kb)
                </span>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    );
  });

  const DragMessage = ({ isDragActive, isDragReject, rejected }) => {
    return (
      <>
        <BlockUiFx blocking={isDropping} lowerZIndex={true}>
          {!isDragActive && !rejected && (
            <p
              className="mt-3 text-center drag-msg"
              style={{ userSelect: "none", pointerEvents: "none" }}
            >
              {text} Maximum of {maxFiles} file{maxFiles > 1 ? "s" : ""}.
              {files.length > 0 ? (
                <>
                  <br />
                  <span className="small text-primary">
                    <strong>{files.length}</strong> file(s) attached.
                  </span>
                </>
              ) : null}
            </p>
          )}
          {isDragActive && !rejected && (
            <p
              className="mt-3 drag-msg"
              style={{ userSelect: "none", pointerEvents: "none" }}
            >
              {dropText}
            </p>
          )}
          {rejected && (
            <p
              className="mt-3 drag-msg"
              style={{ userSelect: "none", pointerEvents: "none" }}
            >
              File type is not supported.
            </p>
          )}
        </BlockUiFx>
      </>
    );
  };

  const FileTooLarge = () => {
    const isFileTooLarge =
      rejectedFiles.length > 0 && rejectedFiles[0].size > maxFileSize;
    return (
      <React.Fragment>
        {isFileTooLarge && <p className="text-danger">File is too large.</p>}
      </React.Fragment>
    );
  };

  const reloadInitialFiles = () => {
    if (!initialFiles || initialFiles.length <= 0) return;

    setFiles(
      initialFiles.map((o) =>
        Object.assign(
          {},
          {
            type: "init",
            name: o.name,
            size: o.size,
            data: o.data || null,
            preview: getPreviewData(o),
          }
        )
      )
    );
  };

  const getPreviewData = (file) => {
    if (file.data.includes("data:")) return file.data;
    return `${cloudStoragePath}/${file.name}`;
  };

  const isImageMode = () => mode === "Image";

  // Component did mount
  useEffect(() => {
    if (initialFiles.length > 0 && !initialised) {
      initialise(true);
      reloadInitialFiles();
    }
  }, [initialFiles]);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <Container className="fileUploader" fluid={true}>
      <Row>
        <Col className="pr-0 pl-0">
          <ImagePreviewFx
            imageLargeUrl={previewUrl}
            isOpen={isPreviewOpen}
            closePreview={closePreview}
            showRotate={true}
            alternateText={"Image Preview"}
          />
          <StyledContainer
            {...getRootProps({
              isDragActive,
              isDragAccept,
              isDragReject,
            })}
            validForReject={
              rejectedFiles.length && rejectedFiles.length > 0 ? true : false
            }
            className="mb-2"
          >
            <input {...getInputProps()} />
            <DragMessage
              isDragActive={isDragActive}
              isDragReject={isDragReject}
              rejected={
                rejectedFiles.length && rejectedFiles.length > 0 ? true : false
              }
            />
            <FileTooLarge />
          </StyledContainer>
          {isImageMode() ? (
            <aside className="thumbsContainer">{imageListing}</aside>
          ) : (
            <Row className="thumbsContainer">{fileListing}</Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default FileUploader;

FileUploader.propTypes = {
  minSize: PropTypes.number,
  maxFileSize: PropTypes.number,
  maxFiles: PropTypes.number,
  mode: PropTypes.string, //Note: Mode can be Image or File, each mode has different layout and behavior
  fileTypes: PropTypes.array,
  initialFiles: PropTypes.array,
  isReadonly: PropTypes.bool,
  cloudStoragePath: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  xs: PropTypes.any,
  sm: PropTypes.any,
  md: PropTypes.any,
  lg: PropTypes.any,
  xl: PropTypes.any,
};

FileUploader.defaultProps = {
  minSize: 0,
  maxFileSize: 100000, // 100kb default
  maxFiles: 5,
  mode: "File",
  fileTypes: [],
  initialFiles: [],
  cloudStoragePath: "",
  isReadonly: false,
  xs: 6,
  sm: 6,
  md: 6,
  lg: 6,
  xl: 6,
};
