import React, { Component } from "react";
import TTGDataTable from "../../../components/solvable-datatable/solvable-datatable";
import ContentSection from "../../../components/Content-Section";
import BlockUiFx from "../../../components/Common/Block-Ui-Fx/Block-Ui-Fx";

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.columns = this.setupDataTable();
    this.state = {
      settings: {}
    };
    this.actionButtonHandler = this.actionButtonHandler.bind(this);
  }

  componentDidMount() {
    this.props.onLoad();
  }

  setupDataTable = () => {
    return [
      {
        Header: "Invoice Number",
        accessor: "invoiceNumber",
        searchable: true
      },
      {
        Header: "Invoice Date",
        accessor: "invoiceDate",
        searchable: true
      },
      {
        Header: "Vouchers",
        accessor: "vouchers",
        searchable: true
      },
      {
        Header: "Total",
        accessor: "total",
        searchable: true
      }
    ];
  };

  onSettingsChanged = settings => {
    this.setState({ settings });
  };

  actionButtonHandler = evt => {
    this.props.downloadTaxInvoice(evt.data.invoiceGuid);
  };

  render() {
    const { isProcessing } = this.props;
    return (
      <React.Fragment>
        <BlockUiFx blocking={isProcessing} lowerZIndex={true}>
          <ContentSection isFullWidth={true} padding={3}>
            <TTGDataTable
              columns={this.columns}
              data={this.props.data}
              stateSettings={this.state.settings}
              defaultPageSize={50}
              actionButtons={{
                type: "customButton",
                containerWidth: 100,
                custom: [
                  {
                    target: "button1",
                    icon: "fas fa-download",
                    tooltip: "Download Tax Invoice"
                  }
                ]
              }}
              onActionButtonClick={this.actionButtonHandler}
              onSettingsChanged={this.onSettingsChanged}
            />
          </ContentSection>
        </BlockUiFx>
      </React.Fragment>
    );
  }
}

export default DataTable;
