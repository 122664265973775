import { connect } from "react-redux";
import ThirdPartyForm from "./Third-Party-Form";
import { selectThirdPartyForm } from "./redux/reducer";
import * as actionCreators from "./redux/action-creators";
import { addNotification } from "./../../redux/system/system-action-creators";
import { selectLoginData } from "./../../redux/system/login-reducer";

function mapStateToProps(state) {
  const thirdPartyForm = selectThirdPartyForm(state);
  const loginData = selectLoginData(state);

  // Redux fields should not be exposed in the view
  // create a proper view model props abstraction here to map with.
  // Redux fields might change, state Selector and view model props will minimize certain issue.
  return {
    courseId: thirdPartyForm.courseId,
    courseName: thirdPartyForm.courseName,
    certificateCodes: thirdPartyForm.certificateCodes,
    unitOfCompetencies: thirdPartyForm.unitOfCompetencies,
    studentFirstName: thirdPartyForm.studentFirstName,
    studentLastName: thirdPartyForm.studentLastName,
    tasks: thirdPartyForm.tasks,
    accessToken: thirdPartyForm.accessToken,

    loginData: loginData,
    fields: thirdPartyForm.fields,
    fieldErrors: thirdPartyForm.fieldErrors,

    isModalOpen: thirdPartyForm.showConfirmModal,
    isLoading: thirdPartyForm.isLoading,
    isLoadingObserverSection: thirdPartyForm.isLoadingObserverSection,
    isLoadingObserverButton: thirdPartyForm.isLoadingObserverButton,
    isLoadingForSaveProgress: thirdPartyForm.isLoadingForSaveProgress,
    isLoadingForSubmit: thirdPartyForm.isLoadingForSubmit,

    showMessageDialog: thirdPartyForm.showMessageDialog,
    showMessageDialogMessage: thirdPartyForm.showMessageDialogMessage,

    isSampleForm: thirdPartyForm.isSampleForm,
    showForm: thirdPartyForm.showForm,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.formInputChange(name, value, error));
  },
  onFieldError: (error) => dispatch(addNotification(error, "Error")),
  dispatch: dispatch,
  history: ownProps.history,
  match: ownProps.match,
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onLoadForm: (courseId) => {
    dispatchProps.dispatch(
      actionCreators.onLoadForm(courseId, dispatchProps.history)
    );
  },
  onLoadSampleForm: (courseId) => {
    dispatchProps.dispatch(
      actionCreators.onLoadSampleForm(courseId, dispatchProps.history)
    );
  },
  onEmail: (e) => {
    dispatchProps.dispatch(actionCreators.showConfirmEmailObserver());
  },
  onToggle: (evt) => {
    if (evt.type === "ok") {
      if (validate(stateProps)) return;
      dispatchProps.dispatch(
        actionCreators.sendEmailAndUpdateThirdPartyForm(
          stateProps.accessToken,
          stateProps.fields,
          dispatchProps.history
        )
      );
    } else {
      dispatchProps.dispatch(actionCreators.hideConfirmEmailObserver());
    }
  },
  onUpdateDetails: () => {
    if (validate(stateProps)) return;

    dispatchProps.dispatch(
      actionCreators.updateThirdPartyFormObserver(
        stateProps.accessToken,
        stateProps.fields,
        dispatchProps.history
      )
    );
  },
  saveProgress: () => {
    if (validate(stateProps)) return;

    dispatchProps.dispatch(
      actionCreators.saveProgressThirdPartyForm(
        stateProps.accessToken,
        stateProps.fields,
        stateProps.courseId,
        dispatchProps.history
      )
    );
  },
  submitForm: () => {
    //if (validate(stateProps)) return;

    dispatchProps.dispatch(
      actionCreators.saveProgressThirdPartyForm(
        stateProps.accessToken,
        stateProps.fields,
        stateProps.courseId,
        dispatchProps.history,
        true
      )
    );
  },
  onMessageToggle: () => {
    dispatchProps.dispatch(actionCreators.hideMessageDialog());
  },
  validate: () => {
    return validate(stateProps);
  },
});

// Obsolete
const validate = (stateProps) => {
  const fields = stateProps.fields;
  const fieldErrors = stateProps.fieldErrors;
  const errMessages = Object.keys(fieldErrors).filter((k) => fieldErrors[k]);
  const fieldsToCheck = [
    "observerFirstName",
    "observerLastName",
    "observerEmail",
  ];

  for (var fld in fieldsToCheck) {
    if (!fields[fieldsToCheck[fld]]) return true;
  }
  if (errMessages.length > 0) return true;

  return false;
};

const ThirdPartyFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(ThirdPartyForm);

export default ThirdPartyFormContainer;
