import isNil from "lodash/isNil";
import React, { Component } from "react";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { Link } from "react-router-dom";
import ToggleDisplay from "react-toggle-display";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import "../../assets/styles/enrolment-forms.scss";
import BlockUiFx from "../../components/Common/Block-Ui-Fx/Block-Ui-Fx";
import ButtonFx from "../../components/Common/Button-Fx/Button-Fx";
import formHandler from "../../components/Common/Fields/form-handler";
import InputField from "../../components/Common/Fields/Input-Field";
import PaymentInputs from "../../components/Common/Fields/Payment-Inputs";
import * as validators from "../../components/Common/Fields/Validators/Field-Validators";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../components/Content-Section";
import LoqateAddressField from "./../../components/Common/Loqate-Single-Address-Field/index";

// Combination of validations
const emailValidator = (fieldName, value) => {
  if (validators.required(fieldName, value))
    return validators.required(fieldName, value);
  if (validators.email(value)) return validators.email(value);

  return false;
};

class NSWReprint extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.props.init();
    this.props.setEmail();
  }

  onAddressSelect = (address) => {
    this.props.updateAutocompleteAddress(address);
  };

  onSuggestNoResults(userInput) {
    if (!userInput || userInput === "") return;
  }

  onChange(value) {
    if (!value) {
      this.props.clearAddress();
    }
  }

  render() {
    const {
      onInputChange,
      onCreditCardChange,
      onFieldError,
      onSubmit,
      isSubmitting,
      isSubmitted,
      showConfirmPopup,
      toggleShowConfirmPopup,
      clearAddress,
      toggleManualAddressEntry,
    } = this.props;
    const { fullAddress } = this.props.addressEntry;
    const {
      email,
      cardName,
      cardNumber,
      cardExpiryDate,
      cardCvc,
    } = this.props.fields;
    const { courseCost } = this.props.nswCertificate;
    const form = formHandler();

    return (
      <React.Fragment>
        <PageTitle title="The NSW Food Safety Supervisor Certificate Reprint" />
        <Form className="ttg-form">
          <ContentSection bgColor="grey-shadow" padding={4}>
            <h1>
              The{" "}
              <span style={{ color: "#1e2b5f" }}>
                NSW Food Safety Supervisor Certificate
              </span>{" "}
              Reprint
            </h1>
            {/* <h2 className="mb-2">
              <small>
                This option is for processing a duplicate of your current NSW
                Certificate.
              </small>
            </h2>
            <h2>
              <small>
                This option is not available for expired certificates. If your
                certificate has expired, you will need to{" "}
                <Link to="/course-enrolment/6/step-1">
                  Enrol in a new NSW course
                </Link>
                .
              </small>
            </h2> */}
            <h3>${courseCost}.00</h3>
          </ContentSection>
          <ToggleDisplay if={isSubmitted}>
            <ContentSection padding={3}>
              <h2>
                Thank you for ordering your{" "}
                <span style={{ color: "#1e2b5f" }}>
                  NSW Food Safety Supervisor Certificate
                </span>{" "}
                Reprint.
              </h2>
              <h2>You will receive this in the post within 7 business days.</h2>
            </ContentSection>
          </ToggleDisplay>
          <ToggleDisplay if={!isSubmitted}>
            <BlockUiFx blocking={isSubmitting} lowerZIndex={true}>
              <ContentSection padding={3}>
                <h5>Your Details</h5>

                <FormGroup>
                  <LoqateAddressField
                    name={"postalAddress"}
                    placeholder="Enter your postal address"
                    form={form}
                    initialValue={fullAddress}
                    onInputChange={onInputChange}
                    onAddressSelect={this.onAddressSelect}
                    fields={this.props.fields}
                    onFieldError={onFieldError}
                    validate={(val) => validators.required("Address", val)}
                    ref={form.add}
                    clearAddress={clearAddress}
                    toggleManualEntry={toggleManualAddressEntry}
                  />
                </FormGroup>

                <FormGroup>
                  <div>
                    <Label for="email">Email Invoice To</Label>
                    <InputField
                      name="email"
                      placeholder="Email Address"
                      type="email"
                      value={email}
                      ref={form.add}
                      onChange={onInputChange}
                      className="mxw-400"
                      onFieldError={onFieldError}
                      validate={(val) => emailValidator("Email Address", val)}
                    />
                  </div>
                </FormGroup>
              </ContentSection>

              <ContentSection bgColor="grey" padding={3}>
                <h5>Payment Information</h5>

                <React.Fragment>
                  <FormGroup>
                    <div>
                      <Label>Credit Card Details</Label>
                    </div>

                    <PaymentInputs
                      onInputChange={onInputChange}
                      onFieldError={onFieldError}
                      cardName={cardName}
                      form={form}
                      cardNumber={cardNumber}
                      cardExpiryDate={cardExpiryDate}
                      cardCvc={cardCvc}
                      onChange={onCreditCardChange}
                    />
                  </FormGroup>

                  <FormGroup>
                    <ButtonFx
                      type="submit"
                      className="ttg-btn"
                      isLoading={isSubmitting}
                      onClick={() => {
                        if (isNil(cardNumber) || !cardNumber) {
                          onInputChange({
                            name: "cardNumber",
                            value: "",
                            error: "Card number is required",
                          });
                          onFieldError("Card number is required.");
                        }

                        if (isNil(cardExpiryDate) || !cardExpiryDate) {
                          onInputChange({
                            name: "cardExpiryDate",
                            value: "",
                            error: "Card expiry date is required.",
                          });
                          onFieldError("Card expiry date is required.");
                        }

                        if (isNil(cardCvc) || !cardCvc) {
                          onInputChange({
                            name: "cardCvc",
                            value: "",
                            error: "Card CVC is required.",
                          });
                          onFieldError("Card CVC is required.");
                        }

                        form.validate({
                          validFn: () => {
                            onSubmit();
                          },
                          invalidFn: () => console.log(form.getFieldErrors()),
                        });
                      }}
                    >
                      Pay Now
                    </ButtonFx>
                  </FormGroup>

                  <Modal
                    isOpen={showConfirmPopup}
                    toggle={toggleShowConfirmPopup}
                  >
                    <ModalHeader toggle={toggleShowConfirmPopup}>
                      Order a Duplicate Copy NSW FSS Certificate
                    </ModalHeader>
                    <ModalBody>
                      Before you get started, we just wanted to confirm you
                      understand that:
                      <ul className="mt-2">
                        <li>
                          This option is for processing a duplicate of your
                          current NSW FSS Certificate.
                        </li>
                        <li>
                          This option is not available for expired certificates.
                          If your certificate has expired, you will need to{" "}
                          <Link to="/course-enrolment/6/step-1">
                            Enrol in a new NSW Food Safety Supervisor course
                          </Link>
                          .
                        </li>
                      </ul>
                      {/* Before you get started, we just wanted to{" "}
                      <strong>
                        confirm your name is {firstName}
                        {firstName === lastName ? "" : ` ${lastName}`}
                      </strong>
                      .
                      <br />
                      <br />
                      If this isn&rsquo;t your name, please click the 'No,
                      That&rsquo;s Not Me' button. */}
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={toggleShowConfirmPopup}>
                        Yes, I Understand
                      </Button>
                    </ModalFooter>
                  </Modal>
                </React.Fragment>
              </ContentSection>
            </BlockUiFx>
          </ToggleDisplay>

          <KeyboardEventHandler
            handleKeys={["enter"]}
            onKeyEvent={(key, e) =>
              form.validate({
                validFn: () => onSubmit(),
              })
            }
          />
        </Form>
      </React.Fragment>
    );
  }
}

export default NSWReprint;
