import React, { Component } from "react";
import { Row, Col, Form, FormGroup } from "reactstrap";
import PageTitle from "../../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../../components/Content-Section";
import SelectField from "../../../components/Common/Fields/Select-Field";
import KeyboardEventHandler from "react-keyboard-event-handler";
import formHandler from "../../../components/Common/Fields/form-handler";
import * as Constants from "../../../constants";
import ButtonFx from "../../../components/Common/Button-Fx/Button-Fx";

class AvetmissReporting extends Component {
  componentDidMount() {
    const { onLoadForm } = this.props;

    onLoadForm();
  }

  render() {
    const form = formHandler();

    const { onSubmit, onInputChange, onFieldError, isLoading } = this.props;

    const {
      years,
      periods,
      collectionStartDate,
      collectionEndDate,
      version
    } = this.props.avetmissReporting;

    const { state, year, period } = this.props.avetmissReporting.fields;

    return (
      <React.Fragment>
        <PageTitle title="Avetmiss Validation Service | Reporting" />
        <ContentSection bgColor="grey-shadow">
          <h1>Avetmiss Validation Service | Reporting</h1>
        </ContentSection>
        <ContentSection bgColor="grey-shadow">
          <Form className="ttg-form">
            <Row form>
              <Col md={4}>
                <FormGroup>
                  <SelectField
                    name="state"
                    value={state}
                    onChange={onInputChange}
                    validate={val =>
                      val !== "-1" && val !== ""
                        ? false
                        : "Select a valid state"
                    }
                    onFieldError={onFieldError}
                    ref={form.add}
                  >
                    <option value="All">All States</option>
                    {Constants.australianStates.map((item, i) => (
                      <option key={i} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </SelectField>
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col md={3}>
                <FormGroup>
                  <SelectField
                    name="year"
                    value={year}
                    onChange={onInputChange}
                    validate={val =>
                      val !== "-1" && val !== "" ? false : "Select a Year"
                    }
                    onFieldError={onFieldError}
                    ref={form.add}
                  >
                    <option value="-1">- Select a Year -</option>
                    {years.map((item, i) => (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    ))}
                  </SelectField>
                </FormGroup>
              </Col>
            </Row>

            {year && (
              <Row form>
                <Col md={3}>
                  <FormGroup>
                    <SelectField
                      name="period"
                      value={period}
                      onChange={onInputChange}
                      validate={val =>
                        val !== "-1" && val !== "" ? false : "Select a Period"
                      }
                      onFieldError={onFieldError}
                      ref={form.add}
                    >
                      <option value="-1">- Select a Period -</option>
                      {periods.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </SelectField>
                  </FormGroup>
                </Col>
              </Row>
            )}

            {year && period && (
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <div>
                      <h5 className="mb-0 pb-0">Avetmiss Version</h5>
                      <p>{version}</p>
                      <h5 className="mb-0 pb-0">Collection Period</h5>
                      <p>
                        {collectionStartDate} - {collectionEndDate}
                      </p>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            )}
            <FormGroup>
              <ButtonFx
                type="submit"
                className="ttg-btn"
                isLoading={isLoading}
                onClick={() => {
                  form.validate({
                    validFn: () =>
                      onSubmit(
                        collectionStartDate,
                        collectionEndDate,
                        state,
                        version,
                        year,
                        period
                      ),
                    invalidFn: () => console.log(form.getFieldErrors())
                  });
                }}
              >
                Generate NAT Files
              </ButtonFx>
            </FormGroup>
            <KeyboardEventHandler
              handleKeys={["enter"]}
              onKeyEvent={(key, e) =>
                form.validate({
                  validFn: () =>
                    onSubmit(
                      collectionStartDate,
                      collectionEndDate,
                      state,
                      version,
                      year,
                      period
                    )
                })
              }
            />
          </Form>
        </ContentSection>
      </React.Fragment>
    );
  }
}

export default AvetmissReporting;
