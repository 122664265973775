import React, { Component } from "react";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../components/Content-Section";
import RegisterBoxContainer from "../../components/Register-Box/Register-Box.Container";
import CourseService from "../../services/course-service";
import ArrowLinkButton from "../../components/Arrow-Link-Button/Arrow-Link-Button";

class CertificateRegister extends Component {
  render() {
    const { match } = this.props;
    const courseService = CourseService();
    const course = courseService.getCourse(match.params.courseId);

    return (
      <React.Fragment>
        <PageTitle title={`${course.courseTitle} | Enrol Now`} />
        <ContentSection bgColor="grey-shadow">
          <h1>Let&rsquo;s Get Started!</h1>
          <h3 className="mb-3">{course.courseTitle}</h3>
          <h5 className="mb-3">${course.courseCost}.00</h5>
          {course.validStates && <h4 className="mb-3">{course.validStates}</h4>}

          {/* <div className="mb-4">
            <ArrowLinkButton
              to="/Corporate-Vouchers-for-Course-Participation/"
              title="Corporate clients purchase Food Safety Vouchers here"
              showAsBlue={true}
            ></ArrowLinkButton>
          </div>
          <div className="mb-4">
            <ArrowLinkButton
              to="#"
              title="Students continue below"
              showDownArrow={true}
            ></ArrowLinkButton>
          </div>

          <h2>Students Start Your Training Here</h2> */}
          <div className="row">
            <div className="col-12 col-md-6">
              <RegisterBoxContainer
                courseId={course.courseId}
                registerType={"student"}
              />
            </div>
          </div>
        </ContentSection>
      </React.Fragment>
    );
  }
}

export default CertificateRegister;
