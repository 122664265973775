import { connect } from "react-redux";
import Login from "./Login";
import { selectLoginData } from "../../redux/system/login-reducer";
import { withRouter } from "react-router-dom";

function mapStateToProps(state) {
  const logInData = selectLoginData(state);
  return {
    logInData: logInData
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch: dispatch,
  history: ownProps.history
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps
});

const LoginContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeFormProps
  )(Login)
);

export default LoginContainer;
