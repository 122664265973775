import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addNotification } from "../../redux/system/system-action-creators";
import Auth from "../../coreLib/auth/auth";
import UserIdentity from "../../coreLib/auth/userIdentity";
import LoginBar from "./Login-Bar";
import Config from "./../../config";
import { selectLoginData } from "../../redux/system/login-reducer";
import {
  onLogin,
  onLogout,
  onLoginInputChange,
  loadUserIdentityState,
} from "../../redux/system/system-action-creators";

function mapStateToProps(state) {
  const loginData = selectLoginData(state);

  return {
    loginData: loginData,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadData: async () => {
    // Note: We will reload user identity from local storage to redux state if user is authenticated.
    if (!Auth.isAuthenticated()) return;

    let userIdentity = UserIdentity();

    const user = {
      firstName: userIdentity.getFirstName(),
      lastName: userIdentity.getLastName(),
      id: userIdentity.getUserId(),
      email: userIdentity.getEmail(),
      hasCourses: userIdentity.hasCourses(),
      userType: userIdentity.userType(),
    };

    await dispatch(loadUserIdentityState(user));
  },
  onInputChange: ({ name, value, error }) => {
    dispatch(onLoginInputChange(name, value, error));
  },
  dispatch: dispatch,
  history: ownProps.history,
});

const mergeFormProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  onLogin: (e) => {
    const { username, password } = stateProps.loginData.fields;

    if (!username && !password) {
      dispatchProps.dispatch(
        addNotification(
          "Please enter your email address and password.",
          "warning"
        )
      );
      return;
    }

    if (!username) {
      dispatchProps.dispatch(
        addNotification("Please enter your email address.", "warning")
      );
      return;
    }

    if (!password) {
      dispatchProps.dispatch(
        addNotification("Please enter your password.", "warning")
      );
      return;
    }

    let returnUrl = getRedirectPath(ownProps);
    dispatchProps.dispatch(
      onLogin(username, password, returnUrl, ownProps.history)
    );
  },
  onLogout: () => {
    dispatchProps.dispatch(
      onLogout(Config.logoutRedirectUrl, ownProps.history)
    );
  },
});

/**
 * Redirect ReturnUrl if its provided otherwise go to default route '/' :-)
 */
const getRedirectPath = (props) => {
  const locationState = props.location.state;
  const pathName =
    locationState && locationState.from && locationState.from.pathname;
  return pathName || "/";
};

// const validate = stateProps => {
//   if (
//     !stateProps.loginData.fields.username ||
//     stateProps.loginData.fields.username == undefined ||
//     stateProps.loginData.fields.username === ""
//   ) {
//     dispatch(
//       addNotification("Please enter your email address........", "warning")
//     );
//     return false;
//   }

//   return true;
// };

const LoginBarContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeFormProps
  )(LoginBar)
);

export default LoginBarContainer;
