import React from "react";
import "./header.scss";
import LoginBarContainer from "../Login-Bar/Login-Bar.Container";
import NavBar from "../NavBar";
import * as Constants from "../../constants";
import UserIdentity from "./../../coreLib/auth/userIdentity";

const Header = props => {
  const { loggedIn } = props.loginData;
  const identity = UserIdentity();
  const currentRole = identity.getRole();

  let privateLinks = Constants.privateNavLinks.filter(o => {
    if (o.roles && o.roles.includes(currentRole)) {
      return o;
    } else if (o.roles == null) {
      return o;
    }
  });

  return (
    <header className="header">
      <LoginBarContainer />

      <NavBar navLinks={loggedIn ? privateLinks : Constants.navLinks} />
    </header>
  );
};

export default Header;
