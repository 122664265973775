import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import DateService from "../../services/date-service";
import CourseService from "../../services/course-service";
import "./your-courses.scss";
import BlockUiFx from "../../components/Common/Block-Ui-Fx/Block-Ui-Fx";

class YourCourses extends Component {
  getCourseActionButtons = (
    course,
    downloadCertificate,
    downloadThirdPartyForm,
    downloadTaxInvoice
  ) => {
    let buttons = [];

    if (
      !course.hasCompleted &&
      course.completedEnrolment &&
      !course.courseExpired
    ) {
      let courseButton = (
        <Link
          key={`${course.id}-1`}
          to={`/course-content/${course.id}/`}
          className="ttg-btn btn ttg-blue-btn"
          title="Continue Course"
        >
          Continue Course
        </Link>
      );

      buttons.push(courseButton);
    }

    if (!course.completedEnrolment && !course.courseExpired) {
      let courseButton = (
        <Link
          key={`${course.id}-2`}
          to={`/course-enrolment/${course.id}/step-2/`}
          className="ttg-btn btn ttg-blue-btn"
          title="Continue Enrolment"
        >
          Continue Enrolment
        </Link>
      );

      buttons.push(courseButton);
    }

    if (course.hasCertificate) {
      let certificateButton = (
        <a
          key={`${course.id}-3`}
          style={{ color: "white" }}
          className="ttg-btn btn ttg-blue-btn"
          onClick={() => downloadCertificate(course.id)}
          title="Download Certificate"
          rel="noopener noreferrer"
        >
          Download Certificate
        </a>
      );

      buttons.push(certificateButton);

      let thirdPartyFormButton = (
        <a
          key={`${course.id}-7`}
          style={{ color: "white" }}
          className="ttg-btn btn ttg-blue-btn"
          onClick={() => downloadThirdPartyForm(course.thirdPartyAccessToken)}
          title="Download Third Party Form"
          rel="noopener noreferrer"
        >
          Download Third Party Form
        </a>
      );

      buttons.push(thirdPartyFormButton);
    }
    if (
      course.completedEnrolment &&
      (course.hasCertificate || !course.courseExpired)
    ) {
      let userCourseToken = course.id;

      let courseGuideButton = (
        <a
          key={`${course.id}-4`}
          href={`/Print-Course-Content-Guide/${userCourseToken}`}
          className="ttg-btn btn ttg-blue-btn"
          target="_blank"
          title="View Course Guide"
          rel="noopener noreferrer"
        >
          Course Guide
        </a>
      );
      buttons.push(courseGuideButton);
    }

    if (
      !course.completedThirdParty &&
      course.completedEnrolment &&
      !course.courseExpired
    ) {
      let thirdPartyButton = (
        <Link
          key={`${course.id}-5`}
          to={`/third-party-form/${course.thirdPartyAccessToken}/`}
          className="ttg-btn btn ttg-blue-btn"
          title="Email Third Party Form"
        >
          Email Third Party Form
        </Link>
      );

      buttons.push(thirdPartyButton);
    }

    if (course.invoiceGuid && course.invoiceGuid != null) {
      let invoiceButton = (
        <a
          key={`${course.id}-6`}
          style={{ color: "white" }}
          className="ttg-btn btn ttg-blue-btn"
          onClick={() => downloadTaxInvoice(course.invoiceGuid)}
          title="Download Tax Invoice"
          rel="noopener noreferrer"
        >
          Tax Invoice
        </a>
      );

      buttons.push(invoiceButton);
    }

    return buttons;
  };

  componentDidMount() {
    this.props.loadCourses();
  }

  render() {
    const { courses, isProcessing } = this.props.yourCourses;
    const {
      downloadCertificate,
      downloadThirdPartyForm,
      downloadTaxInvoice,
    } = this.props;
    const dateService = DateService();
    const courseService = CourseService();

    return (
      <React.Fragment>
        <BlockUiFx blocking={isProcessing} lowerZIndex={true}>
          <Table className="your-courses-table">
            <thead>
              <tr>
                <th className="course-heading">Course</th>
                <th className="date-heading">Enrolled On</th>
                <th className="status-heading">Status</th>
              </tr>
            </thead>
            <tbody>
              {courses.map((course, i) => {
                const courseDetails = courseService.getCourse(course.courseId);
                return (
                  <tr key={i}>
                    <td className="course-val">
                      {/* <div>
                        <table>
                          <tr>
                            <td>Course Id</td>
                            <td>{course.id}</td>
                          </tr>
                          <tr>
                            <td>Invoice Id</td>
                            <td>{course.invoiceGuid}</td>
                          </tr>
                        </table>
                      </div> */}
                      <span className="course-name">
                        {courseDetails.courseTitle}
                        {/* ({courseDetails.certificateCodesString}) */}
                        {(courseDetails.courseId === 6 ||
                          courseDetails.courseId === 8) &&
                          course.hasCertificate && (
                            <React.Fragment>
                              <p className="mt-3">
                                The &ldquo;Download Certificate&rdquo; button
                                below allows you to obtain your Nationally
                                Accredited Statement of Attainment.
                                <br />
                                <br />
                                Your NSW Food Safety Supervisor Certificate
                                (HARD COPY) will be expressed posted to you
                                within the next 5-7 days. Please be patient
                                &#128522;
                              </p>
                            </React.Fragment>
                          )}
                      </span>
                      <div className="action-btns">
                        {this.getCourseActionButtons(
                          course,
                          downloadCertificate,
                          downloadThirdPartyForm,
                          downloadTaxInvoice
                        )}
                      </div>
                    </td>
                    <td className="date-val">
                      {dateService.formatDateFromString(
                        course.enrolmentDate,
                        "DD/MM/YYYY"
                      )}
                    </td>
                    <td className="status-val">{course.status}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </BlockUiFx>
      </React.Fragment>
    );
  }
}

export default YourCourses;
