import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../components/Content-Section";
import TickList from "../../components/Tick-List/Tick-List";
import HomePageCourses from "../../components/Home-Page-Courses/Home-Page-Courses";

class Certificates extends Component {
  render() {
    return (
      <React.Fragment>
        <PageTitle title="Online Food Safety Training Certificates" />
        <ContentSection bgColor="grey-shadow">
          <h1>Food Safety Training</h1>
          <p>
            All our training is Nationally Recognised and Australian Government
            Approved. Our courses are delivered 100% online with no hidden
            assessment or fee&rsquo;s. You can receive your certificate today,
            it&rsquo;s easy and stress free!!
          </p>
          <HomePageCourses />
        </ContentSection>
        <ContentSection>
          <h2 className="text-center">So why train with us?</h2>
          <div className="row">
            <div className="col-md-6 col-12 mb-0">
              <TickList
                className="mb-0"
                items={[
                  <span>We are Standard 3.2.2A Compliant</span>,
                  <span>
                    Train to Gain Pty Ltd t/a Food Safety Education is a
                    Registered Training Organisation (# 22361) with over 30
                    years of hospitality experience
                  </span>,
                  <span>
                    Our courses are Nationally Accredited Australia wide with NO
                    HIDDEN ASSESSMENT or PAYMENTS
                  </span>,
                  <span>
                    NSW Food Authority approved – RTO Approval Number: 25085
                  </span>,
                  <span>
                    Have peace of mind and get the certificates that council
                    want to see
                  </span>,
                  <span>
                    Email and phone support with real people from the
                    hospitality industry
                  </span>,
                  <span>
                    No paper, no printing, get your certificate today. 100%
                    online.
                  </span>,
                  <span>
                    You get as many times as you need to get the answer right!
                  </span>,
                ]}
                tickColor="green"
              />
            </div>
            <div className="col-md-6 col-12">
              <TickList
                items={[
                  <span>You can print out your certificate today!</span>,
                  <span>Educational workbook included</span>,
                  <span>
                    Up to date content with fun and informative videos to watch
                  </span>,
                  <span>
                    We give the best customer service in the business because we
                    know our stuff
                  </span>,
                  <span>
                    Phone support from 9am-5pm Monday to Friday (QLD Time)
                  </span>,
                  <span>
                    We use industry standard SSL security &amp; eWay to securely
                    process your transaction
                  </span>,
                  <span>
                    Bulk orders available for businesses and student tracking
                    facility for business clients
                  </span>,
                  <span>
                    Convenience and comfort of training in your own home or
                    workplace, 100% online.
                  </span>,
                ]}
                tickColor="green"
              />
            </div>
          </div>
        </ContentSection>
      </React.Fragment>
    );
  }
}

export default withRouter(Certificates);
