import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col,
  FormGroup
} from "reactstrap";
import { titles, userTypes } from "../../../../constants";
import formHandler from "../../../../components/Common/Fields/form-handler";
import "../../../../components/Common/Fields/Fields.scss";
import ButtonFx from "../../../../components/Common/Button-Fx/Button-Fx";
import InputField from "../../../../components/Common/Fields/Input-Field";
import SelectField from "../../../../components/Common/Fields/Select-Field";
import * as validators from "../../../../components/Common/Fields/Validators/Field-Validators";

const emailValidator = (fieldName, value) => {
  if (validators.required(fieldName, value))
    return validators.required(fieldName, value);
  if (validators.email(value)) return validators.email(value);

  return false;
};

const validateSelect = (fieldName, value) => {
  if (validators.required(fieldName, value) || value === "-1") {
    return `${fieldName} is required.`;
  }
  return false;
};

export default class EditUserModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      toggleEditDialog,
      onFieldError,
      onDialogInputChange,
      onEdit,
      dialogs,
      isProcessing,
      fields
    } = this.props;
    const { firstName, lastName, email, title, active, userType } = fields;
    const form = formHandler();
    return (
      <div>
        <Modal unmountOnClose={true} isOpen={dialogs.showEditDialog}>
          <ModalHeader toggle={() => toggleEditDialog()}>Edit User</ModalHeader>
          <ModalBody>
            <Form className="ttg-form">
              <Row form>
                <Col>
                  <FormGroup>
                    <label>Title</label>
                    <SelectField
                      name="title"
                      ref={form.add}
                      validate={val => validateSelect("Title", val)}
                      value={title}
                      onFieldError={onFieldError}
                      onChange={onDialogInputChange}
                    >
                      <option value="-1">- Select -</option>
                      {titles.map(title => {
                        return (
                          <option key={title.value} value={title.value}>
                            {title.label}
                          </option>
                        );
                      })}
                    </SelectField>
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col xs="12" md="6">
                  <FormGroup>
                    <label>First Name</label>
                    <InputField
                      name="firstName"
                      placeholder="First Name"
                      ref={form.add}
                      value={firstName}
                      validate={val => validators.required("First Name", val)}
                      onFieldError={onFieldError}
                      onChange={onDialogInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" md="6">
                  <FormGroup>
                    <label>Last Name</label>
                    <InputField
                      name="lastName"
                      ref={form.add}
                      placeholder="Last Name"
                      value={lastName}
                      validate={val => validators.required("Last Name", val)}
                      onFieldError={onFieldError}
                      onChange={onDialogInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <label>Email</label>
                    <InputField
                      name="email"
                      ref={form.add}
                      placeholder="Email"
                      type="email"
                      value={email}
                      validate={val => emailValidator("Email", val)}
                      onFieldError={onFieldError}
                      onChange={onDialogInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col xs="12" md="6">
                  <FormGroup>
                    <label>Active</label>
                    <SelectField
                      name="active"
                      ref={form.add}
                      validate={val => validateSelect("Active", val)}
                      value={active}
                      onFieldError={onFieldError}
                      onChange={onDialogInputChange}
                    >
                      <option value="-1">- Select -</option>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </SelectField>
                  </FormGroup>
                </Col>
                <Col xs="12" md="6">
                  <FormGroup>
                    <label>User Type</label>
                    <SelectField
                      name="userType"
                      ref={form.add}
                      validate={val => validateSelect("User type", val)}
                      value={userType}
                      onFieldError={onFieldError}
                      onChange={onDialogInputChange}
                    >
                      <option value={-1}>- Select -</option>
                      {userTypes.map(type => {
                        return (
                          <option key={type.value} value={type.value}>
                            {type.label}
                          </option>
                        );
                      })}
                    </SelectField>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <ButtonFx
              type="submit"
              onClick={() =>
                form.validate({
                  validFn: () => {
                    onEdit(fields);
                  }
                })
              }
              className="ttg-btn"
              isLoading={isProcessing}
            >
              Save
            </ButtonFx>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
