/**
 * Login Impersonation Component
 * - grabs the token and user id to send back to server
 * - if successfull server will respond a valid jwt
 * rcnet @solvable 2019
 */
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { selectLoginData } from "../../../redux/system/login-reducer";
import * as actionCreators from "../../../redux/system/system-action-creators";
import ContentSection from "../../Content-Section/index";
import BlockUiFx from "./../Block-Ui-Fx/Block-Ui-Fx";

// Functional component with react hooks
const LoginImpersonationContainer = ({
  urlRedirectionViaImpersonation,
  match,
  onLogin
}) => {
  //   redirectNow =false;

  useEffect(() => {
    const { userId, token } = match.params;
    onLogin(userId, token);
  }, []);

  return (
    <React.Fragment>
      {urlRedirectionViaImpersonation !== "" ? (
        <Redirect
          to={{
            pathname: urlRedirectionViaImpersonation
          }}
        />
      ) : (
        <BlockUiFx blocking={true} lowerZIndex={true}>
          <ContentSection padding={3} bgColor="grey-shadow">
            <div style={{ textAlign: "center", minHeight: "100px" }}>
              <h4>Logging in please wait...</h4>
            </div>
          </ContentSection>
        </BlockUiFx>
      )}
    </React.Fragment>
  );
};

// ---------------
// Redux-Connect
// ---------------
const mapStateToProps = state => {
  const { urlRedirectionViaImpersonation } = selectLoginData(state);
  return { urlRedirectionViaImpersonation };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLogin: (id, token) => {
    dispatch(actionCreators.loginImpersonationInit());
    dispatch(actionCreators.onImpersonateLogin(id, token, ownProps.history));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginImpersonationContainer);
