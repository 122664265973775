import React, { Component } from "react";
import { Collapse, CardBody, Card, CardHeader } from "reactstrap";

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: 0 };
  }

  toggle(e) {
    let event = e.target.dataset.event;
    this.setState({
      collapse: this.state.collapse === Number(event) ? 0 : Number(event)
    });
  }

  render() {
    const { collapse } = this.state;
    const { content } = this.props;

    return (
      <React.Fragment>
        {content.map((item, i) => (
          <Card style={{ marginBottom: "1rem" }} key={i}>
            <CardHeader
              onClick={this.toggle}
              data-event={i}
              style={{ cursor: "pointer" }}
            >
              {item.heading}
            </CardHeader>
            <Collapse isOpen={collapse === i}>
              <CardBody>{item.content}</CardBody>
            </Collapse>
          </Card>
        ))}
      </React.Fragment>
    );
  }
}

export default Accordion;
