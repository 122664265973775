import React from "react";
import CarouselItem from "../Carousel-Item";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./carousel.scss";

const View = ({ items }) => {
  const owlOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    margin: 10,
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      500: {
        items: 2
      },
      768: {
        items: 3
      },
      1200: {
        items: 5
      }
    }
  };

  return (
    <div className="row m-0">
      <div className="col">
        <OwlCarousel className="owl-theme" {...owlOptions}>
          {items.map((item, i) => (
            <CarouselItem key={i} imageName={item} />
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default View;
