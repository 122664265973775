import React, { Component } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../components/Content-Section";
import TickList from "../../components/Tick-List/Tick-List";
import ArrowLinkButton from "../../components/Arrow-Link-Button/Arrow-Link-Button";
import ContactBoxContainer from "../../components/Contact-Box/Contact-Box.Container";

class ContactUs extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <PageTitle title="Contact Us - Food Safety Education | Train to Gain" />
        <ContentSection bgColor="grey-shadow">
          <h1>Contact Us</h1>
          <TickList
            items={[
              <span>
                Please read our informative{" "}
                <Link to="/FAQs/">frequently asked questions (FAQs)</Link> first
                as these may instantly assist you.
              </span>,
              <span>
                Student support is available from 9am-5pm Monday to Friday (QLD
                Time) via email and phone.
              </span>,
              <span>Email us using the email box below.</span>,
              <span>
                Call us on <a href="tel:+61400757645">0400 757 645</a>
              </span>,
            ]}
            tickColor="green"
          />
          <div className="my-4">
            <ArrowLinkButton
              to="/Login/"
              title="Start Your Food Safety Training Now!"
            />
          </div>
          <div className="row">
            <div className="col-md-6">
              <ContactBoxContainer />
            </div>
          </div>
        </ContentSection>
      </React.Fragment>
    );
  }
}

export default ContactUs;
