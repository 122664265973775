import React, { Component } from "react";
import { withRouter } from "react-router";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../components/Content-Section";
import * as PublicContent from "../../constants/public-content";

class ContentPage extends Component {
  getPageName = pathName => {
    return pathName
      .replace(new RegExp("^[/]+"), "")
      .replace(new RegExp("[/]+$"), "")
      .toLowerCase();
  };

  getContentPage = pageName => {
    let page = PublicContent.publicPages.filter(
      page => page.pageName === pageName
    );

    if (page.length === 0)
      page = PublicContent.publicPages.filter(
        page => page.pageName === "not-found"
      );

    return page[0];
  };

  render() {
    const { location } = this.props;
    const pageName = this.getPageName(location.pathname);
    const page = this.getContentPage(pageName);

    return (
      <React.Fragment>
        <PageTitle title={page.title} />
        <ContentSection bgColor="grey-shadow" padding={4}>
          <h1 className="my-0">{page.heading}</h1>
        </ContentSection>
        <ContentSection padding={3}>{page.content}</ContentSection>
      </React.Fragment>
    );
  }
}

export default withRouter(ContentPage);
