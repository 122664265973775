import React, { Component } from "react";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../components/Content-Section";
import CourseContentButtons from "../../components/Course-Content-Buttons/Course-Content-Buttons";
import CourseContentSections from "../../components/Course-Content-Sections/Course-Content-Sections";
import BlockUiFx from "./../../components/Common/Block-Ui-Fx/Block-Ui-Fx";
import "./course-content.scss";
import ResponsiveHtml from "components/Common/responsive-html-renderer/responsive-html";

class CourseContent extends Component {
  componentDidMount() {
    const { match, onLoadSection } = this.props;
    onLoadSection(match.params.userCourseId, 1);
  }

  componentWillReceiveProps(newProps) {
    if (
      this.props.courseContent.currentPageIndex !==
      newProps.courseContent.currentPageIndex
    )
      window.scrollTo(0, 0);
  }

  render() {
    const {
      courseName,
      currentPageIndex,
      totalPages,
      totalSections,
      currentSection,
      currentSectionContent,
      isLoading,
    } = this.props.courseContent;
    const { onNextPage, onPreviousPage, onLoadQuiz } = this.props;

    return (
      <React.Fragment>
        <BlockUiFx id="courseContent" blocking={isLoading} lowerZIndex={true}>
          {!isLoading ? (
            <React.Fragment>
              <PageTitle title={`${courseName} - Section ${currentSection}`} />
              <ContentSection bgColor="grey-shadow">
                <h3>
                  {courseName} - Section {currentSection}
                </h3>
                <CourseContentSections
                  currentSection={currentSection}
                  totalSections={totalSections}
                />

                {/* <CourseContentButtons
                  currentSection={currentSection}
                  currentPageIndex={currentPageIndex}
                  totalPages={totalPages}
                  onPreviousPage={onPreviousPage}
                  onNextPage={onNextPage}
                  onLoadQuiz={onLoadQuiz}
                /> */}
              </ContentSection>
              <ContentSection padding={5}>
                <div className="cms-styles-common cms-styles-rendered cms-styles-remove-margin">
                  <ResponsiveHtml html={currentSectionContent} />
                </div>
                <CourseContentButtons
                  currentSection={currentSection}
                  currentPageIndex={currentPageIndex}
                  totalPages={totalPages}
                  onPreviousPage={onPreviousPage}
                  onNextPage={onNextPage}
                  onLoadQuiz={onLoadQuiz}
                />
              </ContentSection>
            </React.Fragment>
          ) : (
            <div className="course-loading-content"></div>
          )}
        </BlockUiFx>
      </React.Fragment>
    );
  }
}

export default CourseContent;
