import React from "react";
import { FormGroup, Label, Row, Col } from "reactstrap";
import InputField from "../Common/Fields/Input-Field";
import SelectField from "../Common/Fields/Select-Field";
import * as Constants from "../../constants";
import * as validators from "../Common/Fields/Validators/Field-Validators";

const validateSelect = (fieldName, value) => {
  if (validators.required(fieldName, value) || value === "-1") {
    return `${fieldName} is required.`;
  }
  return false;
};

const AddressEntry = props => {
  return (
    <React.Fragment>
      <FormGroup className="mb-2">
        <Row form>
          <Col md="3" className="mt-2">
            <Label htmlFor="unitNumber">Unit Number</Label>
            <InputField
              name="unitNumber"
              value={props.unitNumber}
              onChange={props.onInputChange}
              ref={props.form.add}
            />
          </Col>
          <Col md="3" className="mt-2">
            <Label htmlFor="streetNumber">Street Number</Label>
            <InputField
              name="streetNumber"
              value={props.streetNumber}
              onChange={props.onInputChange}
              onFieldError={props.onFieldError}
              ref={props.form.add}
              validate={(val) => validators.required("Street number", val)}
            />
          </Col>
          <Col md="6" className="mt-2">
            <Label htmlFor="streetName">Street Name</Label>
            <InputField
              name="streetName"
              ref={props.form.add}
              value={props.streetName}
              onChange={props.onInputChange}
              onFieldError={props.onFieldError}
              validate={(val) => validators.required("Street name", val)}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row form>
          <Col md="5" className="mt-2">
            <Label htmlFor="city">City</Label>
            <InputField
              name="city"
              ref={props.form.add}
              value={props.city}
              onChange={props.onInputChange}
              onFieldError={props.onFieldError}
              validate={(val) => validators.required("City", val)}
            />
          </Col>
          <Col md="4" className="mt-2">
            <Label htmlFor="state">State</Label>
            <SelectField
              name="state"
              ref={props.form.add}
              value={props.state}
              validate={(val) => validateSelect("State", val)}
              onFieldError={props.onFieldError}
              onChange={props.onInputChange}
            >
              <option value="-1">- select state -</option>
              {Constants.australianStates.map((item, i) => (
                <option key={i} value={item.value}>
                  {item.label}
                </option>
              ))}
            </SelectField>
          </Col>
          <Col md="3" className="mt-2">
            <Label htmlFor="postcode">Postcode</Label>
            <InputField
              name="postcode"
              ref={props.form.add}
              value={props.postcode}
              onChange={props.onInputChange}
              onFieldError={props.onFieldError}
              validate={(val) => validators.required("Post Code", val)}
            />
          </Col>
        </Row>
      </FormGroup>
    </React.Fragment>
  );
};

export default AddressEntry;
