import React, { Component } from "react";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../components/Content-Section";
import LoginBoxContainer from "../../components/Login-Box/Login-Box.Container";
import ArrowLinkButton from "../../components/Arrow-Link-Button/Arrow-Link-Button";
import Auth from "../../coreLib/auth/auth";
import UserIdentity from "../../coreLib/auth/userIdentity";

class Login extends Component {
  componentDidMount() {
    if (Auth.isAuthenticated()) {
      let role = UserIdentity().getRole();
      let redirectTo = null;
      switch (role) {
        case "Admin":
          redirectTo = "/admin/users";
          break;
        case "NotSet":
        case "Student":
          redirectTo = "/your-courses";
          break;
        case "Business":
          redirectTo = "/corporate";
          break;
      }
      this.props.history.push(redirectTo);
    }
  }

  render() {
    return (
      <React.Fragment>
        <PageTitle title="Login" />
        <ContentSection bgColor="grey-shadow">
          <div className="row">
            <div className="col-md-6 col-12">
              <h1>Login</h1>
              <h5>Already a student? Login below.</h5>
              <LoginBoxContainer />
            </div>
            <div className="col-md-6 col-12">
              <h1>Not a student?</h1>
              <div className="mt-2">
                <ArrowLinkButton to="/Certificates/" title="Get started now" />
              </div>
              {/* <div className="mt-2">
                <ArrowLinkButton
                  to="/Corporate-Vouchers-for-Course-Participation/"
                  title="Business Vouchers for Staff Training"
                />
              </div> */}
            </div>
          </div>
        </ContentSection>
      </React.Fragment>
    );
  }
}

export default Login;
