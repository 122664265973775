import React from "react";
import "./course-card.scss";

const CourseCard = ({ courseCode, courseTitle, oldCourseCode }) => {
  return (
    <div className="course-card col-sm-4 m-2 ttg-box-shadow">
      <span className="course-code">
        {courseCode}{" "}
        {oldCourseCode ? (
          <span className="old-course-code">{oldCourseCode}</span>
        ) : (
          ""
        )}
      </span>
      <span className="course-title">{courseTitle}</span>
    </div>
  );
};

export default CourseCard;
