/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Row, Col, Button } from "reactstrap";
import "./signature-pad.scss";
import { PropTypes } from "prop-types";
import ToolTipFx from "./../tooltip/tool-tip-fx";

const SignaturePad = ({
  id,
  penColor,
  padColor,
  onChange,
  outputType,
  initialValue,
  padHeight,
  padWidth,
  isReadonly,
}) => {
  let signatureCanvas = {};

  const clearPad = () => {
    signatureCanvas.clear();
    onChange("");
  };

  const getTrimmedCanvasPad = () => {
    return signatureCanvas.getTrimmedCanvas().toDataURL(outputType);
  };

  const onEnd = () => {
    onChange(getTrimmedCanvasPad());
  };

  // useEffect(() => {
  //   if (initialValue) {
  //     signatureCanvas.clear();
  //     signatureCanvas.fromDataURL(initialValue);
  //   }

  //   if (isReadonly && signatureCanvas) signatureCanvas.off();

  //   return () => {
  //     if (signatureCanvas) signatureCanvas.off();
  //   };
  // }, []);

  useEffect(() => {
    if (initialValue && signatureCanvas.isEmpty()) {
      signatureCanvas.clear();
      signatureCanvas.fromDataURL(initialValue);
    }

    if (isReadonly && signatureCanvas) signatureCanvas.off();

    return () => {
      if (signatureCanvas) signatureCanvas.off();
    };
  }, [initialValue]);

  return (
    <React.Fragment>
      <Row id="signaturePad">
        <Col className="signature-container">
          <div className="signature-box-item">
            <SignatureCanvas
              penColor={penColor}
              canvasProps={{ width: padWidth, height: padHeight }}
              backgroundColor={padColor}
              readOnly={true}
              onEnd={onEnd}
              ref={(ref) => {
                signatureCanvas = ref;
              }}
            />
          </div>
          <div className="signature-toolBox">
            <Button
              id={id}
              type="button"
              className="mt-2 mr-2 ml-2"
              disabled={isReadonly}
              onClick={clearPad}
            >
              <span
                style={{
                  fontSize: "1.25em",
                  lineHeight: "1em",
                  fontWeight: "bold",
                }}
              >
                x
              </span>
            </Button>
            <ToolTipFx target={id}>Clear</ToolTipFx>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SignaturePad;

SignaturePad.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  penColor: PropTypes.string,
  padColor: PropTypes.string,
  outputType: PropTypes.string,
  padHeight: PropTypes.number,
  padWidth: PropTypes.number,
};

SignaturePad.defaultProps = {
  id: "signaturePad1",
  penColor: "#000",
  padColor: "#fff",
  outputType: "image/png",
  padHeight: 150,
  padWidth: 300,
};
