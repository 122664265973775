import React from "react";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../components/Content-Section";
import ResetPasswordContainer from "../../components/Reset-Password/Reset-Password.Container";

const ResetPasswordView = () => (
  <React.Fragment>
    <PageTitle title="Reset Password" />
    <ContentSection bgColor="grey-shadow">
      <div className="row">
        <div className="col-md-6 col-12">
          <h1>Reset Password</h1>
          <ResetPasswordContainer />
        </div>
      </div>
    </ContentSection>
  </React.Fragment>
);

export default ResetPasswordView;
