import React from "react";
import "./course-steps.scss";

const CourseSteps = ({ steps }) => {
  return (
    <div className="row certificate-steps">
      {steps.map((step, i) => (
        <div key={i} className="col-md-6 col-12">
          <div className="step-wrap">
            <div className="step-number-wrap">
              <span className="step-number step-number-green">{i + 1}</span>
            </div>
            <div className="step-text-wrap">{step}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CourseSteps;
