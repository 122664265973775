export const ActionTypes = {
         STEP_2_INIT: "STEP_2_INIT",
         STEP_2_UPDATE_DOB_FIELD: "STEP_2_UPDATE_DOB_FIELD",
         STEP_2_INIT_SUCCEEDED: "STEPSTEP_2_INIT_SUCCEEDED",
         STEP_2_INIT_FAILED: "STEP_2_INIT_FAILED",

         STEP_2_UPDATE_ADDRESS_FROM_STEP_1_ADDRESS:
           "STEP_2_UPDATE_ADDRESS_FROM_STEP_1_ADDRESS",

         STEP_2_TOGGLE_TERTIARY: "STEP_2_TOGGLE_TERTIARY",
         STEP_2_TOGGLE_WHAT_IS_USI: "STEP_2_TOGGLE_WHAT_IS_USI",
         STEP_2_TOGGLE_HAS_USI: "STEP_2_TOGGLE_HAS_USI",
         STEP_2_TOGGLE_DISABILITY: "STEP_2_TOGGLE_DISABILITY",
         STEP_2_TOGGLE_COUNTRY: "STEP_2_TOGGLE_COUNTRY",
         STEP_2_TOGGLE_OTHER_LANGUAGE: "STEP_2_TOGGLE_OTHER_LANGUAGE",
         STEP_2_TOGGLE_UNDERSTANDING_OF_ENGLISH:
           "STEP_2_TOGGLE_UNDERSTANDING_OF_ENGLISH",
         STEP_2_TOGGLE_UNDERSTAND_FALSE_INFO:
           "STEP_2_TOGGLE_UNDERSTAND_FALSE_INFO",
         STEP_2_TOGGLE_UNDERSTAND_TIME_TO_COMPLETE:
           "STEP_2_TOGGLE_UNDERSTAND_TIME_TO_COMPLETE",
         STEP_2_FORM_VALID: "STEP_2_FORM_VALID",

         STEP_2_TOGGLE_GUARDIAN_ACKNOWLEDGEMENT:
           "STEP_2_TOGGLE_GUARDIAN_ACKNOWLEDGEMENT",

         STEP_2_SUBMIT_ENROLMENT_REQUEST: "STEP_2_SUBMIT_ENROLMENT_REQUEST",
         STEP_2_SUBMIT_ENROLMENT_SUCCESS: "STEP_2_SUBMIT_ENROLMENT_SUCCESS",
         STEP_2_SUBMIT_ENROLMENT_FAILURE: "STEP_2_SUBMIT_ENROLMENT_FAILURE",

         STEP_2_FORM_DATE_CHANGE: "STEP_2_FORM_DATE_CHANGE",
         STEP_2_FORM_INPUT_CHANGE: "STEP_2_FORM_INPUT_CHANGE",

         // ADDRESS
         STEP_2_AUTOCOMPLETE_ADDRESS_UPDATE_SUCCESS:
           "STEP_2_AUTOCOMPLETE_ADDRESS_UPDATE_SUCCESS",
         STEP_2_TOGGLE_MANUAL_ADDRESS_ENTRY:
           "STEP_2_TOGGLE_MANUAL_ADDRESS_ENTRY",
         STEP_2_SHOW_MANUAL_ADDRESS_ENTRY: "STEP_2_SHOW_MANUAL_ADDRESS_ENTRY",
         STEP_2_CLEAR_ADDRESS: "STEP_2_CLEAR_ADDRESS",
       };
