import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../components/Content-Section";
import TickList from "../../components/Tick-List/Tick-List";
import ArrowLinkButton from "../../components/Arrow-Link-Button/Arrow-Link-Button";
import Carousel from "../../components/Carousel";
import CourseCardContainer from "../../components/Course-Card-Container/Course-Card-Container";
import CourseCard from "../../components/Course-Card/Course-Card";
import * as Constants from "../../constants";
import "../../assets/styles/map.scss";
// import OnlineTrainingGraphic from "../../assets/images/online-training-graphic.jpg";
import HomePageCourses from "../../components/Home-Page-Courses/Home-Page-Courses";
import CourseSteps from "../../components/Course-Steps/Course-Steps";
import "./home.scss";

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <PageTitle title="" />
        <ContentSection bgColor="fruit">
          <h1 className="orange-brush">
            Welcome to
            <br />
            Food Safety Education
          </h1>
          <TickList
            items={[
              "100% Online Food Safety Training",
              "Nationally Accredited Training",
              "Standard 3.2.2A Compliant",
              "Print Out Your Certificate Today",
              "Online Assessment",
              "Workbook Included",
              "All States Approved",
              "2-6 hours (Average Course Duration)",
            ]}
            tickColor="green"
            headingStyle={true}
          />
          <ArrowLinkButton to="/Certificates/" title="Start Now" />
          <div className="standards-compliant-wrap">
            <img
              src="/images/standard-3.2.2a-complicant.png"
              alt="Standard 3.2.2A Compliant Training"
              className="standards-compliant-tick"
            />
          </div>
        </ContentSection>

        <ContentSection>
          <h2>How do I get my certificate?</h2>
          <CourseSteps
            steps={[
              <span>Choose your course</span>,
              <span>Make your secure payment</span>,
              <span>
                Complete enrolment form and start your online training
              </span>,
              <span>
                Finish online training and the third-party form is submitted,
                then your Nationally Accredited Certificate will be emailed to
                you! That’s it!
              </span>,
            ]}
          />
        </ContentSection>
        <ContentSection>
          <HomePageCourses />
        </ContentSection>
        <ContentSection bgColor="grey">
          <h2 className="text-center">So why train with us?</h2>
          <div className="row">
            <div className="col-md-6 col-12 mb-0">
              <TickList
                className="mb-0"
                items={[
                  <span>We are Standard 3.2.2A Compliant</span>,
                  <span>
                    Train to Gain Pty Ltd t/a Food Safety Education is a
                    Registered Training Organisation (# 22361) with over 30
                    years of hospitality experience
                  </span>,
                  <span>
                    Our courses are Nationally Accredited Australia wide with NO
                    HIDDEN ASSESSMENT or PAYMENTS
                  </span>,
                  <span>
                    NSW Food Authority approved – RTO Approval Number: 25085
                  </span>,
                  <span>
                    Have peace of mind and get the certificates that council
                    want to see
                  </span>,
                  <span>
                    Email and phone support with real people from the
                    hospitality industry
                  </span>,
                  <span>
                    No paper, no printing, get your certificate today. 100%
                    online.
                  </span>,
                  <span>
                    You get as many times as you need to get the answer right!
                  </span>,
                ]}
                tickColor="green"
              />
            </div>
            <div className="col-md-6 col-12">
              <TickList
                items={[
                  <span>You can print out your certificate today!</span>,
                  <span>Educational workbook included</span>,
                  <span>
                    Up to date content with fun and informative videos to watch
                  </span>,
                  <span>
                    We give the best customer service in the business because we
                    know our stuff
                  </span>,
                  <span>
                    Phone support from 9am-5pm Monday to Friday (QLD Time)
                  </span>,
                  <span>
                    We use industry standard SSL security &amp; eWay to securely
                    process your transaction
                  </span>,
                  <span>
                    Bulk orders available for businesses and student tracking
                    facility for business clients
                  </span>,
                  <span>
                    Convenience and comfort of training in your own home or
                    workplace, 100% online.
                  </span>,
                ]}
                tickColor="green"
              />
            </div>
          </div>
        </ContentSection>
        <ContentSection>
          <h2 className="text-center">
            Food Safety Supervisor Certificate for
            <br />
            Hospitality or Retail industries
          </h2>
          <CourseCardContainer>
            <CourseCard
              courseCode="SITXFSA005"
              courseTitle="Use Hygienic Practices for Food Safety"
            />
            <CourseCard
              courseCode="SITXFSA006"
              courseTitle="Participate in Safe Food Handling Practices"
            />
          </CourseCardContainer>
          <h2 className="text-center">
            Food Safety Supervisor Certificates for
            <br />
            Health or Community industries
          </h2>
          <CourseCardContainer>
            <CourseCard
              courseCode="HLTFSE001"
              courseTitle="Follow Basic Food Safety Practices"
            />
            <CourseCard
              courseCode="HLTFSE007"
              courseTitle="Oversee the Day to Day Implementation of Food Safety in the Workplace"
            />
            <CourseCard
              courseCode="HLTFSE005"
              courseTitle="Apply and Monitor Food Safety Requirements"
            />
          </CourseCardContainer>
        </ContentSection>
        <ContentSection bgColor="food">
          <h2>Food Safety Supervisor Certificate Training Online</h2>
          <p>
            We are Australia&rsquo;s Number 1 Registered Training Organisation
            for quality customer service and we pride ourselves on giving you
            fast and effective training you can trust!
          </p>
          <p>
            Train to Gain Pty Ltd t/a Food Safety Education is a Nationally
            Accredited Registered Training Organisation (provider # 22361) with
            over 30 years Hospitality/Retail and Training experience.
          </p>
          <ArrowLinkButton
            to="/Login/"
            title="Start Your Food Safety Training Now!"
          />
        </ContentSection>
        <ContentSection bgColor="grey">
          <h2>
            Are you a Business or Organisation? Buy vouchers for your staff...
          </h2>
          <p>
            We take the hassle out of organising your staff Food Safety
            training. Our online Business portal makes it quick and easy for you
            to purchase Food Safety Courses for your staff or volunteers.
          </p>
          <p>
            <ArrowLinkButton
              to="/Corporate-Vouchers-for-Course-Participation/"
              title="Click here to purchase your staff Food Safety courses now"
            />
          </p>
        </ContentSection>
        <ContentSection>
          <h2 className="text-center">Our Happy Clients</h2>
        </ContentSection>
        <div className="mb-4">
          <Carousel items={Constants.testimonials} />
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Home);
