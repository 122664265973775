import { ActionTypes } from "./action-types";

const initialState = {
  isProcessing: false,
  dialogs: {
    showDeleteDialog: false,
    showEditDialog: false
  },
  fieldErrors: {},
  fields: {
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    active: "",
    userType: ""
  },
  isSubmitting: false
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ADMIN_USERS_SHOW_EDIT_DIALOG:
      return {
        ...state,
        fields: action.payload
          ? {
              ...action.payload,
              active: action.payload.active === "Yes" ? true : false,
              userType:
                action.payload.userType === "Admin"
                  ? 1
                  : action.payload.userType === "Student"
                  ? 2
                  : 3
            }
          : {},
        dialogs: {
          ...state.dialogs,
          showEditDialog: !state.dialogs.showEditDialog
        }
      };
    case ActionTypes.ADMIN_USERS_SHOW_DELETE_DIALOG:
      return {
        ...state,
        fields: action.payload ? action.payload : {},
        dialogs: {
          ...state.dialogs,
          showDeleteDialog: !state.dialogs.showDeleteDialog
        }
      };

    case ActionTypes.ADMIN_USERS_FILTER_FORM_INPUT_CHANGE:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error
        }
      };
    case ActionTypes.ADMIN_USERS_DIALOG_FORM_INPUT_CHANGE:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error
        }
      };
    case ActionTypes.ADMIN_USERS_DELETE_REQUEST:
    case ActionTypes.ADMIN_USERS_EDIT_REQUEST:
      return {
        ...state,
        isProcessing: true
      };
    case ActionTypes.ADMIN_USERS_DELETE_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        dialogs: {
          ...state.dialogs,
          showDeleteDialog: !state.dialogs.showDeleteDialog
        }
      };
    case ActionTypes.ADMIN_USERS_EDIT_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        dialogs: {
          ...state.dialogs,
          showEditDialog: !state.dialogs.showEditDialog
        }
      };
    default:
      return state;
  }
}

export default Reducer;
