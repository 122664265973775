import React, { Component } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../components/Content-Section";
import YourCoursesContainer from "../../components/Your-Courses/Your-Courses.Container";
import ToggleDisplay from "react-toggle-display";

class Start extends Component {
  render() {
    const { isProcessing, hasNswCertificate } = this.props;
    return (
      <React.Fragment>
        <PageTitle title="Your Courses" />
        <ContentSection bgColor="grey-shadow" padding={4}>
          <h1>Your Courses</h1>
          <ToggleDisplay show={!isProcessing}>
            <Link to="/course-enrolment/new/" className="btn ttg-btn mt-2 mr-2">
              Enrol in a New Course
            </Link>

            {hasNswCertificate && (
              <Link
                to="/course-enrolment/replacement-nsw-certificate/"
                className="btn ttg-btn mt-2"
              >
                Order a Duplicate Copy NSW FSS Certificate
              </Link>
            )}
          </ToggleDisplay>
        </ContentSection>
        <ContentSection padding={3}>
          <YourCoursesContainer />
        </ContentSection>
      </React.Fragment>
    );
  }
}

export default Start;
