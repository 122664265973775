import { connect } from "react-redux";
import Start from "./Start";

function mapStateToProps(state) {
  const stateData = state.yourCourses;
  return {
    isProcessing: stateData.isProcessing,
    hasNswCertificate: stateData.hasNswCertificate
  };
}

const StartContainer = connect(mapStateToProps)(Start);

export default StartContainer;
