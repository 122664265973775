import React, { Component } from "react";
import PropTypes from "prop-types";
import View from "./View";
import { connect } from "react-redux";
import { onLogout } from "../../redux/system/system-action-creators";
import { selectLoginData } from "../../redux/system/login-reducer";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <View
        navLinks={this.props.navLinks}
        toggle={this.toggle}
        isOpen={this.state.isOpen}
        onLogout={this.props.onLogout}
        isLoggedIn={this.props.loginData.loggedIn}
      />
    );
  }
}

NavBar.propTypes = {
  navLinks: PropTypes.array.isRequired
};

const mapStateToProps = state => {
  return {
    loginData: selectLoginData(state)
  };
};

const NavBarContainer = connect(
  mapStateToProps,
  { onLogout }
)(NavBar);
export default NavBarContainer;
