import React, { Component } from "react";
import PageTitle from "../../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../../components/Content-Section";
import ValidationError from "./sub-views/validation-error";
import ButtonFx from "../../../components/Common/Button-Fx/Button-Fx";
import DeleteDialog from "./delete-dialog";
import "./avetmiss-reporting-validation.scss";

class AvetmissReportingValidation extends Component {
  componentDidMount() {
    const { hasResult, onLoadForm } = this.props;

    if (!hasResult) onLoadForm();
  }

  render() {
    const { verificationResult } = this.props.avetmissReportingValidation;
    const {
      isLoading,
      isDownloading,
      isDeleting,
      toggleDeleteDialog,
      showDeleteDialog,
      onDelete,
      onDownloadNatFiles,
      onInputChange,
      onSubmit
    } = this.props;

    return (
      <React.Fragment>
        <PageTitle title="Avetmiss Validation Service | Validation" />
        <ContentSection isFullWidth={true} bgColor="grey-shadow">
          <h1>Avetmiss Validation Service | Validation</h1>
        </ContentSection>
        <ContentSection isFullWidth={true}>
          {verificationResult.verificationSuccessful && (
            <React.Fragment>
              <h4>Avetmiss Reporting Validated Successfully</h4>
              <h5>Step 1</h5>
              <p>
                Please login to the{" "}
                <a
                  href="https://avs.ncver.edu.au/avs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AVETMISS Validation Software
                </a>{" "}
                to finalise your submission. Once you login, click the latest
                submission under Collections and then press &ldquo;Finalise
                Submission&rdquo;.
              </p>
              <h5>Step 2</h5>
              <div className="mb-3">
                <ButtonFx
                  type="submit"
                  className="ttg-btn"
                  isLoading={isDownloading}
                  disabled={isDeleting}
                  onClick={() => onDownloadNatFiles()}
                >
                  Download NAT Files
                </ButtonFx>
              </div>
              <h5>Step 3</h5>
              <div>
                <ButtonFx
                  type="submit"
                  className="ttg-btn"
                  isLoading={isDeleting}
                  disabled={isDownloading}
                  onClick={() => toggleDeleteDialog()}
                >
                  Delete NAT Files
                </ButtonFx>
              </div>
              <DeleteDialog
                onDelete={onDelete}
                toggleDeleteDialog={toggleDeleteDialog}
                isDeleting={isDeleting}
                showDeleteDialog={showDeleteDialog}
              />
            </React.Fragment>
          )}
          {!verificationResult.verificationSuccessful && (
            <React.Fragment>
              <table className="avetmiss-validation">
                <tbody>
                  {verificationResult.errors.map((error, i) => (
                    <ValidationError
                      key={i}
                      natFileNumber={error.natFileNumber}
                      fileId={error.fileId}
                      recordNumber={error.recordNumber}
                      errorMessage={error.errorMessage}
                      fieldValue={error.fieldValue}
                      onInputChange={onInputChange}
                    />
                  ))}
                </tbody>
              </table>
              <ButtonFx
                type="submit"
                className="ttg-btn mt-2"
                isLoading={isLoading}
                onClick={() => onSubmit(verificationResult.errors)}
              >
                Save Changes
              </ButtonFx>
            </React.Fragment>
          )}
        </ContentSection>
      </React.Fragment>
    );
  }
}

export default AvetmissReportingValidation;
