import React, { Component } from "react";
import { Form, FormGroup } from "reactstrap";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import "react-datepicker/dist/react-datepicker.css";
import InputField from "../../components/Common/Fields/Input-Field";
import RadioButtonList from "../../components/Common/Fields/Radio-Button-List";
import ContentSection from "../../components/Content-Section";
import "./third-party-form.scss";
import AdditionalInfoForm from "./sub-views/additional-info-form";
import ObserverForm from "./sub-views/observer-form";
import ConfirmEmailFormSending from "./confirm-dialog/Confirm-Email-Form-Sending";
import MessageDialog from "./message-dialog/Message-Dialog";
import ButtonFx from "./../../components/Common/Button-Fx/Button-Fx";
import BlockUiFx from "./../../components/Common/Block-Ui-Fx/Block-Ui-Fx";
import formHandler from "./../../components/Common/Fields/form-handler";
import KeyboardEventHandler from "react-keyboard-event-handler";
import Auth from "../../coreLib/auth/auth";

class ThirdPartyForm extends Component {
  form = formHandler();

  componentDidMount() {
    const { match, onLoadForm, onLoadSampleForm } = this.props;

    if (match.params.userCourseId.toLowerCase() === "sample")
      onLoadSampleForm(2);
    else if (match.params.userCourseId.toLowerCase().startsWith("sample-")) {
      onLoadSampleForm(
        parseInt(match.params.userCourseId.toLowerCase().replace("sample-", ""))
      );
    } else onLoadForm(match.params.userCourseId);
  }

  getUnitsOfCompetency = (certificateCodes, unitOfCompetencies) => {
    let units = [];

    for (let i = 0; i < certificateCodes.length; i++) {
      units.push(
        <span key={i} className="unit-of-competency">
          {certificateCodes[i]} - {unitOfCompetencies[i]}
        </span>
      );
    }

    return units;
  };

  getTaskItems = (tasks, fieldResponses) => {
    const getData = (id) => {
      return fieldResponses[id] != null ? fieldResponses[id] : undefined;
    };

    return tasks.map((task, i) => (
      <tr key={i} className="task-row">
        <td>{task.task}</td>
        <td>
          <RadioButtonList
            name={`task-${task.taskId}`}
            items={[
              { label: "Yes", value: "true" },
              { label: "No", value: "false" },
            ]}
            value={getData(`task-${task.taskId}`)}
            onChange={this.props.onInputChange}
          />
        </td>
        <td>
          <InputField
            name={`task-details-${task.taskId}`}
            value={getData(`task-details-${task.taskId}`)}
            onChange={this.props.onInputChange}
            className="mxw-400"
          />
        </td>
      </tr>
    ));
  };

  getTasksTable = (tasks, fieldResponses) => {
    let taskGroups = Array.from(
      new Set(
        tasks.map((x) => {
          return x.taskGroup;
        })
      )
    );

    return (
      <table className="third-party-tasks">
        <thead>
          <tr>
            <th>
              Does the student consistently meet workplace standards in the
              following areas of competence?
            </th>
            <th>Yes / No</th>
            {/* <th>If No, provide a brief explanation</th> */}
            <th>Comments (optional)</th>
          </tr>
        </thead>
        <tbody>
          {taskGroups.map((taskGroup, i) => (
            <React.Fragment key={i}>
              <tr className="task-group">
                <td colSpan={3}>{taskGroup}</td>
              </tr>
              {this.getTaskItems(
                tasks.filter((x) => x.taskGroup === taskGroup),
                fieldResponses
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  };

  render() {
    const {
      courseName,
      certificateCodes,
      unitOfCompetencies,
      studentFirstName,
      studentLastName,
      tasks,
      onInputChange,
      onUpdateDetails,
      onEmail,
      isLoading,
      isLoadingObserverSection,
      isLoadingObserverButton,
      isLoadingForSaveProgress,
      isLoadingForSubmit,
      saveProgress,
      submitForm,
      validate,
      onFieldError,
      isSampleForm,
      showForm,
    } = this.props;

    const {
      observerFirstName,
      observerLastName,
      observerEmail,
      additionalComments,
      studentPerformanceSatisfactory,
      confirmThirdPartyDeclaration,
      signature,
    } = this.props.fields;

    const { firstName, lastName, userType } = this.props.loginData.loggedInUser;

    let isLoggedIn = Auth.isAuthenticated();

    if (isLoggedIn === undefined) isLoggedIn = false;

    return (
      <React.Fragment>
        <PageTitle title="Third Party Form" />
        <ConfirmEmailFormSending {...this.props} />
        <MessageDialog {...this.props} />
        <BlockUiFx blocking={isLoading} lowerZIndex={true}>
          <Form className="ttg-form">
            <ContentSection bgColor="grey-shadow" padding={3}>
              <h1>Third Party Form</h1>
              <h5>Observer Details</h5>
              <ObserverForm
                observerFirstName={observerFirstName}
                observerLastName={observerLastName}
                observerEmail={observerEmail}
                onInputChange={onInputChange}
                onUpdateDetails={onUpdateDetails}
                onEmail={onEmail}
                isLoading={isLoadingObserverSection}
                isUpdateButtonLoading={isLoadingObserverButton}
                validate={validate}
                onFieldError={onFieldError}
                form={this.form}
                isSampleForm={isSampleForm}
                isLoggedIn={isLoggedIn}
                firstName={firstName}
                lastName={lastName}
                userType={userType}
              />
            </ContentSection>

            {showForm && (
              <div
                className={`third-party-form${
                  isSampleForm ? " third-party-form-sample" : ""
                }`}
              >
                <ContentSection>
                  <table className="third-party-form-info">
                    <tbody>
                      <tr>
                        <td>Student Name</td>
                        <td>
                          {studentFirstName} {studentLastName}
                        </td>
                      </tr>
                      <tr>
                        <td>Course Name</td>
                        <td>{courseName}</td>
                      </tr>
                      <tr>
                        <td>Unit of Competency</td>
                        <td>
                          {this.getUnitsOfCompetency(
                            certificateCodes,
                            unitOfCompetencies
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Who can complete this form?</td>
                        <td>
                          <p>
                            Because we offer online training, we need to prove
                            your practical skills in Food Safety and we do this
                            by an online third-party checklist form that is
                            emailed to your nominated observer. There are some
                            things we can&rsquo;t see you doing through your
                            computer – such as washing your hands.
                          </p>
                          <p>
                            We often have students who aren&rsquo;t working in
                            the food industry at the moment - sometimes because
                            they are starting a new food business or a new
                            career in the industry, so this observation can also
                            be done in the home kitchen (simulated environment).
                            The person you choose to fill in the report does not
                            need to be a Food Safety Supervisor themselves. As
                            the observation does not have to be in work
                            situation, an adult family member can be nominated
                            as your observer as long as they can witness you
                            perform the required tasks.
                          </p>
                          <p>Your observer can be:</p>
                          <ul>
                            <li>
                              Past or Present Supervisor/Employer/Manager/Co
                              Worker
                            </li>
                            <li>
                              Past or Present Volunteer Supervisor/Manager/Co
                              Worker
                            </li>
                            <li>Adult Family Member or Friend</li>
                          </ul>
                          <h5 className="mb-0">
                            The person you choose to fill in the report does not
                            need to have a Food Safety Certificate.
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>Third Party Evidence Report Purpose</td>
                        <td>
                          The purpose of the Third Party Evidence Report is to
                          obtain evidence to support a judgement about the
                          student&rsquo;s competence as part of the assessment
                          for their chosen course. The Third Party Evidence
                          Report is completed by the student&rsquo;s supervisor,
                          manager or an alternative appropriate person who works
                          closely with the student in a supervisory role. If the
                          student is the owner/CEO/most senior person in the
                          organisation, then an appropriately experienced
                          colleague can complete the report, if they have the
                          relevant experience and knowledge to competently
                          observe the student completing the tasks and make a
                          decision based on the student&rsquo;s performance.
                        </td>
                      </tr>
                      <tr>
                        <td>Instructions for the Third Party</td>
                        <td>
                          In order to make an assessment about the
                          student&rsquo;s ability to complete this course, we
                          are required to collect evidence from a range of
                          contexts and over a period of time.
                          <br />
                          <br />
                          We seek your support in gathering evidence about the
                          competence of this student while performing a range of
                          appropriate tasks over a period of time (e.g. several
                          observations performed over 1-2 weeks). This report
                          will support other evidence of the student&rsquo;s
                          competence. We value your contribution and ask that
                          you answer the questions honestly.
                          <br />
                          <br />
                          We recommend that you have a short discussion with the
                          student before starting the observation tasks, to
                          ensure you both understand what is required to
                          demonstrate competence in the required tasks and
                          against the criteria below.
                          <br />
                          <br />
                          After observing the student completing the required
                          tasks, use the criteria checklist below to tick either
                          &lsquo;Yes&rsquo; or &lsquo;No&rsquo; to confirm
                          whether the student consistently meets workplace
                          standards on those areas.
                          <br />
                          <br />
                          If you tick &lsquo;No&rsquo; against a criteria,
                          please provide immediate feedback to the student to
                          enable them to demonstrate competence successfully
                          either immediately or during their next observation
                          session with you. If you believe by the end of the
                          observation sessions that the student has demonstrated
                          competence in each criteria, please complete and
                          resubmit the Third Party Form Evidence Report with
                          &lsquo;Yes&rsquo; ticked for each criteria.
                        </td>
                      </tr>
                      <tr className="no-bg">
                        <td colSpan="2">
                          If you have any questions or concerns in regard to
                          completing this Third Party Report, or would like to
                          discuss any aspect of the student&rsquo;s performance
                          in relation to this unit of competency, please contact
                          Train to Gain Pty Ltd t/a Food Safety Education at{" "}
                          <a href="mailto:contact@traintogain.com.au">
                            contact@traintogain.com.au
                          </a>
                          .
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ContentSection>

                <ContentSection padding={3}>
                  <h5>
                    The following day-to-day tasks were observed by the Third
                    Party:
                  </h5>
                  <h6 className="mt-2 mb-3">
                    The student will be considered not yet competent if you
                    answer No to any of the tasks below. Further training and
                    observation will need to be undertaken before the student
                    will be certified.
                  </h6>
                  {this.getTasksTable(tasks, this.props.fields)}
                  <AdditionalInfoForm
                    onInputChange={onInputChange}
                    additionalComments={additionalComments}
                    studentPerformanceSatisfactory={
                      studentPerformanceSatisfactory
                    }
                    confirmThirdPartyDeclaration={confirmThirdPartyDeclaration}
                    signature={signature}
                  />
                  {!isSampleForm && (
                    <FormGroup className="mt-3">
                      <ButtonFx
                        type="submit"
                        className="ttg-btn mr-2"
                        isLoading={isLoadingForSaveProgress}
                        onClick={() =>
                          this.form.validate({
                            validFn: () => saveProgress(),
                          })
                        }
                        disabled={isLoadingForSubmit}
                      >
                        Save Progress
                      </ButtonFx>
                      <ButtonFx
                        className="ttg-btn mr-2"
                        isLoading={isLoadingForSubmit}
                        onClick={() =>
                          this.form.validate({
                            validFn: () => submitForm(),
                          })
                        }
                        disabled={isLoadingForSaveProgress}
                      >
                        Submit
                      </ButtonFx>
                    </FormGroup>
                  )}
                  {isSampleForm && (
                    <FormGroup className="mt-3">
                      <ButtonFx
                        type="submit"
                        className="ttg-btn mr-2"
                        isLoading={isLoadingForSaveProgress}
                        onClick={() => alert("This is just a sample form.")}
                        disabled={isLoadingForSubmit}
                      >
                        Save Progress
                      </ButtonFx>
                      <ButtonFx
                        className="ttg-btn mr-2"
                        isLoading={isLoadingForSubmit}
                        onClick={() => alert("This is just a sample form.")}
                        disabled={isLoadingForSaveProgress}
                      >
                        Submit
                      </ButtonFx>
                    </FormGroup>
                  )}
                </ContentSection>
                <KeyboardEventHandler
                  handleKeys={["enter"]}
                  onKeyEvent={(key, e) =>
                    this.form.validate({ validFn: () => saveProgress() })
                  }
                />
              </div>
            )}
          </Form>
        </BlockUiFx>
      </React.Fragment>
    );
  }
}

export default ThirdPartyForm;
