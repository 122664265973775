import React from "react";
import { Link } from "react-router-dom";
import { courses } from "../../constants/course-content";
import "./home-page-courses.scss";
import UserIdentity from "./../../coreLib/auth/userIdentity";

const HomePageCourses = () => {
  let userIdent = UserIdentity();

  return (
    <div className="row">
      {courses.map((course, i) => (
        <div key={i} className="home-course-item col-12 col-lg-4 col-md-6 my-2">
          <div className="course-item-box">
            <img src={course.courseImage} alt={course.courseTitle} />
            <span className="course-item-title">{course.courseTitle}</span>
            {course.validStates ? (
              <span className="course-item-valid">{course.validStates}</span>
            ) : (
              ""
            )}
            <span className="course-item-read-more">
              <Link to={course.courseReadMorePage} className="btn ttg-blue-btn">
                Read more
              </Link>{" "}
            </span>
            <span className="course-item-price">${course.courseCost}.00</span>
            {userIdent.isAuthenticated() ? (
              <Link
                to={`/course-enrolment/${course.courseId}/step-1/`}
                className="btn ttg-btn"
              >
                Start Now!
              </Link>
            ) : (
              <Link
                to={`/Certificates/Enrol/${course.courseId}/`}
                className="btn ttg-btn"
              >
                Start Now!
              </Link>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomePageCourses;
