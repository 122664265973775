import React, { Component } from "react";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../components/Content-Section";
import RegisterBoxContainer from "../../components/Register-Box/Register-Box.Container";
import TickList from "../../components/Tick-List/Tick-List";
import CourseSteps from "../../components/Course-Steps/Course-Steps";

class CorporateVouchersRegister extends Component {
  render() {
    return (
      <React.Fragment>
        <PageTitle title="Business Vouchers for Staff Training" />
        <ContentSection bgColor="grey-shadow" padding={4}>
          <h1>Business Vouchers for Staff Training</h1>
          <p className="mb-0">
            At Food Safety Education, we offer you the flexibility to purchase
            Food Safety Training for your staff so they can proceed with their
            Food Safety training today!
          </p>
        </ContentSection>
        <ContentSection padding={3}>
          <h2>How do I order course vouchers for my staff?</h2>
          <CourseSteps
            steps={[
              <span>
                Register your{" "}
                <span style={{ textDecoration: "underline" }}>business</span>{" "}
                details below
              </span>,
              <span>Choose the courses you need for your staff</span>,
              <span>
                Make payment. Two options, either pay via credit card or
                generate a tax invoice. This needs to be paid within 14 days of
                your voucher order
              </span>,
              <span>
                Your course vouchers are instantly generated and will be
                displayed in your client portal. You can view or email voucher
                codes directly to your staff so they can start their food safety
                training today!
              </span>,
            ]}
          />
        </ContentSection>
        <ContentSection bgColor="grey">
          <div className="row">
            <div className="col-12 col-md-6">
              <RegisterBoxContainer registerType={"corporate"} />
            </div>
          </div>
        </ContentSection>
        <ContentSection>
          <TickList
            items={[
              "All the vouchers are recorded in your client portal so you can email staff their vouchers directly",
              "Student tracking facility, so you can check the progress of each staff member’s training and keep track of how many vouchers have been redeemed",
              "Have access to print out your staff’s Statement of Attainments at any time, including reprints",
              "14 day invoice option means you do not have to pay straight away to receive the vouchers, so this means your staff can start training today!",
              "Flexibility to re order more vouchers at any time within the client portal",
              "Vouchers are valid until they have been redeemed. Vouchers cannot be transferred once a student has redeemed the voucher.",
            ]}
            tickColor="green"
          />
          <p>
            <strong>Instructions for staff using vouchers.</strong>
          </p>
          <TickList
            items={[
              <span>
                Staff go to{" "}
                <a href="https://www.foodsafetyeducation.com.au">
                  www.foodsafetyeducation.com.au
                </a>
              </span>,
              "Staff select the exact course you ordered by clicking on the 'Start Now' button",
              "Staff register their personal details",
              "Staff select the 'Voucher' option in the payment section and click 'Apply'",
              "Staff proceed with enrolment and their online training",
            ]}
            tickColor="green"
          />
          <h5>That’s it! Easy!</h5>
        </ContentSection>
      </React.Fragment>
    );
  }
}

export default CorporateVouchersRegister;
