import React from "react";
import { Link } from "react-router-dom";
import ToggleDisplay from "react-toggle-display";
import Copyright from "../Copyright/Copyright";
import "./footer.css";

const Footer = (props) => {
  const { loggedIn } = props.loginData;

  return (
    <footer className={loggedIn ? "" : ""}>
      <ToggleDisplay if={loggedIn}>
        <section>
          <section className="footer"></section>
          <Copyright />
        </section>
      </ToggleDisplay>
      <ToggleDisplay if={!loggedIn}>
        <section className="footer">
          <div className="container">
            <div className="row">
              <div className="col">
                <ul className="footer-links">
                  <li>
                    <Link to="/Privacy-Policy/">Privacy Policy</Link>
                  </li>
                  <li>
                    <a
                      href="/UserFiles/files/Complaints-and-Appeals-Policy-2023.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Complaints
                    </a>
                  </li>
                  <li>
                    <Link to="/Refund-Policy/">Refund Policy</Link>
                  </li>
                  <li>
                    <a
                      href="/UserFiles/files/Student-Handbook-2024.07.v2.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Student Handbook
                    </a>
                  </li>
                  <li>
                    <Link to="/About-Train-to-Gain/">About</Link>
                  </li>
                  <li>
                    <Link to="/FAQs/">FAQs</Link>
                  </li>
                  <li>
                    <Link to="/Articles/">Articles</Link>
                  </li>
                  <li>
                    <Link to="/Contact-Us/">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <Copyright />
      </ToggleDisplay>
    </footer>
  );
};

export default Footer;
