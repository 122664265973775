/**
 * Step 3 Reducer
 */
import { ActionTypes } from "./action-types";

const initialState = {
  fieldVisibility: {
    showExperience: false,
    showWorkPlaceName: false
  },
  fields: {
    userCourseId: "",
    whereExperience: "",
    workplaceName: "",
    workplaceSuburb: "",
    workplacePhone: "",
    relationshipToObserver: "",
    observerFirstName: "",
    observerLastName: "",
    observerEmail: "",
    workplaceStartDate: "",
    workplaceEndDate: "",
    currentEmploymentStatus: ""
  },
  isProcessing: false,
  formValid: false
};

function Step3Reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.STEP_3_INIT:
      return {
        ...state,
        isProcessing: true,
      };
    case ActionTypes.STEP_3_INIT_SUCCEEDED:
      return {
        ...state,
        isProcessing: false,
        fieldVisibility: {
          showExperience: false,
          showWorkPlaceName: false,
        },
        fields: {
          ...state.fields,
          userCourseId: action.payload.userCourseId,
          whereExperience: "",
          workplaceName: action.payload.workplaceName,
          workplaceSuburb: action.payload.workplaceSuburb,
          workplacePhone: action.payload.workplacePhone,
          relationshipToObserver: action.payload.relationshipToObserver,
          observerFirstName: action.payload.observerFirstName,
          observerLastName: action.payload.observerLastName,
          observerEmail: action.payload.observerEmail,
          workplaceStartDate: action.payload.workplaceStartDate,
          workplaceEndDate: action.payload.workplaceEndDate,
          currentEmploymentStatus:
            action.payload.currentEmploymentStatus === null ||
            action.payload.currentEmploymentStatus === ""
              ? ""
              : action.payload.currentEmploymentStatus,
        },
      };
    case ActionTypes.STEP_3_INIT_FAILED:
      return {
        ...state,
        isProcessing: false,
      };
    case ActionTypes.STEP_3_TOGGLE_WHERE_EXPERIENCE:
      return {
        ...state,
        fieldVisibility: {
          ...state.fieldVisibility,
          showExperience: true,
          showWorkPlaceName: action.payload,
        },
        fields: {
          ...state.fields,
          workplaceName: action.payload ? state.fields.workplaceName : "",
        },
      };
    case ActionTypes.STEP_3_FORM_VALID:
      return {
        ...state,
        formValid: action.payload,
      };
    case ActionTypes.STEP_3_SUBMIT_ENROLMENT_REQUEST:
      return {
        ...state,
      };

    case ActionTypes.STEP_3_SUBMIT_ENROLMENT_SUCCESS:
      return {
        ...state,
      };

    case ActionTypes.STEP_3_SUBMIT_ENROLMENT_FAILURE:
      return {
        ...state,
      };

    case ActionTypes.STEP_3_FORM_INPUT_CHANGE:
      let stateResultInput = Object.assign({}, state, {
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error,
        },
      });
      return stateResultInput;
    case ActionTypes.STEP_3_FORM_DATE_CHANGE:
      let stateResultDate = Object.assign({}, state, {
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
      });

      return stateResultDate;
    default:
      return state;
  }
}

export default Step3Reducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectStep3 = state => state.courseEnrolment.step3;
