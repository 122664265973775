import React from "react";
import { Button } from "reactstrap";

const CourseContentButtons = ({
  currentSection,
  currentPageIndex,
  totalPages,
  onPreviousPage,
  onNextPage,
  onLoadQuiz
}) => {
  return (
    <div>
      {currentPageIndex > 0 && (
        <React.Fragment>
          <Button className="ttg-btn mt-3 mr-2" onClick={onPreviousPage}>
            Previous Page
          </Button>
        </React.Fragment>
      )}
      {currentPageIndex < totalPages - 1 && (
        <Button className="ttg-btn mt-3" onClick={onNextPage}>
          Next Page
        </Button>
      )}

      {/* TODO: show the start quiz button if they click back from the quiz */}
      {currentPageIndex === totalPages - 1 && (
        <Button className="ttg-btn mt-3" onClick={onLoadQuiz}>
          Start Section {currentSection} Quiz
        </Button>
      )}
    </div>
  );
};

export default CourseContentButtons;
