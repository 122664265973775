import React, { Component } from "react";
import "./course-card.scss";

class CourseCardContainer extends Component {
  state = {};
  render() {
    return (
      <div className="course-card-container row my-3">
        {this.props.children}
      </div>
    );
  }
}

export default CourseCardContainer;
