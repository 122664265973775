import React, { Component } from "react";
import PageTitle from "../../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../../components/Content-Section";
import Datatable from "./datatable";
import FilterSection from "./filter-section";
import ToggleDisplay from "react-toggle-display";

class Users extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      onLoad,
      match,
      hideFilterSection,
      onLoadSpecificUserCourse,
    } = this.props;
    match.params.id ? hideFilterSection(true) : hideFilterSection(false);
    match.params.id ? onLoadSpecificUserCourse(match.params.id) : onLoad();
    this.props.clearDataTableSearchbox();
  }

  render() {
    const { isProcessing, hideFilterSection, isReset } = this.props.dataTable;
    return (
      <React.Fragment>
        <PageTitle title="Admin - Course Enrolments | Train to Gain" />
        <ContentSection isFullWidth={true} padding={3} bgColor="grey-shadow">
          <h1>Course Enrolments</h1>
        </ContentSection>
        <ContentSection isFullWidth={true} padding={3}>
          {!isReset && (
            <ToggleDisplay if={!hideFilterSection} show={!isProcessing}>
              <FilterSection {...this.props} />
            </ToggleDisplay>
          )}
          <Datatable {...this.props} />
        </ContentSection>
      </React.Fragment>
    );
  }
}

export default Users;
