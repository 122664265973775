import React, { useEffect } from "react";
import { Button, Spinner, Table } from "reactstrap";
import "./sync-view.scss";
import PageTitle from "components/Common/Page-Title/Page-Title";
import ContentSection from "components/Content-Section";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "./redux/action-creators";
import BlockUiFx from "components/Common/Block-Ui-Fx/Block-Ui-Fx";
import { useState } from "react";
import ConfirmModal from "components/Common/Confirm-Modal/Confirm-Modal";
import BasicModal from "components/Common/Basic-Modal/Basic-Modal";

const SyncView = () => {
  const dispatch = useDispatch();

  const selectSyncCourses = useSelector((state) => state.syncCourses);
  const [showValidationDialog, toggleValidationDialog] = useState(false);
  const [validationMessages, setValidationMessages] = useState(null);

  const [
    showPublishConfirmationDialog,
    togglePublishConfirmationDialog,
  ] = useState(false);

  const [selectedCourseId, setSelectedCourseId] = useState(null);

  useEffect(() => {
    dispatch(Actions.syncCoursesFromStaffd()).then((resp) => {
      if (!resp.isSuccessful) {
        setValidationMessages(resp.validationMessages);
        toggleValidationDialog(true);
      }
    });
  }, []);

  const handleOnPublishConfirmation = (evt) => {
    if (evt.type !== "ok") {
      togglePublishConfirmationDialog(false);
      return;
    }

    dispatch(Actions.publishCourse(selectedCourseId));
    togglePublishConfirmationDialog(false);
  };

  return (
    <>
      <PageTitle title="Content Sync" />
      <ContentSection bgColor="grey-shadow" padding={4}>
        <h1>Content Sync</h1>
      </ContentSection>
      <BlockUiFx
        blocking={selectSyncCourses.isLoading ?? false}
        lowerZIndex={true}
      >
        <ConfirmModal
          title={"Publish Course"}
          isModalOpen={showPublishConfirmationDialog}
          onToggle={handleOnPublishConfirmation}
        >
          Are you sure you wish to publish the new content now? Note: this
          cannot be undone.
        </ConfirmModal>

        <BasicModal
          title={"Validation messages"}
          isOpen={showValidationDialog}
          isLoading={false}
          toggle={() => toggleValidationDialog(false)}
          okButtonText={"Ok"}
          showCancel={false}
        >
          <span>Sorry, failed to sync courses from Staffd.</span>
          {validationMessages && (
            <div className="validation-messages mt-1">
              <span>Please check the following:</span>
              <ul>
                {validationMessages.map((msg, index) => (
                  <li key={index}>{msg}</li>
                ))}
              </ul>
            </div>
          )}
        </BasicModal>

        <ContentSection padding={3}>
          {selectSyncCourses.isSyncing && (
            <div className="loading-text">
              <span>
                <Spinner
                  animation="border"
                  color="primary"
                  size="sm"
                  className="spinner"
                />
                Syncing with Staffd, please wait.
              </span>
            </div>
          )}
          {!selectSyncCourses.isSyncing && (
            <Table className="content-sync-table">
              <thead>
                <tr>
                  <th>Course</th>
                  <th>CSC</th>
                  <th>Last Published</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {selectSyncCourses.courses.map((course, i) => (
                  <tr key={i}>
                    <td className="course-val">
                      <span>{course.courseTitle}</span>
                      <br />({course.certificateCodesString})
                    </td>
                    <td>{course.currentSetCount}</td>
                    <td>{course.publishDateTime}</td>
                    <td className="status">
                      {course.isWaitingPublishing
                        ? "Waiting Publishing"
                        : "Synced"}
                    </td>
                    <td className="action-btns">
                      {course.isWaitingPublishing && (
                        <Button
                          className="btn ttg-btn"
                          onClick={() => {
                            setSelectedCourseId(course.courseId);
                            togglePublishConfirmationDialog(true);
                          }}
                          disabled={selectSyncCourses.isLoading}
                        >
                          Publish Now
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </ContentSection>
      </BlockUiFx>
    </>
  );
};

export default SyncView;
