import { ActionTypes } from "./action-types";
import { addNotification } from "../../../redux/system/system-action-creators";
import * as Constants from "../../../constants";
import HttpService from "./service";
import IdentityService from "../../../coreLib/auth/userIdentity";

export const onSubmit = fields => {
  const { title, firstName, lastName, email, state } = fields;

  return dispatch => {
    if (!title || title === undefined || title === "") {
      dispatch(addNotification("Title is required.", "warning"));
      return;
    }

    if (!firstName || firstName === undefined || firstName === "") {
      dispatch(addNotification("First name is required.", "warning"));
      return;
    }

    if (!lastName || lastName === undefined || lastName === "") {
      dispatch(addNotification("Last name is required.", "warning"));
      return;
    }

    if (!email || email === undefined || email === "") {
      dispatch(addNotification("Email address is required.", "warning"));
      return;
    }

    if (!Constants.emailRegex.test(email)) {
      dispatch(addNotification("Invalid email address.", "warning"));
      return;
    }

    if (!state || state === undefined || state === "") {
      dispatch(addNotification("State is required.", "warning"));
      return;
    }

    dispatch(onSubmitStart());
    let id = IdentityService().getUserId();
    HttpService()
      .updateProfile(id, fields)
      .then(() => {
        dispatch(onSubmitSuccess());
        dispatch(addNotification("Your profle has been updated successfully."));
      })
      .catch(() => {
        dispatch(addNotification("Error updating your profile.", "warning"));
        dispatch(onSubmitFailure());
      });
  };
};

export const getUserInfo = () => dispatch => {
  dispatch(onGetUserInfo());
  let id = IdentityService().getUserId();
  HttpService()
    .getUserInfo(id)
    .then(resp => dispatch(onGetUserInfoSuccess(resp.data)))
    .catch(() => {
      dispatch(addNotification("Error loading user details.", "warning"));
      dispatch(onGetUserInfoFailure());
    });
};

const onGetUserInfo = () => ({
  type: ActionTypes.UPDATE_PROFILE_ON_LOAD
});

const onGetUserInfoSuccess = payload => ({
  type: ActionTypes.UPDATE_PROFILE_ON_LOAD_SUCCESS,
  payload
});

const onGetUserInfoFailure = () => ({
  type: ActionTypes.UPDATE_PROFILE_ON_LOAD_FAILURE
});

const onSubmitStart = () => ({
  type: ActionTypes.UPDATE_PROFILE_ON_SUBMIT
});

const onSubmitSuccess = payload => ({
  type: ActionTypes.UPDATE_PROFILE_ON_SUBMIT_SUCCESS,
  payload
});

const onSubmitFailure = () => ({
  type: ActionTypes.UPDATE_PROFILE_ON_SUBMIT_FAILURE
});

export const formInputChange = (name, value, error) => ({
  type: ActionTypes.UPDATE_PROFILE_FORM_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error
  }
});
