import { ActionTypes } from "./action-types";

const initialState = {
  fields: {
    title: null,
    firstName: null,
    lastName: null,
    email: null,
    state: null
  },
  isSubmitting: false,
  isSubmitted: false,
  fieldErrors: {}
};

function UpdateProfileReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_PROFILE_FORM_INPUT_CHANGE:
      let stateResult = Object.assign({}, state, {
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error
        }
      });
      return stateResult;
    case ActionTypes.UPDATE_PROFILE_ON_SUBMIT:
      return {
        ...state,
        isSubmitting: true
      };
    case ActionTypes.UPDATE_PROFILE_ON_SUBMIT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: true
      };
    case ActionTypes.UPDATE_PROFILE_ON_SUBMIT_FAILURE:
      return {
        ...state,
        isSubmitting: false
      };
    case ActionTypes.UPDATE_PROFILE_ON_LOAD:
      return {
        ...state,
        isSubmitting: true
      };
    case ActionTypes.UPDATE_PROFILE_ON_LOAD_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        fields: action.payload
      };
    case ActionTypes.UPDATE_PROFILE_ON_LOAD_FAILURE:
      return {
        ...state,
        isSubmitting: false
      };
    default:
      return state;
  }
}

export default UpdateProfileReducer;

export const selectUpdateProfile = state => state.updateProfile;
